import { ChevronDownIcon } from '@chakra-ui/icons'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { CloseIcon, TickRoundedIcon } from 'icons'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { flushSync } from 'react-dom'
import { TEALIUM_EVENT } from 'tracking'
import {
  createUtagPopupResponseEvent,
  createUtagPopupViewEvent
} from 'tracking/tealium/helpers'
import { TEALIUM_POPUP_RESULT } from 'tracking/tealium/helpers/constants'
import { Button, H4, SmallText } from 'ui'
import { useLocalStorage, ZINDEX } from 'utils'
import { AnimatePresence } from '../../components/AnimatePresence'

const MotionBox = motion(Box)
const MotionHeading = motion(Heading)

interface FormPopupProps {
  children: ({
    handleFormSuccess,
    handleFormError
  }: {
    handleFormSuccess: () => void
    handleFormError: () => void
  }) => ReactNode
  cookieName: string
  id?: string
  heading?: {
    initial: string
    onSuccess: string
  }
  bodyText?: {
    initial: string
    onSuccess: string
  }
  successMessage?: {
    heading: string
    bodyText: string
  }
  errorMessage?: {
    heading: string
    bodyText: string
  }
  isAuthenticated?: boolean
  isAuthStateLoading?: boolean
  hideUntilForcedOpen: boolean
  disableTealiumPopupEvent?: boolean
}

const FormPopup = ({
  children,
  cookieName,
  id,
  heading,
  bodyText,
  successMessage,
  errorMessage,
  isAuthenticated,
  isAuthStateLoading,
  hideUntilForcedOpen,
  disableTealiumPopupEvent = false
}: FormPopupProps) => {
  const formPopupHeaderRef = useRef<HTMLDivElement>(null)
  const [formPopupHeaderHeight, setFormPopupHeaderHeight] = useState<number>(0)
  const [isPopupMinimised, setIsPopupMinimised] = useState<boolean>(false)
  const [hasPopupBeenDismissed, setHasPopupBeenDismissed] =
    useState<boolean>(false)
  const [successfulSubmission, setSuccessfulSubmission] =
    useState<boolean>(false)
  const [submissionError, setSubmissionError] = useState<boolean>(false)

  const {
    query: { showLeadPopup },
    events: RouterEvents
  } = useRouter()

  const [value, setValue] = useLocalStorage('tealium_lcf_data', {
    popup_id: '',
    popup_name: '',
    popup_testcell: '',
    tealium_visitor_id: ''
  })

  const HIDE_POPUP_COOKIE = cookieName
  const FIRST_VISIT_COOKIE = 'POPUP_FIRST_VISIT'
  const INITIAL_DELAY = 60
  const isSubsequentVisit = !!Cookies.get(FIRST_VISIT_COOKIE)
  const hasHidePopupCookie = !!Cookies.get(HIDE_POPUP_COOKIE)
  const hidePopupCookieValue = Cookies.get(HIDE_POPUP_COOKIE)
  const MINIMISE_POPUP_COOKIE = 'MINIMISE_POPUP_COOKIE'
  const handleSetHidePopupCookie = useCallback(
    (value: boolean) => {
      Cookies.set(HIDE_POPUP_COOKIE, `${value}`)
    },
    [HIDE_POPUP_COOKIE]
  )

  /**
   * Ideally we would use Tealium here to trigger when we should load this on subsequent page visits
   * However we can't use tealium_session_event_number for toggling this because subsequent page visits within the SPA routing don't get counted.
   * This value is only updated when a hard refresh is triggered
   */
  useEffect(() => {
    /** Set the cookie if this is the users first visit */
    const setFirstVisitCookie = () => {
      Cookies.set(FIRST_VISIT_COOKIE, 'true')
    }

    // Only run if the first time cookie isn't set
    // console.debug(`👆 Has this user visited the site before: ${isSubsequentVisit ? 'yes' : 'no'}`)
    if (!isSubsequentVisit) {
      // if user navigates away to a completely different site
      // or refreshes the page etc then set cookie
      window.addEventListener('beforeunload', setFirstVisitCookie)

      // if user navigates to another page on the same site
      RouterEvents.on('routeChangeStart', setFirstVisitCookie)

      // Remove our events on unload
      return () => {
        RouterEvents.off('routeChangeStart', setFirstVisitCookie)
        window.removeEventListener('beforeunload', setFirstVisitCookie)
      }
    }

    /* We need this return statement here otherwise typescript will throw an error */
    return
  }, [isSubsequentVisit, RouterEvents])

  useEffect(() => {
    const handleResize = () => {
      setFormPopupHeaderHeight(formPopupHeaderRef?.current?.clientHeight ?? 0)
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const cookieValue = Cookies.get(MINIMISE_POPUP_COOKIE)
    if (typeof cookieValue === 'string') {
      setIsPopupMinimised(cookieValue === 'true')
    }
  }, [])

  useEffect(() => {
    if (!formPopupHeaderRef?.current?.clientHeight) {
      return
    }

    setFormPopupHeaderHeight(formPopupHeaderRef.current.clientHeight)
    Cookies.set(MINIMISE_POPUP_COOKIE, isPopupMinimised.toString())
  }, [isPopupMinimised])

  /**
   * Display lead capture popup form on utag show_popup event
   * Note: this has been
   */
  useEffect(() => {
    const handleUtagShowPopup = (event) => {
      if (window?.utag) {
        // if HIDE_POPUP_COOKIE is set to true we don't want to do anything as our popup shouldn't be displayed
        if (
          hidePopupCookieValue === 'true' ||
          disableTealiumPopupEvent === true
        )
          return

        handleSetHidePopupCookie(false)
        createUtagPopupViewEvent({
          ...event.detail
        })
        setValue({ ...event.detail })
      }
    }

    if (typeof window !== 'undefined') {
      document.addEventListener(TEALIUM_EVENT.SHOW_POPUP, handleUtagShowPopup)
    }

    return () => {
      document.removeEventListener(
        TEALIUM_EVENT.SHOW_POPUP,
        handleUtagShowPopup
      )
    }
  }, [
    setValue,
    handleSetHidePopupCookie,
    hidePopupCookieValue,
    disableTealiumPopupEvent
  ])

  const handleToggleMinimisePopup = () => {
    /* To stop the delay, once the user has clicked on the page it will be set to no longer their first time. */
    Cookies.set(FIRST_VISIT_COOKIE, 'false')
    setIsPopupMinimised((prevIsPopupMinimised) => {
      // Speakdata doesn't want response events if the user has completed the form
      if (!successfulSubmission) {
        createUtagPopupResponseEvent({
          popup_id: value?.popup_id ?? '',
          popup_name: value?.popup_name ?? '',
          popup_testcell: value?.popup_testcell ?? '',
          tealium_visitor_id: value?.tealium_visitor_id ?? '',
          popup_result: prevIsPopupMinimised
            ? TEALIUM_POPUP_RESULT.MAXIMISED
            : TEALIUM_POPUP_RESULT.MINIMISED
        })
      }
      return !prevIsPopupMinimised
    })
  }

  if (
    // If hideUntilForcedOpen is false AND the HIDE_POPUP_COOKIE has been set to true AND showLeadPopup isn't present in the url params don't render the component
    (!hideUntilForcedOpen &&
      hidePopupCookieValue === 'true' &&
      !showLeadPopup) ||
    // If hideUntilForcedOpen is true AND the HIDE_POPUP_COOKIE has not been set OR it's been set to true AND showLeadPopup isn't present in the url params don't render the component
    (hideUntilForcedOpen &&
      (!hasHidePopupCookie || hidePopupCookieValue === 'true') &&
      !showLeadPopup) ||
    isAuthenticated ||
    isAuthStateLoading ||
    hasPopupBeenDismissed
  ) {
    return null
  }

  // If this is the users first visit, then the popup should take a minute
  // Otherwise any subsequent visits we don't want any delay
  const POPUP_DELAY = isSubsequentVisit ? 0 : INITIAL_DELAY

  const handleFormSuccess = () => {
    /*
    React 18 introduced automatic batching which causes the render to occur after setSuccessfulSubmission and handleSetHidePopupCookie have run.
    This causes the popup to close before the success message can be displayed, as if hidePopupCookieValue is true we return null.
    To fix this, we need to use flushSync to force the render to occur before handleSetHidePopupCookie has run so that hidePopupCookieValue is false when the
    render occurs. hidePopupCookieValue is not state and is not changing as a result of a prop change, so will not trigger another render when it gets set here.
    */
    flushSync(() => {
      setSuccessfulSubmission(true)
    })
    handleSetHidePopupCookie(true)

    createUtagPopupResponseEvent({
      popup_id: value?.popup_id ?? '',
      popup_name: value?.popup_name ?? '',
      popup_testcell: value?.popup_testcell ?? '',
      tealium_visitor_id: value?.tealium_visitor_id ?? '',
      popup_result: TEALIUM_POPUP_RESULT.COMPLETED
    })
  }

  const handleDismissPopup = () => {
    handleSetHidePopupCookie(true)
    setHasPopupBeenDismissed(true)
    // Speakdata doesn't want response events if the user has completed the form
    if (!successfulSubmission) {
      createUtagPopupResponseEvent({
        popup_id: value?.popup_id ?? '',
        popup_name: value?.popup_name ?? '',
        popup_testcell: value?.popup_testcell ?? '',
        tealium_visitor_id: value?.tealium_visitor_id ?? '',
        popup_result: TEALIUM_POPUP_RESULT.DISMISSED
      })
    }
  }

  const handleFormError = () => {
    setSubmissionError(true)
  }

  const getDelay = () => {
    if (showLeadPopup) {
      return 0
    }

    if (process.env.NEXT_PUBLIC_LEAD_FORM_DELAY) {
      return process.env.NEXT_PUBLIC_LEAD_FORM_DELAY
    }

    return POPUP_DELAY
  }

  return (
    <Box dir='ltr' id={id} key={`${isPopupMinimised}`}>
      <MotionBox
        bgColor='rgba(0,0,0,0.54)'
        pos='fixed'
        top={0}
        boxSize='100%'
        display={{ sm: 'block', md: 'none' }}
        aria-label='background-overlay'
        zIndex={ZINDEX.FORM_POPUP}
        transition={{ delay: getDelay(), ease: 'easeOut' }}
        initial={{ opacity: 0, y: '100%' }}
        variants={{
          open: { opacity: 1, y: 0 },
          closed: { opacity: 0, y: '100%' }
        }}
        animate={isPopupMinimised ? 'closed' : 'open'}
        sx={{
          overflowX: 'hidden'
        }}
      />
      <MotionBox
        maxW={isPopupMinimised ? 'min-content' : '420px'}
        pos='fixed'
        bottom={0}
        right={{ base: 0, md: 4 }}
        maxHeight={{ base: '85vh', md: '80vh' }}
        overflow='scroll'
        borderTopRadius={20}
        boxShadow='0px 5px 20px rgba(7, 25, 55, 0.1)'
        sx={{
          maxHeight: {
            base: '85svh',
            md: '80svh'
          },
          overflowX: 'hidden',
          '.grecaptchaBadge': 'visibility: hidden'
        }}
        background={'white'}
        initial={{ opacity: 1, y: `calc(100% - ${formPopupHeaderHeight}px)` }}
        variants={{
          open: {
            opacity: 1,
            y: 0
          },
          closed: {
            opacity: 1,
            y: `calc(100% - ${formPopupHeaderHeight}px)`
          }
        }}
        animate={isPopupMinimised ? 'closed' : 'open'}
        transition={{ delay: getDelay(), type: 'spring', duration: 0.8 }}
        zIndex={ZINDEX.FORM_POPUP + 1}
      >
        <Box
          backgroundColor='deepblue.500'
          color='white'
          position='sticky'
          top='0'
          zIndex={ZINDEX.FORM_POPUP + 2}
        >
          <Box
            ref={formPopupHeaderRef}
            px={{ base: 5, md: 4 }}
            pt={isPopupMinimised ? { base: 2, md: 1 } : { base: 5, md: 4 }}
            pb={isPopupMinimised ? { base: 2, md: 1 } : 1}
          >
            <Flex
              justifyContent='space-between'
              alignItems='center'
              pos='relative'
            >
              {heading && (
                <MotionHeading
                  as='h3'
                  fontSize={{ base: '26px', lg: '32px' }}
                  fontWeight={isPopupMinimised ? 500 : 700}
                  lineHeight='1.375'
                  mb={0}
                  initial={{ scale: 0.563 }}
                  animate={{
                    scale: isPopupMinimised ? 0.5625 : 1,
                    transformOrigin: 'center left'
                  }}
                  transition={{ ease: 'easeOut' }}
                  whiteSpace='nowrap'
                  fontFamily={isPopupMinimised ? 'enz500' : 'enz700'}
                >
                  {successfulSubmission && !submissionError
                    ? heading.onSuccess
                    : heading.initial}
                </MotionHeading>
              )}
              <Flex display='flex' alignItems='center'>
                <Button
                  variant='unstyled'
                  boxSize={8}
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  aria-label='Minimise form'
                  minW='unset'
                  icon={
                    <ChevronDownIcon
                      transform={
                        isPopupMinimised ? 'rotate(180deg)' : 'rotate(0deg)'
                      }
                      boxSize={8}
                      color={'white'}
                    />
                  }
                  onClick={handleToggleMinimisePopup}
                />
                <Button
                  variant='unstyled'
                  boxSize={8}
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  aria-label='Close form'
                  minW='unset'
                  icon={<CloseIcon boxSize={8} color={'white'} />}
                  onClick={handleDismissPopup}
                />
              </Flex>
            </Flex>
          </Box>
          {bodyText && (
            <AnimatePresence>
              {!isPopupMinimised && (
                <MotionBox
                  transition={{ delay: getDelay() }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  px={{ base: 5, md: 4 }}
                  pb={4}
                >
                  <Text mb={0} fontSize='sm'>
                    {successfulSubmission && !submissionError
                      ? bodyText.onSuccess
                      : bodyText.initial}
                  </Text>
                </MotionBox>
              )}
            </AnimatePresence>
          )}
        </Box>
        {!successfulSubmission && !submissionError && (
          <Box padding={5}>
            {children({
              handleFormSuccess,
              handleFormError
            })}
          </Box>
        )}
        {successfulSubmission && !submissionError && successMessage && (
          <Box px={4} py={16} backgroundColor='white' textAlign='center'>
            <Box boxSize={16} color='success' marginBottom={8} mx='auto'>
              <TickRoundedIcon />
            </Box>
            <H4>{successMessage.heading}</H4>
            <SmallText>{successMessage.bodyText}</SmallText>
          </Box>
        )}
        {submissionError && errorMessage && (
          <Box px={4} py={16} backgroundColor='white' textAlign='center'>
            <H4 color='error'>{errorMessage.heading}</H4>
            <SmallText color='error'>{errorMessage.bodyText}</SmallText>
          </Box>
        )}
      </MotionBox>
    </Box>
  )
}

export default FormPopup
