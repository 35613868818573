/* eslint-disable */ 
 /* 🤖 This is an generated file, do not alter it directly! 👮 */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  SWNZ_DateTime: { input: any; output: any; }
  SWNZ_Dimension: { input: any; output: any; }
  SWNZ_HexColor: { input: any; output: any; }
  SWNZ_JSON: { input: any; output: any; }
  SWNZ_Quality: { input: any; output: any; }
};

export type Query = {
  __typename?: 'Query';
  apiInfo?: Maybe<ApiInfo>;
  inventory?: Maybe<InventoryCollection>;
  swnz__node?: Maybe<Swnz__Node>;
  swnz_accordionContainer?: Maybe<Swnz_AccordionContainer>;
  swnz_accordionContainerCollection?: Maybe<Swnz_AccordionContainerCollection>;
  swnz_accordionItem?: Maybe<Swnz_AccordionItem>;
  swnz_accordionItemCollection?: Maybe<Swnz_AccordionItemCollection>;
  swnz_alternatingContent?: Maybe<Swnz_AlternatingContent>;
  swnz_alternatingContentCollection?: Maybe<Swnz_AlternatingContentCollection>;
  swnz_alternatingQuote?: Maybe<Swnz_AlternatingQuote>;
  swnz_alternatingQuoteCollection?: Maybe<Swnz_AlternatingQuoteCollection>;
  swnz_asset?: Maybe<Swnz_Asset>;
  swnz_assetCollection?: Maybe<Swnz_AssetCollection>;
  swnz_banner?: Maybe<Swnz_Banner>;
  swnz_bannerCollection?: Maybe<Swnz_BannerCollection>;
  swnz_blockquote?: Maybe<Swnz_Blockquote>;
  swnz_blockquoteCollection?: Maybe<Swnz_BlockquoteCollection>;
  swnz_blogAuthor?: Maybe<Swnz_BlogAuthor>;
  swnz_blogAuthorCollection?: Maybe<Swnz_BlogAuthorCollection>;
  swnz_blogCallToAction?: Maybe<Swnz_BlogCallToAction>;
  swnz_blogCallToActionCollection?: Maybe<Swnz_BlogCallToActionCollection>;
  swnz_blogCardWithLink?: Maybe<Swnz_BlogCardWithLink>;
  swnz_blogCardWithLinkCollection?: Maybe<Swnz_BlogCardWithLinkCollection>;
  swnz_blogCategory?: Maybe<Swnz_BlogCategory>;
  swnz_blogCategoryCollection?: Maybe<Swnz_BlogCategoryCollection>;
  swnz_blogCustomCampaignTags?: Maybe<Swnz_BlogCustomCampaignTags>;
  swnz_blogCustomCampaignTagsCollection?: Maybe<Swnz_BlogCustomCampaignTagsCollection>;
  swnz_blogImpactText?: Maybe<Swnz_BlogImpactText>;
  swnz_blogImpactTextCollection?: Maybe<Swnz_BlogImpactTextCollection>;
  swnz_blogPost?: Maybe<Swnz_BlogPost>;
  swnz_blogPostCollection?: Maybe<Swnz_BlogPostCollection>;
  swnz_bulletPoint?: Maybe<Swnz_BulletPoint>;
  swnz_bulletPointCollection?: Maybe<Swnz_BulletPointCollection>;
  swnz_bulletPointContainer?: Maybe<Swnz_BulletPointContainer>;
  swnz_bulletPointContainerCollection?: Maybe<Swnz_BulletPointContainerCollection>;
  swnz_callToAction?: Maybe<Swnz_CallToAction>;
  swnz_callToActionCollection?: Maybe<Swnz_CallToActionCollection>;
  swnz_cardContainer?: Maybe<Swnz_CardContainer>;
  swnz_cardContainerCollection?: Maybe<Swnz_CardContainerCollection>;
  swnz_cardWithLink?: Maybe<Swnz_CardWithLink>;
  swnz_cardWithLinkCollection?: Maybe<Swnz_CardWithLinkCollection>;
  swnz_cardWithModal?: Maybe<Swnz_CardWithModal>;
  swnz_cardWithModalCollection?: Maybe<Swnz_CardWithModalCollection>;
  swnz_componentStatic?: Maybe<Swnz_ComponentStatic>;
  swnz_componentStaticCollection?: Maybe<Swnz_ComponentStaticCollection>;
  swnz_emotionContainer?: Maybe<Swnz_EmotionContainer>;
  swnz_emotionContainerCollection?: Maybe<Swnz_EmotionContainerCollection>;
  swnz_emotionItem?: Maybe<Swnz_EmotionItem>;
  swnz_emotionItemCollection?: Maybe<Swnz_EmotionItemCollection>;
  swnz_emotionItemResponse?: Maybe<Swnz_EmotionItemResponse>;
  swnz_emotionItemResponseCollection?: Maybe<Swnz_EmotionItemResponseCollection>;
  swnz_entryCollection?: Maybe<Swnz_EntryCollection>;
  swnz_formDropdown?: Maybe<Swnz_FormDropdown>;
  swnz_formDropdownCollection?: Maybe<Swnz_FormDropdownCollection>;
  swnz_formDropdownOption?: Maybe<Swnz_FormDropdownOption>;
  swnz_formDropdownOptionCollection?: Maybe<Swnz_FormDropdownOptionCollection>;
  swnz_formFieldCity?: Maybe<Swnz_FormFieldCity>;
  swnz_formFieldCityCollection?: Maybe<Swnz_FormFieldCityCollection>;
  swnz_formLeadCapture?: Maybe<Swnz_FormLeadCapture>;
  swnz_formLeadCaptureCollection?: Maybe<Swnz_FormLeadCaptureCollection>;
  swnz_galleryCarousel?: Maybe<Swnz_GalleryCarousel>;
  swnz_galleryCarouselCollection?: Maybe<Swnz_GalleryCarouselCollection>;
  swnz_genericText?: Maybe<Swnz_GenericText>;
  swnz_genericTextCollection?: Maybe<Swnz_GenericTextCollection>;
  swnz_globalSettings?: Maybe<Swnz_GlobalSettings>;
  swnz_globalSettingsCollection?: Maybe<Swnz_GlobalSettingsCollection>;
  swnz_glossary?: Maybe<Swnz_Glossary>;
  swnz_glossaryCollection?: Maybe<Swnz_GlossaryCollection>;
  swnz_hero?: Maybe<Swnz_Hero>;
  swnz_heroCollection?: Maybe<Swnz_HeroCollection>;
  swnz_heroFeatured?: Maybe<Swnz_HeroFeatured>;
  swnz_heroFeaturedCollection?: Maybe<Swnz_HeroFeaturedCollection>;
  swnz_heroFeaturedFloatingCard?: Maybe<Swnz_HeroFeaturedFloatingCard>;
  swnz_heroFeaturedFloatingCardCollection?: Maybe<Swnz_HeroFeaturedFloatingCardCollection>;
  swnz_heroTab?: Maybe<Swnz_HeroTab>;
  swnz_heroTabCollection?: Maybe<Swnz_HeroTabCollection>;
  swnz_homepageCard?: Maybe<Swnz_HomepageCard>;
  swnz_homepageCardCollection?: Maybe<Swnz_HomepageCardCollection>;
  swnz_homepagePanel?: Maybe<Swnz_HomepagePanel>;
  swnz_homepagePanelCollection?: Maybe<Swnz_HomepagePanelCollection>;
  swnz_homepageStoryPanel?: Maybe<Swnz_HomepageStoryPanel>;
  swnz_homepageStoryPanelCollection?: Maybe<Swnz_HomepageStoryPanelCollection>;
  swnz_iconCallToActions?: Maybe<Swnz_IconCallToActions>;
  swnz_iconCallToActionsCollection?: Maybe<Swnz_IconCallToActionsCollection>;
  swnz_impactText?: Maybe<Swnz_ImpactText>;
  swnz_impactTextCollection?: Maybe<Swnz_ImpactTextCollection>;
  swnz_infoBox?: Maybe<Swnz_InfoBox>;
  swnz_infoBoxCollection?: Maybe<Swnz_InfoBoxCollection>;
  swnz_introTextWithJumpMenu?: Maybe<Swnz_IntroTextWithJumpMenu>;
  swnz_introTextWithJumpMenuCollection?: Maybe<Swnz_IntroTextWithJumpMenuCollection>;
  swnz_invCourse?: Maybe<Swnz_InvCourse>;
  swnz_invCourseCollection?: Maybe<Swnz_InvCourseCollection>;
  swnz_invInstitution?: Maybe<Swnz_InvInstitution>;
  swnz_invInstitutionCollection?: Maybe<Swnz_InvInstitutionCollection>;
  swnz_jumpAhead?: Maybe<Swnz_JumpAhead>;
  swnz_jumpAheadCollection?: Maybe<Swnz_JumpAheadCollection>;
  swnz_keepExploring?: Maybe<Swnz_KeepExploring>;
  swnz_keepExploringCollection?: Maybe<Swnz_KeepExploringCollection>;
  swnz_mapContainer?: Maybe<Swnz_MapContainer>;
  swnz_mapContainerCollection?: Maybe<Swnz_MapContainerCollection>;
  swnz_mediaAudio?: Maybe<Swnz_MediaAudio>;
  swnz_mediaAudioCollection?: Maybe<Swnz_MediaAudioCollection>;
  swnz_mediaIcon?: Maybe<Swnz_MediaIcon>;
  swnz_mediaIconCollection?: Maybe<Swnz_MediaIconCollection>;
  swnz_mediaImage?: Maybe<Swnz_MediaImage>;
  swnz_mediaImageCollection?: Maybe<Swnz_MediaImageCollection>;
  swnz_mediaVideo?: Maybe<Swnz_MediaVideo>;
  swnz_mediaVideoCollection?: Maybe<Swnz_MediaVideoCollection>;
  swnz_migration?: Maybe<Swnz_Migration>;
  swnz_migrationCollection?: Maybe<Swnz_MigrationCollection>;
  swnz_naumaiPageStandard?: Maybe<Swnz_NaumaiPageStandard>;
  swnz_naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  swnz_navigation?: Maybe<Swnz_Navigation>;
  swnz_navigationCollection?: Maybe<Swnz_NavigationCollection>;
  swnz_navigationElement?: Maybe<Swnz_NavigationElement>;
  swnz_navigationElementCollection?: Maybe<Swnz_NavigationElementCollection>;
  swnz_nextSteps?: Maybe<Swnz_NextSteps>;
  swnz_nextStepsCollection?: Maybe<Swnz_NextStepsCollection>;
  swnz_nextStepsItem?: Maybe<Swnz_NextStepsItem>;
  swnz_nextStepsItemCollection?: Maybe<Swnz_NextStepsItemCollection>;
  swnz_page?: Maybe<Swnz_Page>;
  swnz_pageCollection?: Maybe<Swnz_PageCollection>;
  swnz_pageHomepage?: Maybe<Swnz_PageHomepage>;
  swnz_pageHomepageCollection?: Maybe<Swnz_PageHomepageCollection>;
  swnz_pageModal?: Maybe<Swnz_PageModal>;
  swnz_pageModalCollection?: Maybe<Swnz_PageModalCollection>;
  swnz_pageSearchResults?: Maybe<Swnz_PageSearchResults>;
  swnz_pageSearchResultsCollection?: Maybe<Swnz_PageSearchResultsCollection>;
  swnz_pageStandard?: Maybe<Swnz_PageStandard>;
  swnz_pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
  swnz_promo?: Maybe<Swnz_Promo>;
  swnz_promoCollection?: Maybe<Swnz_PromoCollection>;
  swnz_richMedia?: Maybe<Swnz_RichMedia>;
  swnz_richMediaCollection?: Maybe<Swnz_RichMediaCollection>;
  swnz_seo?: Maybe<Swnz_Seo>;
  swnz_seoCollection?: Maybe<Swnz_SeoCollection>;
  swnz_stat?: Maybe<Swnz_Stat>;
  swnz_statCollection?: Maybe<Swnz_StatCollection>;
  swnz_statContainer?: Maybe<Swnz_StatContainer>;
  swnz_statContainerCollection?: Maybe<Swnz_StatContainerCollection>;
  swnz_storyCard?: Maybe<Swnz_StoryCard>;
  swnz_storyCardCollection?: Maybe<Swnz_StoryCardCollection>;
  swnz_storyCardContainer?: Maybe<Swnz_StoryCardContainer>;
  swnz_storyCardContainerCollection?: Maybe<Swnz_StoryCardContainerCollection>;
  swnz_tile?: Maybe<Swnz_Tile>;
  swnz_tileCollection?: Maybe<Swnz_TileCollection>;
  swnz_tileContainer?: Maybe<Swnz_TileContainer>;
  swnz_tileContainerCollection?: Maybe<Swnz_TileContainerCollection>;
  swnz_translationProject?: Maybe<Swnz_TranslationProject>;
  swnz_translationProjectCollection?: Maybe<Swnz_TranslationProjectCollection>;
  swnz_translator?: Maybe<Swnz_Translator>;
  swnz_translatorCollection?: Maybe<Swnz_TranslatorCollection>;
  swnz_value?: Maybe<Swnz_Value>;
  swnz_valueCollection?: Maybe<Swnz_ValueCollection>;
};


export type QueryInventoryArgs = {
  id: Scalars['String']['input'];
  key: Scalars['String']['input'];
};


export type QuerySwnz__NodeArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_AccordionContainerArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_AccordionContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_AccordionContainerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_AccordionContainerFilter>;
};


export type QuerySwnz_AccordionItemArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_AccordionItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_AccordionItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_AccordionItemFilter>;
};


export type QuerySwnz_AlternatingContentArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_AlternatingContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_AlternatingContentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_AlternatingContentFilter>;
};


export type QuerySwnz_AlternatingQuoteArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_AlternatingQuoteCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_AlternatingQuoteOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_AlternatingQuoteFilter>;
};


export type QuerySwnz_AssetArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_AssetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_AssetOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_AssetFilter>;
};


export type QuerySwnz_BannerArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_BannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BannerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_BannerFilter>;
};


export type QuerySwnz_BlockquoteArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_BlockquoteCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BlockquoteOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_BlockquoteFilter>;
};


export type QuerySwnz_BlogAuthorArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_BlogAuthorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BlogAuthorOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_BlogAuthorFilter>;
};


export type QuerySwnz_BlogCallToActionArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_BlogCallToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BlogCallToActionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_BlogCallToActionFilter>;
};


export type QuerySwnz_BlogCardWithLinkArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_BlogCardWithLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BlogCardWithLinkOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_BlogCardWithLinkFilter>;
};


export type QuerySwnz_BlogCategoryArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_BlogCategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BlogCategoryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_BlogCategoryFilter>;
};


export type QuerySwnz_BlogCustomCampaignTagsArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_BlogCustomCampaignTagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BlogCustomCampaignTagsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_BlogCustomCampaignTagsFilter>;
};


export type QuerySwnz_BlogImpactTextArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_BlogImpactTextCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BlogImpactTextOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_BlogImpactTextFilter>;
};


export type QuerySwnz_BlogPostArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_BlogPostCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BlogPostOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_BlogPostFilter>;
};


export type QuerySwnz_BulletPointArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_BulletPointCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BulletPointOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_BulletPointFilter>;
};


export type QuerySwnz_BulletPointContainerArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_BulletPointContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BulletPointContainerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_BulletPointContainerFilter>;
};


export type QuerySwnz_CallToActionArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_CallToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CallToActionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


export type QuerySwnz_CardContainerArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_CardContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CardContainerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_CardContainerFilter>;
};


export type QuerySwnz_CardWithLinkArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_CardWithLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CardWithLinkOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_CardWithLinkFilter>;
};


export type QuerySwnz_CardWithModalArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_CardWithModalCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CardWithModalOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_CardWithModalFilter>;
};


export type QuerySwnz_ComponentStaticArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_ComponentStaticCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_ComponentStaticOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_ComponentStaticFilter>;
};


export type QuerySwnz_EmotionContainerArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_EmotionContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_EmotionContainerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_EmotionContainerFilter>;
};


export type QuerySwnz_EmotionItemArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_EmotionItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_EmotionItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_EmotionItemFilter>;
};


export type QuerySwnz_EmotionItemResponseArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_EmotionItemResponseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_EmotionItemResponseOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_EmotionItemResponseFilter>;
};


export type QuerySwnz_EntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_EntryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_EntryFilter>;
};


export type QuerySwnz_FormDropdownArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_FormDropdownCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_FormDropdownOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_FormDropdownFilter>;
};


export type QuerySwnz_FormDropdownOptionArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_FormDropdownOptionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_FormDropdownOptionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_FormDropdownOptionFilter>;
};


export type QuerySwnz_FormFieldCityArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_FormFieldCityCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_FormFieldCityOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_FormFieldCityFilter>;
};


export type QuerySwnz_FormLeadCaptureArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_FormLeadCaptureCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_FormLeadCaptureOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_FormLeadCaptureFilter>;
};


export type QuerySwnz_GalleryCarouselArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_GalleryCarouselCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_GalleryCarouselOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_GalleryCarouselFilter>;
};


export type QuerySwnz_GenericTextArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_GenericTextCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_GenericTextOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_GenericTextFilter>;
};


export type QuerySwnz_GlobalSettingsArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_GlobalSettingsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_GlobalSettingsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_GlobalSettingsFilter>;
};


export type QuerySwnz_GlossaryArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_GlossaryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_GlossaryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_GlossaryFilter>;
};


export type QuerySwnz_HeroArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_HeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_HeroOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_HeroFilter>;
};


export type QuerySwnz_HeroFeaturedArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_HeroFeaturedCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_HeroFeaturedOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_HeroFeaturedFilter>;
};


export type QuerySwnz_HeroFeaturedFloatingCardArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_HeroFeaturedFloatingCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_HeroFeaturedFloatingCardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_HeroFeaturedFloatingCardFilter>;
};


export type QuerySwnz_HeroTabArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_HeroTabCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_HeroTabOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_HeroTabFilter>;
};


export type QuerySwnz_HomepageCardArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_HomepageCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_HomepageCardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_HomepageCardFilter>;
};


export type QuerySwnz_HomepagePanelArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_HomepagePanelCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_HomepagePanelOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_HomepagePanelFilter>;
};


export type QuerySwnz_HomepageStoryPanelArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_HomepageStoryPanelCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_HomepageStoryPanelOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_HomepageStoryPanelFilter>;
};


export type QuerySwnz_IconCallToActionsArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_IconCallToActionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_IconCallToActionsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_IconCallToActionsFilter>;
};


export type QuerySwnz_ImpactTextArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_ImpactTextCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_ImpactTextOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_ImpactTextFilter>;
};


export type QuerySwnz_InfoBoxArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_InfoBoxCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_InfoBoxOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_InfoBoxFilter>;
};


export type QuerySwnz_IntroTextWithJumpMenuArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_IntroTextWithJumpMenuCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_IntroTextWithJumpMenuOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_IntroTextWithJumpMenuFilter>;
};


export type QuerySwnz_InvCourseArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_InvCourseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_InvCourseOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_InvCourseFilter>;
};


export type QuerySwnz_InvInstitutionArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_InvInstitutionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_InvInstitutionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_InvInstitutionFilter>;
};


export type QuerySwnz_JumpAheadArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_JumpAheadCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_JumpAheadOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_JumpAheadFilter>;
};


export type QuerySwnz_KeepExploringArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_KeepExploringCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_KeepExploringOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_KeepExploringFilter>;
};


export type QuerySwnz_MapContainerArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_MapContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MapContainerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_MapContainerFilter>;
};


export type QuerySwnz_MediaAudioArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_MediaAudioCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaAudioOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_MediaAudioFilter>;
};


export type QuerySwnz_MediaIconArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_MediaIconCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaIconOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_MediaIconFilter>;
};


export type QuerySwnz_MediaImageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_MediaImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_MediaImageFilter>;
};


export type QuerySwnz_MediaVideoArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_MediaVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaVideoOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_MediaVideoFilter>;
};


export type QuerySwnz_MigrationArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_MigrationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MigrationOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_MigrationFilter>;
};


export type QuerySwnz_NaumaiPageStandardArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_NaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_NaumaiPageStandardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_NaumaiPageStandardFilter>;
};


export type QuerySwnz_NavigationArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_NavigationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_NavigationOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_NavigationFilter>;
};


export type QuerySwnz_NavigationElementArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_NavigationElementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_NavigationElementOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_NavigationElementFilter>;
};


export type QuerySwnz_NextStepsArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_NextStepsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_NextStepsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_NextStepsFilter>;
};


export type QuerySwnz_NextStepsItemArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_NextStepsItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_NextStepsItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_NextStepsItemFilter>;
};


export type QuerySwnz_PageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_PageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_PageFilter>;
};


export type QuerySwnz_PageHomepageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_PageHomepageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PageHomepageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_PageHomepageFilter>;
};


export type QuerySwnz_PageModalArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_PageModalCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PageModalOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_PageModalFilter>;
};


export type QuerySwnz_PageSearchResultsArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_PageSearchResultsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PageSearchResultsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_PageSearchResultsFilter>;
};


export type QuerySwnz_PageStandardArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_PageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PageStandardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_PageStandardFilter>;
};


export type QuerySwnz_PromoArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_PromoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PromoOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_PromoFilter>;
};


export type QuerySwnz_RichMediaArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_RichMediaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_RichMediaOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_RichMediaFilter>;
};


export type QuerySwnz_SeoArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_SeoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_SeoOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_SeoFilter>;
};


export type QuerySwnz_StatArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_StatCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_StatOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_StatFilter>;
};


export type QuerySwnz_StatContainerArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_StatContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_StatContainerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_StatContainerFilter>;
};


export type QuerySwnz_StoryCardArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_StoryCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_StoryCardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_StoryCardFilter>;
};


export type QuerySwnz_StoryCardContainerArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_StoryCardContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_StoryCardContainerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_StoryCardContainerFilter>;
};


export type QuerySwnz_TileArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_TileCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_TileOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_TileFilter>;
};


export type QuerySwnz_TileContainerArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_TileContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_TileContainerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_TileContainerFilter>;
};


export type QuerySwnz_TranslationProjectArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_TranslationProjectCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_TranslationProjectOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_TranslationProjectFilter>;
};


export type QuerySwnz_TranslatorArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_TranslatorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_TranslatorOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_TranslatorFilter>;
};


export type QuerySwnz_ValueArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySwnz_ValueCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_ValueOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_ValueFilter>;
};

/** Container to display Accordion Items [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/accordionContainer) */
export type Swnz_AccordionContainer = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_AccordionContainer';
  _id: Scalars['ID']['output'];
  accordionItemsCollection?: Maybe<Swnz_AccordionContainerAccordionItemsCollection>;
  codeId?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  introText?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_AccordionContainerLinkingCollections>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** Container to display Accordion Items [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/accordionContainer) */
export type Swnz_AccordionContainerAccordionItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_AccordionContainerAccordionItemsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_AccordionItemFilter>;
};


/** Container to display Accordion Items [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/accordionContainer) */
export type Swnz_AccordionContainerCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display Accordion Items [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/accordionContainer) */
export type Swnz_AccordionContainerIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display Accordion Items [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/accordionContainer) */
export type Swnz_AccordionContainerIntroTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display Accordion Items [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/accordionContainer) */
export type Swnz_AccordionContainerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Container to display Accordion Items [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/accordionContainer) */
export type Swnz_AccordionContainerTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_AccordionContainerAccordionItemsCollection = {
  __typename?: 'SWNZ_AccordionContainerAccordionItemsCollection';
  items: Array<Maybe<Swnz_AccordionItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_AccordionContainerAccordionItemsCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Swnz_AccordionContainerCollection = {
  __typename?: 'SWNZ_AccordionContainerCollection';
  items: Array<Maybe<Swnz_AccordionContainer>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_AccordionContainerFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_AccordionContainerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_AccordionContainerFilter>>>;
  accordionItems?: InputMaybe<Swnz_CfAccordionItemNestedFilter>;
  accordionItemsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introText?: InputMaybe<Scalars['String']['input']>;
  introText_contains?: InputMaybe<Scalars['String']['input']>;
  introText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  introText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introText_not?: InputMaybe<Scalars['String']['input']>;
  introText_not_contains?: InputMaybe<Scalars['String']['input']>;
  introText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_AccordionContainerLinkingCollections = {
  __typename?: 'SWNZ_AccordionContainerLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_AccordionContainerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_AccordionContainerLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_AccordionContainerLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_AccordionContainerLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_AccordionContainerLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_AccordionContainerLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_AccordionContainerLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_AccordionContainerOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** An Accordion Item [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/accordionItem) */
export type Swnz_AccordionItem = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_AccordionItem';
  _id: Scalars['ID']['output'];
  bodyText?: Maybe<Swnz_AccordionItemBodyText>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_AccordionItemLinkingCollections>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** An Accordion Item [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/accordionItem) */
export type Swnz_AccordionItemBodyTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** An Accordion Item [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/accordionItem) */
export type Swnz_AccordionItemIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** An Accordion Item [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/accordionItem) */
export type Swnz_AccordionItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** An Accordion Item [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/accordionItem) */
export type Swnz_AccordionItemTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_AccordionItemBodyText = {
  __typename?: 'SWNZ_AccordionItemBodyText';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_AccordionItemBodyTextLinks;
};

export type Swnz_AccordionItemBodyTextAssets = {
  __typename?: 'SWNZ_AccordionItemBodyTextAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_AccordionItemBodyTextEntries = {
  __typename?: 'SWNZ_AccordionItemBodyTextEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_AccordionItemBodyTextLinks = {
  __typename?: 'SWNZ_AccordionItemBodyTextLinks';
  assets: Swnz_AccordionItemBodyTextAssets;
  entries: Swnz_AccordionItemBodyTextEntries;
  resources: Swnz_AccordionItemBodyTextResources;
};

export type Swnz_AccordionItemBodyTextResources = {
  __typename?: 'SWNZ_AccordionItemBodyTextResources';
  block: Array<Swnz_AccordionItemBodyTextResourcesBlock>;
  hyperlink: Array<Swnz_AccordionItemBodyTextResourcesHyperlink>;
  inline: Array<Swnz_AccordionItemBodyTextResourcesInline>;
};

export type Swnz_AccordionItemBodyTextResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_AccordionItemBodyTextResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_AccordionItemBodyTextResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_AccordionItemBodyTextResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_AccordionItemBodyTextResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_AccordionItemBodyTextResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_AccordionItemCollection = {
  __typename?: 'SWNZ_AccordionItemCollection';
  items: Array<Maybe<Swnz_AccordionItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_AccordionItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_AccordionItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_AccordionItemFilter>>>;
  bodyText_contains?: InputMaybe<Scalars['String']['input']>;
  bodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_AccordionItemLinkingCollections = {
  __typename?: 'SWNZ_AccordionItemLinkingCollections';
  accordionContainerCollection?: Maybe<Swnz_AccordionContainerCollection>;
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_AccordionItemLinkingCollectionsAccordionContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_AccordionItemLinkingCollectionsAccordionContainerCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_AccordionItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_AccordionItemLinkingCollectionsAccordionContainerCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_AccordionItemOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Alternating content of text and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingContent) */
export type Swnz_AlternatingContent = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_AlternatingContent';
  _id: Scalars['ID']['output'];
  bodyText?: Maybe<Swnz_AlternatingContentBodyText>;
  codeId?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_AlternatingContentLinkingCollections>;
  mediaItem?: Maybe<Swnz_AlternatingContentMediaItem>;
  mediaOrientation?: Maybe<Scalars['String']['output']>;
  mediaPlacement?: Maybe<Scalars['String']['output']>;
  primaryCta?: Maybe<Swnz_CallToAction>;
  secondaryCta?: Maybe<Swnz_CallToAction>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** Alternating content of text and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingContent) */
export type Swnz_AlternatingContentBodyTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Alternating content of text and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingContent) */
export type Swnz_AlternatingContentCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Alternating content of text and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingContent) */
export type Swnz_AlternatingContentIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Alternating content of text and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingContent) */
export type Swnz_AlternatingContentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Alternating content of text and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingContent) */
export type Swnz_AlternatingContentMediaItemArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_AlternatingContentMediaItemFilter>;
};


/** Alternating content of text and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingContent) */
export type Swnz_AlternatingContentMediaOrientationArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Alternating content of text and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingContent) */
export type Swnz_AlternatingContentMediaPlacementArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Alternating content of text and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingContent) */
export type Swnz_AlternatingContentPrimaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** Alternating content of text and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingContent) */
export type Swnz_AlternatingContentSecondaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** Alternating content of text and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingContent) */
export type Swnz_AlternatingContentTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_AlternatingContentBodyText = {
  __typename?: 'SWNZ_AlternatingContentBodyText';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_AlternatingContentBodyTextLinks;
};

export type Swnz_AlternatingContentBodyTextAssets = {
  __typename?: 'SWNZ_AlternatingContentBodyTextAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_AlternatingContentBodyTextEntries = {
  __typename?: 'SWNZ_AlternatingContentBodyTextEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_AlternatingContentBodyTextLinks = {
  __typename?: 'SWNZ_AlternatingContentBodyTextLinks';
  assets: Swnz_AlternatingContentBodyTextAssets;
  entries: Swnz_AlternatingContentBodyTextEntries;
  resources: Swnz_AlternatingContentBodyTextResources;
};

export type Swnz_AlternatingContentBodyTextResources = {
  __typename?: 'SWNZ_AlternatingContentBodyTextResources';
  block: Array<Swnz_AlternatingContentBodyTextResourcesBlock>;
  hyperlink: Array<Swnz_AlternatingContentBodyTextResourcesHyperlink>;
  inline: Array<Swnz_AlternatingContentBodyTextResourcesInline>;
};

export type Swnz_AlternatingContentBodyTextResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_AlternatingContentBodyTextResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_AlternatingContentBodyTextResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_AlternatingContentBodyTextResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_AlternatingContentBodyTextResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_AlternatingContentBodyTextResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_AlternatingContentCollection = {
  __typename?: 'SWNZ_AlternatingContentCollection';
  items: Array<Maybe<Swnz_AlternatingContent>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_AlternatingContentFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_AlternatingContentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_AlternatingContentFilter>>>;
  bodyText_contains?: InputMaybe<Scalars['String']['input']>;
  bodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mediaItem?: InputMaybe<Swnz_CfmediaItemMultiTypeNestedFilter>;
  mediaItem_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mediaOrientation?: InputMaybe<Scalars['String']['input']>;
  mediaOrientation_contains?: InputMaybe<Scalars['String']['input']>;
  mediaOrientation_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mediaOrientation_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mediaOrientation_not?: InputMaybe<Scalars['String']['input']>;
  mediaOrientation_not_contains?: InputMaybe<Scalars['String']['input']>;
  mediaOrientation_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mediaPlacement?: InputMaybe<Scalars['String']['input']>;
  mediaPlacement_contains?: InputMaybe<Scalars['String']['input']>;
  mediaPlacement_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mediaPlacement_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mediaPlacement_not?: InputMaybe<Scalars['String']['input']>;
  mediaPlacement_not_contains?: InputMaybe<Scalars['String']['input']>;
  mediaPlacement_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  primaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  primaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  secondaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_AlternatingContentLinkingCollections = {
  __typename?: 'SWNZ_AlternatingContentLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_AlternatingContentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_AlternatingContentLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_AlternatingContentLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_AlternatingContentLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_AlternatingContentLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_AlternatingContentLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_AlternatingContentLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Swnz_AlternatingContentMediaItem = Swnz_MediaImage | Swnz_MediaVideo;

export type Swnz_AlternatingContentMediaItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_AlternatingContentMediaItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_AlternatingContentMediaItemFilter>>>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_contains?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_not?: InputMaybe<Scalars['String']['input']>;
  caption_not_contains?: InputMaybe<Scalars['String']['input']>;
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export enum Swnz_AlternatingContentOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  MediaOrientationAsc = 'mediaOrientation_ASC',
  MediaOrientationDesc = 'mediaOrientation_DESC',
  MediaPlacementAsc = 'mediaPlacement_ASC',
  MediaPlacementDesc = 'mediaPlacement_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Alternating content of quote and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingQuote) */
export type Swnz_AlternatingQuote = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_AlternatingQuote';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  credit?: Maybe<Scalars['String']['output']>;
  creditSuffix?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_AlternatingQuoteLinkingCollections>;
  mediaItem?: Maybe<Swnz_MediaImage>;
  mediaOrientation?: Maybe<Scalars['String']['output']>;
  mediaPlacement?: Maybe<Scalars['String']['output']>;
  quoteText?: Maybe<Scalars['String']['output']>;
  quoteTextColour?: Maybe<Scalars['String']['output']>;
  sys: Swnz_Sys;
};


/** Alternating content of quote and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingQuote) */
export type Swnz_AlternatingQuoteCreditArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Alternating content of quote and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingQuote) */
export type Swnz_AlternatingQuoteCreditSuffixArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Alternating content of quote and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingQuote) */
export type Swnz_AlternatingQuoteIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Alternating content of quote and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingQuote) */
export type Swnz_AlternatingQuoteLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Alternating content of quote and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingQuote) */
export type Swnz_AlternatingQuoteMediaItemArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_MediaImageFilter>;
};


/** Alternating content of quote and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingQuote) */
export type Swnz_AlternatingQuoteMediaOrientationArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Alternating content of quote and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingQuote) */
export type Swnz_AlternatingQuoteMediaPlacementArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Alternating content of quote and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingQuote) */
export type Swnz_AlternatingQuoteQuoteTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Alternating content of quote and MediaItem [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/alternatingQuote) */
export type Swnz_AlternatingQuoteQuoteTextColourArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_AlternatingQuoteCollection = {
  __typename?: 'SWNZ_AlternatingQuoteCollection';
  items: Array<Maybe<Swnz_AlternatingQuote>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_AlternatingQuoteFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_AlternatingQuoteFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_AlternatingQuoteFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  credit?: InputMaybe<Scalars['String']['input']>;
  creditSuffix?: InputMaybe<Scalars['String']['input']>;
  creditSuffix_contains?: InputMaybe<Scalars['String']['input']>;
  creditSuffix_exists?: InputMaybe<Scalars['Boolean']['input']>;
  creditSuffix_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  creditSuffix_not?: InputMaybe<Scalars['String']['input']>;
  creditSuffix_not_contains?: InputMaybe<Scalars['String']['input']>;
  creditSuffix_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  credit_contains?: InputMaybe<Scalars['String']['input']>;
  credit_exists?: InputMaybe<Scalars['Boolean']['input']>;
  credit_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  credit_not?: InputMaybe<Scalars['String']['input']>;
  credit_not_contains?: InputMaybe<Scalars['String']['input']>;
  credit_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mediaItem?: InputMaybe<Swnz_CfMediaImageNestedFilter>;
  mediaItem_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mediaOrientation?: InputMaybe<Scalars['String']['input']>;
  mediaOrientation_contains?: InputMaybe<Scalars['String']['input']>;
  mediaOrientation_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mediaOrientation_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mediaOrientation_not?: InputMaybe<Scalars['String']['input']>;
  mediaOrientation_not_contains?: InputMaybe<Scalars['String']['input']>;
  mediaOrientation_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mediaPlacement?: InputMaybe<Scalars['String']['input']>;
  mediaPlacement_contains?: InputMaybe<Scalars['String']['input']>;
  mediaPlacement_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mediaPlacement_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mediaPlacement_not?: InputMaybe<Scalars['String']['input']>;
  mediaPlacement_not_contains?: InputMaybe<Scalars['String']['input']>;
  mediaPlacement_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  quoteText?: InputMaybe<Scalars['String']['input']>;
  quoteTextColour?: InputMaybe<Scalars['String']['input']>;
  quoteTextColour_contains?: InputMaybe<Scalars['String']['input']>;
  quoteTextColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  quoteTextColour_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  quoteTextColour_not?: InputMaybe<Scalars['String']['input']>;
  quoteTextColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  quoteTextColour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  quoteText_contains?: InputMaybe<Scalars['String']['input']>;
  quoteText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  quoteText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  quoteText_not?: InputMaybe<Scalars['String']['input']>;
  quoteText_not_contains?: InputMaybe<Scalars['String']['input']>;
  quoteText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_AlternatingQuoteLinkingCollections = {
  __typename?: 'SWNZ_AlternatingQuoteLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_AlternatingQuoteLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_AlternatingQuoteLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_AlternatingQuoteLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_AlternatingQuoteLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_AlternatingQuoteLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_AlternatingQuoteLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_AlternatingQuoteLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_AlternatingQuoteOrder {
  CreditSuffixAsc = 'creditSuffix_ASC',
  CreditSuffixDesc = 'creditSuffix_DESC',
  CreditAsc = 'credit_ASC',
  CreditDesc = 'credit_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  MediaOrientationAsc = 'mediaOrientation_ASC',
  MediaOrientationDesc = 'mediaOrientation_DESC',
  MediaPlacementAsc = 'mediaPlacement_ASC',
  MediaPlacementDesc = 'mediaPlacement_DESC',
  QuoteTextColourAsc = 'quoteTextColour_ASC',
  QuoteTextColourDesc = 'quoteTextColour_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Represents a binary file in a space. An asset can be any file type. */
export type Swnz_Asset = {
  __typename?: 'SWNZ_Asset';
  contentType?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  description?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  linkedFrom?: Maybe<Swnz_AssetLinkingCollections>;
  size?: Maybe<Scalars['Int']['output']>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type Swnz_AssetContentTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type Swnz_AssetDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type Swnz_AssetFileNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type Swnz_AssetHeightArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type Swnz_AssetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type Swnz_AssetSizeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type Swnz_AssetTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type Swnz_AssetUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  transform?: InputMaybe<Swnz_ImageTransformOptions>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type Swnz_AssetWidthArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_AssetCollection = {
  __typename?: 'SWNZ_AssetCollection';
  items: Array<Maybe<Swnz_Asset>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_AssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_AssetFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_AssetFilter>>>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  contentType_contains?: InputMaybe<Scalars['String']['input']>;
  contentType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentType_not?: InputMaybe<Scalars['String']['input']>;
  contentType_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileName_contains?: InputMaybe<Scalars['String']['input']>;
  fileName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fileName_not?: InputMaybe<Scalars['String']['input']>;
  fileName_not_contains?: InputMaybe<Scalars['String']['input']>;
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  height?: InputMaybe<Scalars['Int']['input']>;
  height_exists?: InputMaybe<Scalars['Boolean']['input']>;
  height_gt?: InputMaybe<Scalars['Int']['input']>;
  height_gte?: InputMaybe<Scalars['Int']['input']>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  height_lt?: InputMaybe<Scalars['Int']['input']>;
  height_lte?: InputMaybe<Scalars['Int']['input']>;
  height_not?: InputMaybe<Scalars['Int']['input']>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  size?: InputMaybe<Scalars['Int']['input']>;
  size_exists?: InputMaybe<Scalars['Boolean']['input']>;
  size_gt?: InputMaybe<Scalars['Int']['input']>;
  size_gte?: InputMaybe<Scalars['Int']['input']>;
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  size_lt?: InputMaybe<Scalars['Int']['input']>;
  size_lte?: InputMaybe<Scalars['Int']['input']>;
  size_not?: InputMaybe<Scalars['Int']['input']>;
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_contains?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not?: InputMaybe<Scalars['String']['input']>;
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  width?: InputMaybe<Scalars['Int']['input']>;
  width_exists?: InputMaybe<Scalars['Boolean']['input']>;
  width_gt?: InputMaybe<Scalars['Int']['input']>;
  width_gte?: InputMaybe<Scalars['Int']['input']>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  width_lt?: InputMaybe<Scalars['Int']['input']>;
  width_lte?: InputMaybe<Scalars['Int']['input']>;
  width_not?: InputMaybe<Scalars['Int']['input']>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type Swnz_AssetLinkingCollections = {
  __typename?: 'SWNZ_AssetLinkingCollections';
  blogAuthorCollection?: Maybe<Swnz_BlogAuthorCollection>;
  blogCardWithLinkCollection?: Maybe<Swnz_BlogCardWithLinkCollection>;
  cardWithLinkCollection?: Maybe<Swnz_CardWithLinkCollection>;
  entryCollection?: Maybe<Swnz_EntryCollection>;
  globalSettingsCollection?: Maybe<Swnz_GlobalSettingsCollection>;
  heroCollection?: Maybe<Swnz_HeroCollection>;
  invInstitutionCollection?: Maybe<Swnz_InvInstitutionCollection>;
  mediaAudioCollection?: Maybe<Swnz_MediaAudioCollection>;
  mediaIconCollection?: Maybe<Swnz_MediaIconCollection>;
  mediaImageCollection?: Maybe<Swnz_MediaImageCollection>;
  mediaVideoCollection?: Maybe<Swnz_MediaVideoCollection>;
  pageHomepageCollection?: Maybe<Swnz_PageHomepageCollection>;
  valueCollection?: Maybe<Swnz_ValueCollection>;
};


export type Swnz_AssetLinkingCollectionsBlogAuthorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_AssetLinkingCollectionsBlogCardWithLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_AssetLinkingCollectionsCardWithLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_AssetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_AssetLinkingCollectionsGlobalSettingsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_AssetLinkingCollectionsHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_AssetLinkingCollectionsInvInstitutionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_AssetLinkingCollectionsMediaAudioCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_AssetLinkingCollectionsMediaIconCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_AssetLinkingCollectionsMediaImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_AssetLinkingCollectionsMediaVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_AssetLinkingCollectionsPageHomepageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_AssetLinkingCollectionsValueCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_AssetOrder {
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC'
}

/** Generic banner with full width content [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/banner) */
export type Swnz_Banner = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_Banner';
  _id: Scalars['ID']['output'];
  backgroundColour?: Maybe<Scalars['String']['output']>;
  bodyText?: Maybe<Swnz_BannerBodyText>;
  codeId?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_BannerLinkingCollections>;
  primaryCta?: Maybe<Swnz_CallToAction>;
  secondaryCta?: Maybe<Swnz_CallToAction>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** Generic banner with full width content [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/banner) */
export type Swnz_BannerBackgroundColourArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Generic banner with full width content [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/banner) */
export type Swnz_BannerBodyTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Generic banner with full width content [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/banner) */
export type Swnz_BannerCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Generic banner with full width content [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/banner) */
export type Swnz_BannerIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Generic banner with full width content [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/banner) */
export type Swnz_BannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Generic banner with full width content [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/banner) */
export type Swnz_BannerPrimaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** Generic banner with full width content [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/banner) */
export type Swnz_BannerSecondaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** Generic banner with full width content [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/banner) */
export type Swnz_BannerTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_BannerBodyText = {
  __typename?: 'SWNZ_BannerBodyText';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_BannerBodyTextLinks;
};

export type Swnz_BannerBodyTextAssets = {
  __typename?: 'SWNZ_BannerBodyTextAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_BannerBodyTextEntries = {
  __typename?: 'SWNZ_BannerBodyTextEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_BannerBodyTextLinks = {
  __typename?: 'SWNZ_BannerBodyTextLinks';
  assets: Swnz_BannerBodyTextAssets;
  entries: Swnz_BannerBodyTextEntries;
  resources: Swnz_BannerBodyTextResources;
};

export type Swnz_BannerBodyTextResources = {
  __typename?: 'SWNZ_BannerBodyTextResources';
  block: Array<Swnz_BannerBodyTextResourcesBlock>;
  hyperlink: Array<Swnz_BannerBodyTextResourcesHyperlink>;
  inline: Array<Swnz_BannerBodyTextResourcesInline>;
};

export type Swnz_BannerBodyTextResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_BannerBodyTextResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_BannerBodyTextResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_BannerBodyTextResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_BannerBodyTextResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_BannerBodyTextResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_BannerCollection = {
  __typename?: 'SWNZ_BannerCollection';
  items: Array<Maybe<Swnz_Banner>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_BannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_BannerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_BannerFilter>>>;
  backgroundColour?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColour_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  backgroundColour_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bodyText_contains?: InputMaybe<Scalars['String']['input']>;
  bodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  primaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  primaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  secondaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_BannerLinkingCollections = {
  __typename?: 'SWNZ_BannerLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_BannerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_BannerLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BannerLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_BannerLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BannerLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_BannerLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_BannerLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_BannerOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A featured block for rich text, inside Generic Text [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blockquote) */
export type Swnz_Blockquote = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_Blockquote';
  _id: Scalars['ID']['output'];
  bodyText?: Maybe<Swnz_BlockquoteBodyText>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_BlockquoteLinkingCollections>;
  sys: Swnz_Sys;
};


/** A featured block for rich text, inside Generic Text [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blockquote) */
export type Swnz_BlockquoteBodyTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A featured block for rich text, inside Generic Text [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blockquote) */
export type Swnz_BlockquoteIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A featured block for rich text, inside Generic Text [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blockquote) */
export type Swnz_BlockquoteLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_BlockquoteBodyText = {
  __typename?: 'SWNZ_BlockquoteBodyText';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_BlockquoteBodyTextLinks;
};

export type Swnz_BlockquoteBodyTextAssets = {
  __typename?: 'SWNZ_BlockquoteBodyTextAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_BlockquoteBodyTextEntries = {
  __typename?: 'SWNZ_BlockquoteBodyTextEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_BlockquoteBodyTextLinks = {
  __typename?: 'SWNZ_BlockquoteBodyTextLinks';
  assets: Swnz_BlockquoteBodyTextAssets;
  entries: Swnz_BlockquoteBodyTextEntries;
  resources: Swnz_BlockquoteBodyTextResources;
};

export type Swnz_BlockquoteBodyTextResources = {
  __typename?: 'SWNZ_BlockquoteBodyTextResources';
  block: Array<Swnz_BlockquoteBodyTextResourcesBlock>;
  hyperlink: Array<Swnz_BlockquoteBodyTextResourcesHyperlink>;
  inline: Array<Swnz_BlockquoteBodyTextResourcesInline>;
};

export type Swnz_BlockquoteBodyTextResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_BlockquoteBodyTextResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_BlockquoteBodyTextResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_BlockquoteBodyTextResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_BlockquoteBodyTextResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_BlockquoteBodyTextResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_BlockquoteCollection = {
  __typename?: 'SWNZ_BlockquoteCollection';
  items: Array<Maybe<Swnz_Blockquote>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_BlockquoteFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_BlockquoteFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_BlockquoteFilter>>>;
  bodyText_contains?: InputMaybe<Scalars['String']['input']>;
  bodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_BlockquoteLinkingCollections = {
  __typename?: 'SWNZ_BlockquoteLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_BlockquoteLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_BlockquoteLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BlockquoteLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_BlockquoteLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BlockquoteLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_BlockquoteLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_BlockquoteLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_BlockquoteOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Blog Authors [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogAuthor) */
export type Swnz_BlogAuthor = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_BlogAuthor';
  _id: Scalars['ID']['output'];
  authorImage?: Maybe<Swnz_Asset>;
  bio?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  fullName?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_BlogAuthorLinkingCollections>;
  subTitle?: Maybe<Scalars['String']['output']>;
  sys: Swnz_Sys;
};


/** Blog Authors [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogAuthor) */
export type Swnz_BlogAuthorAuthorImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Blog Authors [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogAuthor) */
export type Swnz_BlogAuthorBioArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Authors [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogAuthor) */
export type Swnz_BlogAuthorFullNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Authors [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogAuthor) */
export type Swnz_BlogAuthorIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Authors [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogAuthor) */
export type Swnz_BlogAuthorLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Blog Authors [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogAuthor) */
export type Swnz_BlogAuthorSubTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_BlogAuthorCollection = {
  __typename?: 'SWNZ_BlogAuthorCollection';
  items: Array<Maybe<Swnz_BlogAuthor>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_BlogAuthorFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_BlogAuthorFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_BlogAuthorFilter>>>;
  authorImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bio_contains?: InputMaybe<Scalars['String']['input']>;
  bio_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bio_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bio_not?: InputMaybe<Scalars['String']['input']>;
  bio_not_contains?: InputMaybe<Scalars['String']['input']>;
  bio_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullName_contains?: InputMaybe<Scalars['String']['input']>;
  fullName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fullName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fullName_not?: InputMaybe<Scalars['String']['input']>;
  fullName_not_contains?: InputMaybe<Scalars['String']['input']>;
  fullName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  subTitle_contains?: InputMaybe<Scalars['String']['input']>;
  subTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subTitle_not?: InputMaybe<Scalars['String']['input']>;
  subTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  subTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_BlogAuthorLinkingCollections = {
  __typename?: 'SWNZ_BlogAuthorLinkingCollections';
  blogPostCollection?: Maybe<Swnz_BlogPostCollection>;
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_BlogAuthorLinkingCollectionsBlogPostCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BlogAuthorLinkingCollectionsBlogPostCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_BlogAuthorLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_BlogAuthorLinkingCollectionsBlogPostCollectionOrder {
  FeaturedBlogPositionAsc = 'featuredBlogPosition_ASC',
  FeaturedBlogPositionDesc = 'featuredBlogPosition_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LastUpdatedDateAsc = 'lastUpdatedDate_ASC',
  LastUpdatedDateDesc = 'lastUpdatedDate_DESC',
  NoFollowAsc = 'no_follow_ASC',
  NoFollowDesc = 'no_follow_DESC',
  NoIndexAsc = 'no_index_ASC',
  NoIndexDesc = 'no_index_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SeoTitleAsc = 'seoTitle_ASC',
  SeoTitleDesc = 'seoTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagBrandPillarsAsc = 'tagBrandPillars_ASC',
  TagBrandPillarsDesc = 'tagBrandPillars_DESC',
  TagCampaignAsc = 'tagCampaign_ASC',
  TagCampaignDesc = 'tagCampaign_DESC',
  TagLearnerJourneyStageAsc = 'tagLearnerJourneyStage_ASC',
  TagLearnerJourneyStageDesc = 'tagLearnerJourneyStage_DESC',
  TagTopicAsc = 'tagTopic_ASC',
  TagTopicDesc = 'tagTopic_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_BlogAuthorOrder {
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SubTitleAsc = 'subTitle_ASC',
  SubTitleDesc = 'subTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Blog CTA buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCallToAction) */
export type Swnz_BlogCallToAction = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_BlogCallToAction';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  internalLink?: Maybe<Swnz_BlogPost>;
  label?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_BlogCallToActionLinkingCollections>;
  openInNewWindow?: Maybe<Scalars['Boolean']['output']>;
  sys: Swnz_Sys;
  urlLink?: Maybe<Scalars['String']['output']>;
};


/** Blog CTA buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCallToAction) */
export type Swnz_BlogCallToActionIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog CTA buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCallToAction) */
export type Swnz_BlogCallToActionInternalLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_BlogPostFilter>;
};


/** Blog CTA buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCallToAction) */
export type Swnz_BlogCallToActionLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog CTA buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCallToAction) */
export type Swnz_BlogCallToActionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Blog CTA buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCallToAction) */
export type Swnz_BlogCallToActionOpenInNewWindowArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog CTA buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCallToAction) */
export type Swnz_BlogCallToActionUrlLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_BlogCallToActionCollection = {
  __typename?: 'SWNZ_BlogCallToActionCollection';
  items: Array<Maybe<Swnz_BlogCallToAction>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_BlogCallToActionFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_BlogCallToActionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_BlogCallToActionFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  internalLink?: InputMaybe<Swnz_CfBlogPostNestedFilter>;
  internalLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  openInNewWindow?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewWindow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewWindow_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  urlLink?: InputMaybe<Scalars['String']['input']>;
  urlLink_contains?: InputMaybe<Scalars['String']['input']>;
  urlLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  urlLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  urlLink_not?: InputMaybe<Scalars['String']['input']>;
  urlLink_not_contains?: InputMaybe<Scalars['String']['input']>;
  urlLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_BlogCallToActionLinkingCollections = {
  __typename?: 'SWNZ_BlogCallToActionLinkingCollections';
  blogImpactTextCollection?: Maybe<Swnz_BlogImpactTextCollection>;
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_BlogCallToActionLinkingCollectionsBlogImpactTextCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BlogCallToActionLinkingCollectionsBlogImpactTextCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_BlogCallToActionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_BlogCallToActionLinkingCollectionsBlogImpactTextCollectionOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DisplayAsQuoteAsc = 'displayAsQuote_ASC',
  DisplayAsQuoteDesc = 'displayAsQuote_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextColourAsc = 'textColour_ASC',
  TextColourDesc = 'textColour_DESC'
}

export enum Swnz_BlogCallToActionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  OpenInNewWindowAsc = 'openInNewWindow_ASC',
  OpenInNewWindowDesc = 'openInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlLinkAsc = 'urlLink_ASC',
  UrlLinkDesc = 'urlLink_DESC'
}

/** Blogs: Card with a URL or Blog Page link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCardWithLink) */
export type Swnz_BlogCardWithLink = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_BlogCardWithLink';
  _id: Scalars['ID']['output'];
  assetLink?: Maybe<Swnz_Asset>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  customUrl?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Swnz_MediaImage>;
  link?: Maybe<Swnz_BlogPost>;
  linkedFrom?: Maybe<Swnz_BlogCardWithLinkLinkingCollections>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** Blogs: Card with a URL or Blog Page link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCardWithLink) */
export type Swnz_BlogCardWithLinkAssetLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Blogs: Card with a URL or Blog Page link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCardWithLink) */
export type Swnz_BlogCardWithLinkCustomUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blogs: Card with a URL or Blog Page link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCardWithLink) */
export type Swnz_BlogCardWithLinkDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blogs: Card with a URL or Blog Page link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCardWithLink) */
export type Swnz_BlogCardWithLinkIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blogs: Card with a URL or Blog Page link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCardWithLink) */
export type Swnz_BlogCardWithLinkImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_MediaImageFilter>;
};


/** Blogs: Card with a URL or Blog Page link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCardWithLink) */
export type Swnz_BlogCardWithLinkLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_BlogPostFilter>;
};


/** Blogs: Card with a URL or Blog Page link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCardWithLink) */
export type Swnz_BlogCardWithLinkLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Blogs: Card with a URL or Blog Page link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCardWithLink) */
export type Swnz_BlogCardWithLinkTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_BlogCardWithLinkCollection = {
  __typename?: 'SWNZ_BlogCardWithLinkCollection';
  items: Array<Maybe<Swnz_BlogCardWithLink>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_BlogCardWithLinkFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_BlogCardWithLinkFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_BlogCardWithLinkFilter>>>;
  assetLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  customUrl?: InputMaybe<Scalars['String']['input']>;
  customUrl_contains?: InputMaybe<Scalars['String']['input']>;
  customUrl_exists?: InputMaybe<Scalars['Boolean']['input']>;
  customUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  customUrl_not?: InputMaybe<Scalars['String']['input']>;
  customUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  customUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<Swnz_CfMediaImageNestedFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<Swnz_CfBlogPostNestedFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_BlogCardWithLinkLinkingCollections = {
  __typename?: 'SWNZ_BlogCardWithLinkLinkingCollections';
  blogPostCollection?: Maybe<Swnz_BlogPostCollection>;
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_BlogCardWithLinkLinkingCollectionsBlogPostCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BlogCardWithLinkLinkingCollectionsBlogPostCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_BlogCardWithLinkLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_BlogCardWithLinkLinkingCollectionsBlogPostCollectionOrder {
  FeaturedBlogPositionAsc = 'featuredBlogPosition_ASC',
  FeaturedBlogPositionDesc = 'featuredBlogPosition_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LastUpdatedDateAsc = 'lastUpdatedDate_ASC',
  LastUpdatedDateDesc = 'lastUpdatedDate_DESC',
  NoFollowAsc = 'no_follow_ASC',
  NoFollowDesc = 'no_follow_DESC',
  NoIndexAsc = 'no_index_ASC',
  NoIndexDesc = 'no_index_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SeoTitleAsc = 'seoTitle_ASC',
  SeoTitleDesc = 'seoTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagBrandPillarsAsc = 'tagBrandPillars_ASC',
  TagBrandPillarsDesc = 'tagBrandPillars_DESC',
  TagCampaignAsc = 'tagCampaign_ASC',
  TagCampaignDesc = 'tagCampaign_DESC',
  TagLearnerJourneyStageAsc = 'tagLearnerJourneyStage_ASC',
  TagLearnerJourneyStageDesc = 'tagLearnerJourneyStage_DESC',
  TagTopicAsc = 'tagTopic_ASC',
  TagTopicDesc = 'tagTopic_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_BlogCardWithLinkOrder {
  CustomUrlAsc = 'customUrl_ASC',
  CustomUrlDesc = 'customUrl_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Selected tags from the Taxonomy API for Blog Categories [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCategory) */
export type Swnz_BlogCategory = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_BlogCategory';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  description?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_BlogCategoryLinkingCollections>;
  order?: Maybe<Scalars['Int']['output']>;
  sys: Swnz_Sys;
};


/** Selected tags from the Taxonomy API for Blog Categories [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCategory) */
export type Swnz_BlogCategoryDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Selected tags from the Taxonomy API for Blog Categories [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCategory) */
export type Swnz_BlogCategoryKeyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Selected tags from the Taxonomy API for Blog Categories [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCategory) */
export type Swnz_BlogCategoryLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Selected tags from the Taxonomy API for Blog Categories [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCategory) */
export type Swnz_BlogCategoryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Selected tags from the Taxonomy API for Blog Categories [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCategory) */
export type Swnz_BlogCategoryOrderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_BlogCategoryCollection = {
  __typename?: 'SWNZ_BlogCategoryCollection';
  items: Array<Maybe<Swnz_BlogCategory>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_BlogCategoryFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_BlogCategoryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_BlogCategoryFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_exists?: InputMaybe<Scalars['Boolean']['input']>;
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  order?: InputMaybe<Scalars['Int']['input']>;
  order_exists?: InputMaybe<Scalars['Boolean']['input']>;
  order_gt?: InputMaybe<Scalars['Int']['input']>;
  order_gte?: InputMaybe<Scalars['Int']['input']>;
  order_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  order_lt?: InputMaybe<Scalars['Int']['input']>;
  order_lte?: InputMaybe<Scalars['Int']['input']>;
  order_not?: InputMaybe<Scalars['Int']['input']>;
  order_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_BlogCategoryLinkingCollections = {
  __typename?: 'SWNZ_BlogCategoryLinkingCollections';
  blogPostCollection?: Maybe<Swnz_BlogPostCollection>;
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_BlogCategoryLinkingCollectionsBlogPostCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BlogCategoryLinkingCollectionsBlogPostCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_BlogCategoryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_BlogCategoryLinkingCollectionsBlogPostCollectionOrder {
  FeaturedBlogPositionAsc = 'featuredBlogPosition_ASC',
  FeaturedBlogPositionDesc = 'featuredBlogPosition_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LastUpdatedDateAsc = 'lastUpdatedDate_ASC',
  LastUpdatedDateDesc = 'lastUpdatedDate_DESC',
  NoFollowAsc = 'no_follow_ASC',
  NoFollowDesc = 'no_follow_DESC',
  NoIndexAsc = 'no_index_ASC',
  NoIndexDesc = 'no_index_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SeoTitleAsc = 'seoTitle_ASC',
  SeoTitleDesc = 'seoTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagBrandPillarsAsc = 'tagBrandPillars_ASC',
  TagBrandPillarsDesc = 'tagBrandPillars_DESC',
  TagCampaignAsc = 'tagCampaign_ASC',
  TagCampaignDesc = 'tagCampaign_DESC',
  TagLearnerJourneyStageAsc = 'tagLearnerJourneyStage_ASC',
  TagLearnerJourneyStageDesc = 'tagLearnerJourneyStage_DESC',
  TagTopicAsc = 'tagTopic_ASC',
  TagTopicDesc = 'tagTopic_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_BlogCategoryOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Custom campaign tags for blogs [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCustomCampaignTags) */
export type Swnz_BlogCustomCampaignTags = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_BlogCustomCampaignTags';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  key?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_BlogCustomCampaignTagsLinkingCollections>;
  sys: Swnz_Sys;
};


/** Custom campaign tags for blogs [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCustomCampaignTags) */
export type Swnz_BlogCustomCampaignTagsKeyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Custom campaign tags for blogs [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCustomCampaignTags) */
export type Swnz_BlogCustomCampaignTagsLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Custom campaign tags for blogs [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogCustomCampaignTags) */
export type Swnz_BlogCustomCampaignTagsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_BlogCustomCampaignTagsCollection = {
  __typename?: 'SWNZ_BlogCustomCampaignTagsCollection';
  items: Array<Maybe<Swnz_BlogCustomCampaignTags>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_BlogCustomCampaignTagsFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_BlogCustomCampaignTagsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_BlogCustomCampaignTagsFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_exists?: InputMaybe<Scalars['Boolean']['input']>;
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_BlogCustomCampaignTagsLinkingCollections = {
  __typename?: 'SWNZ_BlogCustomCampaignTagsLinkingCollections';
  blogPostCollection?: Maybe<Swnz_BlogPostCollection>;
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_BlogCustomCampaignTagsLinkingCollectionsBlogPostCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BlogCustomCampaignTagsLinkingCollectionsBlogPostCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_BlogCustomCampaignTagsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_BlogCustomCampaignTagsLinkingCollectionsBlogPostCollectionOrder {
  FeaturedBlogPositionAsc = 'featuredBlogPosition_ASC',
  FeaturedBlogPositionDesc = 'featuredBlogPosition_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LastUpdatedDateAsc = 'lastUpdatedDate_ASC',
  LastUpdatedDateDesc = 'lastUpdatedDate_DESC',
  NoFollowAsc = 'no_follow_ASC',
  NoFollowDesc = 'no_follow_DESC',
  NoIndexAsc = 'no_index_ASC',
  NoIndexDesc = 'no_index_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SeoTitleAsc = 'seoTitle_ASC',
  SeoTitleDesc = 'seoTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagBrandPillarsAsc = 'tagBrandPillars_ASC',
  TagBrandPillarsDesc = 'tagBrandPillars_DESC',
  TagCampaignAsc = 'tagCampaign_ASC',
  TagCampaignDesc = 'tagCampaign_DESC',
  TagLearnerJourneyStageAsc = 'tagLearnerJourneyStage_ASC',
  TagLearnerJourneyStageDesc = 'tagLearnerJourneyStage_DESC',
  TagTopicAsc = 'tagTopic_ASC',
  TagTopicDesc = 'tagTopic_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_BlogCustomCampaignTagsOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Quote or impact statement for Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogImpactText) */
export type Swnz_BlogImpactText = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_BlogImpactText';
  _id: Scalars['ID']['output'];
  backgroundColour?: Maybe<Scalars['String']['output']>;
  bodyText?: Maybe<Swnz_BlogImpactTextBodyText>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  description?: Maybe<Scalars['String']['output']>;
  displayAsQuote?: Maybe<Scalars['Boolean']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_BlogImpactTextLinkingCollections>;
  primaryCta?: Maybe<Swnz_BlogCallToAction>;
  secondaryCta?: Maybe<Swnz_BlogCallToAction>;
  sys: Swnz_Sys;
  textColour?: Maybe<Scalars['String']['output']>;
};


/** Quote or impact statement for Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogImpactText) */
export type Swnz_BlogImpactTextBackgroundColourArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Quote or impact statement for Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogImpactText) */
export type Swnz_BlogImpactTextBodyTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Quote or impact statement for Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogImpactText) */
export type Swnz_BlogImpactTextDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Quote or impact statement for Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogImpactText) */
export type Swnz_BlogImpactTextDisplayAsQuoteArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Quote or impact statement for Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogImpactText) */
export type Swnz_BlogImpactTextIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Quote or impact statement for Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogImpactText) */
export type Swnz_BlogImpactTextLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Quote or impact statement for Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogImpactText) */
export type Swnz_BlogImpactTextPrimaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_BlogCallToActionFilter>;
};


/** Quote or impact statement for Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogImpactText) */
export type Swnz_BlogImpactTextSecondaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_BlogCallToActionFilter>;
};


/** Quote or impact statement for Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogImpactText) */
export type Swnz_BlogImpactTextTextColourArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_BlogImpactTextBodyText = {
  __typename?: 'SWNZ_BlogImpactTextBodyText';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_BlogImpactTextBodyTextLinks;
};

export type Swnz_BlogImpactTextBodyTextAssets = {
  __typename?: 'SWNZ_BlogImpactTextBodyTextAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_BlogImpactTextBodyTextEntries = {
  __typename?: 'SWNZ_BlogImpactTextBodyTextEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_BlogImpactTextBodyTextLinks = {
  __typename?: 'SWNZ_BlogImpactTextBodyTextLinks';
  assets: Swnz_BlogImpactTextBodyTextAssets;
  entries: Swnz_BlogImpactTextBodyTextEntries;
  resources: Swnz_BlogImpactTextBodyTextResources;
};

export type Swnz_BlogImpactTextBodyTextResources = {
  __typename?: 'SWNZ_BlogImpactTextBodyTextResources';
  block: Array<Swnz_BlogImpactTextBodyTextResourcesBlock>;
  hyperlink: Array<Swnz_BlogImpactTextBodyTextResourcesHyperlink>;
  inline: Array<Swnz_BlogImpactTextBodyTextResourcesInline>;
};

export type Swnz_BlogImpactTextBodyTextResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_BlogImpactTextBodyTextResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_BlogImpactTextBodyTextResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_BlogImpactTextBodyTextResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_BlogImpactTextBodyTextResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_BlogImpactTextBodyTextResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_BlogImpactTextCollection = {
  __typename?: 'SWNZ_BlogImpactTextCollection';
  items: Array<Maybe<Swnz_BlogImpactText>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_BlogImpactTextFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_BlogImpactTextFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_BlogImpactTextFilter>>>;
  backgroundColour?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColour_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  backgroundColour_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bodyText_contains?: InputMaybe<Scalars['String']['input']>;
  bodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayAsQuote?: InputMaybe<Scalars['Boolean']['input']>;
  displayAsQuote_exists?: InputMaybe<Scalars['Boolean']['input']>;
  displayAsQuote_not?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  primaryCta?: InputMaybe<Swnz_CfBlogCallToActionNestedFilter>;
  primaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondaryCta?: InputMaybe<Swnz_CfBlogCallToActionNestedFilter>;
  secondaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  textColour?: InputMaybe<Scalars['String']['input']>;
  textColour_contains?: InputMaybe<Scalars['String']['input']>;
  textColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  textColour_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  textColour_not?: InputMaybe<Scalars['String']['input']>;
  textColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  textColour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_BlogImpactTextLinkingCollections = {
  __typename?: 'SWNZ_BlogImpactTextLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_BlogImpactTextLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_BlogImpactTextOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DisplayAsQuoteAsc = 'displayAsQuote_ASC',
  DisplayAsQuoteDesc = 'displayAsQuote_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextColourAsc = 'textColour_ASC',
  TextColourDesc = 'textColour_DESC'
}

/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPost = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_BlogPost';
  _id: Scalars['ID']['output'];
  areaOfStudy?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  authorsCollection?: Maybe<Swnz_BlogPostAuthorsCollection>;
  bodyContent?: Maybe<Swnz_BlogPostBodyContent>;
  brandPillars?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  campaign?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  category?: Maybe<Swnz_BlogCategory>;
  chinaRegion?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  featuredBlogPosition?: Maybe<Scalars['Int']['output']>;
  hero?: Maybe<Swnz_BlogPostHero>;
  identifier?: Maybe<Scalars['String']['output']>;
  indiaRegion?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  introText?: Maybe<Scalars['String']['output']>;
  lastUpdatedDate?: Maybe<Scalars['SWNZ_DateTime']['output']>;
  learnerJourneyStage?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  levelOfStudyBroad?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  levelOfStudySpecific?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  linkedFrom?: Maybe<Swnz_BlogPostLinkingCollections>;
  location?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  noFollow?: Maybe<Scalars['Boolean']['output']>;
  noIndex?: Maybe<Scalars['Boolean']['output']>;
  nzRegion?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  providerSubtype?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  providerType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  publishedDate?: Maybe<Scalars['SWNZ_DateTime']['output']>;
  relatedContentCollection?: Maybe<Swnz_BlogPostRelatedContentCollection>;
  seoDescription?: Maybe<Scalars['String']['output']>;
  seoTitle?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  subjectOfStudy?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sys: Swnz_Sys;
  tagBrandPillars?: Maybe<Scalars['String']['output']>;
  tagCampaign?: Maybe<Scalars['String']['output']>;
  tagCustomCampaign?: Maybe<Swnz_BlogCustomCampaignTags>;
  tagLearnerJourneyStage?: Maybe<Scalars['String']['output']>;
  tagTopic?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Scalars['SWNZ_JSON']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  topic?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  userType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostAreaOfStudyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostAuthorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BlogPostAuthorsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_BlogAuthorFilter>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostBodyContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostBrandPillarsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostCampaignArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostCategoryArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_BlogCategoryFilter>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostChinaRegionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostFeaturedBlogPositionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostHeroArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_BlogPostHeroFilter>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostIndiaRegionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostIntroTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostLastUpdatedDateArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostLearnerJourneyStageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostLevelOfStudyBroadArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostLevelOfStudySpecificArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostLocationArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostNoFollowArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostNoIndexArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostNzRegionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostProviderSubtypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostProviderTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostPublishedDateArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostRelatedContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BlogPostRelatedContentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_BlogCardWithLinkFilter>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostSeoDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostSeoTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostSubjectOfStudyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostTagBrandPillarsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostTagCampaignArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostTagCustomCampaignArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_BlogCustomCampaignTagsFilter>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostTagLearnerJourneyStageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostTagTopicArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostTagsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostTopicArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Blog Post [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/blogPost) */
export type Swnz_BlogPostUserTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_BlogPostAuthorsCollection = {
  __typename?: 'SWNZ_BlogPostAuthorsCollection';
  items: Array<Maybe<Swnz_BlogAuthor>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_BlogPostAuthorsCollectionOrder {
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SubTitleAsc = 'subTitle_ASC',
  SubTitleDesc = 'subTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Swnz_BlogPostBodyContent = {
  __typename?: 'SWNZ_BlogPostBodyContent';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_BlogPostBodyContentLinks;
};

export type Swnz_BlogPostBodyContentAssets = {
  __typename?: 'SWNZ_BlogPostBodyContentAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_BlogPostBodyContentEntries = {
  __typename?: 'SWNZ_BlogPostBodyContentEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_BlogPostBodyContentLinks = {
  __typename?: 'SWNZ_BlogPostBodyContentLinks';
  assets: Swnz_BlogPostBodyContentAssets;
  entries: Swnz_BlogPostBodyContentEntries;
  resources: Swnz_BlogPostBodyContentResources;
};

export type Swnz_BlogPostBodyContentResources = {
  __typename?: 'SWNZ_BlogPostBodyContentResources';
  block: Array<Swnz_BlogPostBodyContentResourcesBlock>;
  hyperlink: Array<Swnz_BlogPostBodyContentResourcesHyperlink>;
  inline: Array<Swnz_BlogPostBodyContentResourcesInline>;
};

export type Swnz_BlogPostBodyContentResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_BlogPostBodyContentResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_BlogPostBodyContentResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_BlogPostBodyContentResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_BlogPostBodyContentResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_BlogPostBodyContentResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_BlogPostCollection = {
  __typename?: 'SWNZ_BlogPostCollection';
  items: Array<Maybe<Swnz_BlogPost>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_BlogPostFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_BlogPostFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_BlogPostFilter>>>;
  areaOfStudy_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  areaOfStudy_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  areaOfStudy_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  areaOfStudy_exists?: InputMaybe<Scalars['Boolean']['input']>;
  authors?: InputMaybe<Swnz_CfBlogAuthorNestedFilter>;
  authorsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyContent_contains?: InputMaybe<Scalars['String']['input']>;
  bodyContent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyContent_not_contains?: InputMaybe<Scalars['String']['input']>;
  brandPillars_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  brandPillars_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  brandPillars_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  brandPillars_exists?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  campaign_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  campaign_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  campaign_exists?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<Swnz_CfBlogCategoryNestedFilter>;
  category_exists?: InputMaybe<Scalars['Boolean']['input']>;
  chinaRegion_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chinaRegion_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chinaRegion_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chinaRegion_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  featuredBlogPosition?: InputMaybe<Scalars['Int']['input']>;
  featuredBlogPosition_exists?: InputMaybe<Scalars['Boolean']['input']>;
  featuredBlogPosition_gt?: InputMaybe<Scalars['Int']['input']>;
  featuredBlogPosition_gte?: InputMaybe<Scalars['Int']['input']>;
  featuredBlogPosition_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  featuredBlogPosition_lt?: InputMaybe<Scalars['Int']['input']>;
  featuredBlogPosition_lte?: InputMaybe<Scalars['Int']['input']>;
  featuredBlogPosition_not?: InputMaybe<Scalars['Int']['input']>;
  featuredBlogPosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  hero?: InputMaybe<Swnz_CfheroMultiTypeNestedFilter>;
  hero_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  indiaRegion_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  indiaRegion_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  indiaRegion_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  indiaRegion_exists?: InputMaybe<Scalars['Boolean']['input']>;
  introText?: InputMaybe<Scalars['String']['input']>;
  introText_contains?: InputMaybe<Scalars['String']['input']>;
  introText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  introText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introText_not?: InputMaybe<Scalars['String']['input']>;
  introText_not_contains?: InputMaybe<Scalars['String']['input']>;
  introText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lastUpdatedDate?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  lastUpdatedDate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  lastUpdatedDate_gt?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  lastUpdatedDate_gte?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  lastUpdatedDate_in?: InputMaybe<Array<InputMaybe<Scalars['SWNZ_DateTime']['input']>>>;
  lastUpdatedDate_lt?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  lastUpdatedDate_lte?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  lastUpdatedDate_not?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  lastUpdatedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['SWNZ_DateTime']['input']>>>;
  learnerJourneyStage_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  learnerJourneyStage_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  learnerJourneyStage_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  learnerJourneyStage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  levelOfStudyBroad_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudyBroad_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudyBroad_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudyBroad_exists?: InputMaybe<Scalars['Boolean']['input']>;
  levelOfStudySpecific_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudySpecific_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudySpecific_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudySpecific_exists?: InputMaybe<Scalars['Boolean']['input']>;
  location_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_exists?: InputMaybe<Scalars['Boolean']['input']>;
  no_follow?: InputMaybe<Scalars['Boolean']['input']>;
  no_follow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  no_follow_not?: InputMaybe<Scalars['Boolean']['input']>;
  no_index?: InputMaybe<Scalars['Boolean']['input']>;
  no_index_exists?: InputMaybe<Scalars['Boolean']['input']>;
  no_index_not?: InputMaybe<Scalars['Boolean']['input']>;
  nzRegion_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nzRegion_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nzRegion_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nzRegion_exists?: InputMaybe<Scalars['Boolean']['input']>;
  providerSubtype_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerSubtype_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerSubtype_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerSubtype_exists?: InputMaybe<Scalars['Boolean']['input']>;
  providerType_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerType_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerType_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publishedDate?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  publishedDate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publishedDate_gt?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  publishedDate_gte?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  publishedDate_in?: InputMaybe<Array<InputMaybe<Scalars['SWNZ_DateTime']['input']>>>;
  publishedDate_lt?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  publishedDate_lte?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  publishedDate_not?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  publishedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['SWNZ_DateTime']['input']>>>;
  relatedContent?: InputMaybe<Swnz_CfBlogCardWithLinkNestedFilter>;
  relatedContentCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  seoDescription?: InputMaybe<Scalars['String']['input']>;
  seoDescription_contains?: InputMaybe<Scalars['String']['input']>;
  seoDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  seoDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  seoDescription_not?: InputMaybe<Scalars['String']['input']>;
  seoDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  seoDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  seoTitle?: InputMaybe<Scalars['String']['input']>;
  seoTitle_contains?: InputMaybe<Scalars['String']['input']>;
  seoTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  seoTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  seoTitle_not?: InputMaybe<Scalars['String']['input']>;
  seoTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  seoTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subjectOfStudy_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subjectOfStudy_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subjectOfStudy_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subjectOfStudy_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  tagBrandPillars?: InputMaybe<Scalars['String']['input']>;
  tagBrandPillars_contains?: InputMaybe<Scalars['String']['input']>;
  tagBrandPillars_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tagBrandPillars_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tagBrandPillars_not?: InputMaybe<Scalars['String']['input']>;
  tagBrandPillars_not_contains?: InputMaybe<Scalars['String']['input']>;
  tagBrandPillars_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tagCampaign?: InputMaybe<Scalars['String']['input']>;
  tagCampaign_contains?: InputMaybe<Scalars['String']['input']>;
  tagCampaign_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tagCampaign_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tagCampaign_not?: InputMaybe<Scalars['String']['input']>;
  tagCampaign_not_contains?: InputMaybe<Scalars['String']['input']>;
  tagCampaign_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tagCustomCampaign?: InputMaybe<Swnz_CfBlogCustomCampaignTagsNestedFilter>;
  tagCustomCampaign_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tagLearnerJourneyStage?: InputMaybe<Scalars['String']['input']>;
  tagLearnerJourneyStage_contains?: InputMaybe<Scalars['String']['input']>;
  tagLearnerJourneyStage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tagLearnerJourneyStage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tagLearnerJourneyStage_not?: InputMaybe<Scalars['String']['input']>;
  tagLearnerJourneyStage_not_contains?: InputMaybe<Scalars['String']['input']>;
  tagLearnerJourneyStage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tagTopic?: InputMaybe<Scalars['String']['input']>;
  tagTopic_contains?: InputMaybe<Scalars['String']['input']>;
  tagTopic_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tagTopic_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tagTopic_not?: InputMaybe<Scalars['String']['input']>;
  tagTopic_not_contains?: InputMaybe<Scalars['String']['input']>;
  tagTopic_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topic_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topic_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topic_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topic_exists?: InputMaybe<Scalars['Boolean']['input']>;
  userType_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userType_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userType_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userType_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Swnz_BlogPostHero = Swnz_MediaImage | Swnz_MediaVideo;

export type Swnz_BlogPostHeroFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_BlogPostHeroFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_BlogPostHeroFilter>>>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_contains?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_not?: InputMaybe<Scalars['String']['input']>;
  caption_not_contains?: InputMaybe<Scalars['String']['input']>;
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_BlogPostLinkingCollections = {
  __typename?: 'SWNZ_BlogPostLinkingCollections';
  blogCallToActionCollection?: Maybe<Swnz_BlogCallToActionCollection>;
  blogCardWithLinkCollection?: Maybe<Swnz_BlogCardWithLinkCollection>;
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_BlogPostLinkingCollectionsBlogCallToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BlogPostLinkingCollectionsBlogCallToActionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_BlogPostLinkingCollectionsBlogCardWithLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BlogPostLinkingCollectionsBlogCardWithLinkCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_BlogPostLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_BlogPostLinkingCollectionsBlogCallToActionCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  OpenInNewWindowAsc = 'openInNewWindow_ASC',
  OpenInNewWindowDesc = 'openInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlLinkAsc = 'urlLink_ASC',
  UrlLinkDesc = 'urlLink_DESC'
}

export enum Swnz_BlogPostLinkingCollectionsBlogCardWithLinkCollectionOrder {
  CustomUrlAsc = 'customUrl_ASC',
  CustomUrlDesc = 'customUrl_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_BlogPostOrder {
  FeaturedBlogPositionAsc = 'featuredBlogPosition_ASC',
  FeaturedBlogPositionDesc = 'featuredBlogPosition_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LastUpdatedDateAsc = 'lastUpdatedDate_ASC',
  LastUpdatedDateDesc = 'lastUpdatedDate_DESC',
  NoFollowAsc = 'no_follow_ASC',
  NoFollowDesc = 'no_follow_DESC',
  NoIndexAsc = 'no_index_ASC',
  NoIndexDesc = 'no_index_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SeoTitleAsc = 'seoTitle_ASC',
  SeoTitleDesc = 'seoTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagBrandPillarsAsc = 'tagBrandPillars_ASC',
  TagBrandPillarsDesc = 'tagBrandPillars_DESC',
  TagCampaignAsc = 'tagCampaign_ASC',
  TagCampaignDesc = 'tagCampaign_DESC',
  TagLearnerJourneyStageAsc = 'tagLearnerJourneyStage_ASC',
  TagLearnerJourneyStageDesc = 'tagLearnerJourneyStage_DESC',
  TagTopicAsc = 'tagTopic_ASC',
  TagTopicDesc = 'tagTopic_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Swnz_BlogPostRelatedContentCollection = {
  __typename?: 'SWNZ_BlogPostRelatedContentCollection';
  items: Array<Maybe<Swnz_BlogCardWithLink>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_BlogPostRelatedContentCollectionOrder {
  CustomUrlAsc = 'customUrl_ASC',
  CustomUrlDesc = 'customUrl_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** A bullet point [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/bulletPoint) */
export type Swnz_BulletPoint = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_BulletPoint';
  _id: Scalars['ID']['output'];
  bodyText?: Maybe<Swnz_BulletPointBodyText>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  icon?: Maybe<Swnz_MediaIcon>;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_BulletPointLinkingCollections>;
  sys: Swnz_Sys;
};


/** A bullet point [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/bulletPoint) */
export type Swnz_BulletPointBodyTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A bullet point [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/bulletPoint) */
export type Swnz_BulletPointIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_MediaIconFilter>;
};


/** A bullet point [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/bulletPoint) */
export type Swnz_BulletPointIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A bullet point [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/bulletPoint) */
export type Swnz_BulletPointLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_BulletPointBodyText = {
  __typename?: 'SWNZ_BulletPointBodyText';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_BulletPointBodyTextLinks;
};

export type Swnz_BulletPointBodyTextAssets = {
  __typename?: 'SWNZ_BulletPointBodyTextAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_BulletPointBodyTextEntries = {
  __typename?: 'SWNZ_BulletPointBodyTextEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_BulletPointBodyTextLinks = {
  __typename?: 'SWNZ_BulletPointBodyTextLinks';
  assets: Swnz_BulletPointBodyTextAssets;
  entries: Swnz_BulletPointBodyTextEntries;
  resources: Swnz_BulletPointBodyTextResources;
};

export type Swnz_BulletPointBodyTextResources = {
  __typename?: 'SWNZ_BulletPointBodyTextResources';
  block: Array<Swnz_BulletPointBodyTextResourcesBlock>;
  hyperlink: Array<Swnz_BulletPointBodyTextResourcesHyperlink>;
  inline: Array<Swnz_BulletPointBodyTextResourcesInline>;
};

export type Swnz_BulletPointBodyTextResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_BulletPointBodyTextResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_BulletPointBodyTextResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_BulletPointBodyTextResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_BulletPointBodyTextResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_BulletPointBodyTextResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_BulletPointCollection = {
  __typename?: 'SWNZ_BulletPointCollection';
  items: Array<Maybe<Swnz_BulletPoint>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** Container to display bullet points [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/bulletPointContainer) */
export type Swnz_BulletPointContainer = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_BulletPointContainer';
  _id: Scalars['ID']['output'];
  bulletPointsCollection?: Maybe<Swnz_BulletPointContainerBulletPointsCollection>;
  codeId?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  introText?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_BulletPointContainerLinkingCollections>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** Container to display bullet points [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/bulletPointContainer) */
export type Swnz_BulletPointContainerBulletPointsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BulletPointContainerBulletPointsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_BulletPointFilter>;
};


/** Container to display bullet points [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/bulletPointContainer) */
export type Swnz_BulletPointContainerCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display bullet points [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/bulletPointContainer) */
export type Swnz_BulletPointContainerIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display bullet points [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/bulletPointContainer) */
export type Swnz_BulletPointContainerIntroTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display bullet points [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/bulletPointContainer) */
export type Swnz_BulletPointContainerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Container to display bullet points [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/bulletPointContainer) */
export type Swnz_BulletPointContainerTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_BulletPointContainerBulletPointsCollection = {
  __typename?: 'SWNZ_BulletPointContainerBulletPointsCollection';
  items: Array<Maybe<Swnz_BulletPoint>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_BulletPointContainerBulletPointsCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Swnz_BulletPointContainerCollection = {
  __typename?: 'SWNZ_BulletPointContainerCollection';
  items: Array<Maybe<Swnz_BulletPointContainer>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_BulletPointContainerFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_BulletPointContainerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_BulletPointContainerFilter>>>;
  bulletPoints?: InputMaybe<Swnz_CfBulletPointNestedFilter>;
  bulletPointsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introText?: InputMaybe<Scalars['String']['input']>;
  introText_contains?: InputMaybe<Scalars['String']['input']>;
  introText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  introText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introText_not?: InputMaybe<Scalars['String']['input']>;
  introText_not_contains?: InputMaybe<Scalars['String']['input']>;
  introText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_BulletPointContainerLinkingCollections = {
  __typename?: 'SWNZ_BulletPointContainerLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageModalCollection?: Maybe<Swnz_PageModalCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_BulletPointContainerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_BulletPointContainerLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BulletPointContainerLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_BulletPointContainerLinkingCollectionsPageModalCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BulletPointContainerLinkingCollectionsPageModalCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_BulletPointContainerLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BulletPointContainerLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_BulletPointContainerLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_BulletPointContainerLinkingCollectionsPageModalCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_BulletPointContainerLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_BulletPointContainerOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Swnz_BulletPointFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_BulletPointFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_BulletPointFilter>>>;
  bodyText_contains?: InputMaybe<Scalars['String']['input']>;
  bodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  icon?: InputMaybe<Swnz_CfMediaIconNestedFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_BulletPointLinkingCollections = {
  __typename?: 'SWNZ_BulletPointLinkingCollections';
  bulletPointContainerCollection?: Maybe<Swnz_BulletPointContainerCollection>;
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_BulletPointLinkingCollectionsBulletPointContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_BulletPointLinkingCollectionsBulletPointContainerCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_BulletPointLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_BulletPointLinkingCollectionsBulletPointContainerCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_BulletPointOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** CTA buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/callToAction) */
export type Swnz_CallToAction = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_CallToAction';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  internalLink?: Maybe<Swnz_CallToActionInternalLink>;
  label?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_CallToActionLinkingCollections>;
  modalId?: Maybe<Scalars['String']['output']>;
  openInNewWindow?: Maybe<Scalars['Boolean']['output']>;
  sys: Swnz_Sys;
  urlLink?: Maybe<Scalars['String']['output']>;
};


/** CTA buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/callToAction) */
export type Swnz_CallToActionIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** CTA buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/callToAction) */
export type Swnz_CallToActionInternalLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** CTA buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/callToAction) */
export type Swnz_CallToActionLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** CTA buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/callToAction) */
export type Swnz_CallToActionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** CTA buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/callToAction) */
export type Swnz_CallToActionModalIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** CTA buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/callToAction) */
export type Swnz_CallToActionOpenInNewWindowArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** CTA buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/callToAction) */
export type Swnz_CallToActionUrlLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_CallToActionCollection = {
  __typename?: 'SWNZ_CallToActionCollection';
  items: Array<Maybe<Swnz_CallToAction>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_CallToActionFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CallToActionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CallToActionFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  internalLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  modalId?: InputMaybe<Scalars['String']['input']>;
  modalId_contains?: InputMaybe<Scalars['String']['input']>;
  modalId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  modalId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  modalId_not?: InputMaybe<Scalars['String']['input']>;
  modalId_not_contains?: InputMaybe<Scalars['String']['input']>;
  modalId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  openInNewWindow?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewWindow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewWindow_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  urlLink?: InputMaybe<Scalars['String']['input']>;
  urlLink_contains?: InputMaybe<Scalars['String']['input']>;
  urlLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  urlLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  urlLink_not?: InputMaybe<Scalars['String']['input']>;
  urlLink_not_contains?: InputMaybe<Scalars['String']['input']>;
  urlLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CallToActionInternalLink = Swnz_NaumaiPageStandard | Swnz_Page;

export type Swnz_CallToActionLinkingCollections = {
  __typename?: 'SWNZ_CallToActionLinkingCollections';
  alternatingContentCollection?: Maybe<Swnz_AlternatingContentCollection>;
  bannerCollection?: Maybe<Swnz_BannerCollection>;
  emotionItemResponseCollection?: Maybe<Swnz_EmotionItemResponseCollection>;
  entryCollection?: Maybe<Swnz_EntryCollection>;
  genericTextCollection?: Maybe<Swnz_GenericTextCollection>;
  globalSettingsCollection?: Maybe<Swnz_GlobalSettingsCollection>;
  heroCollection?: Maybe<Swnz_HeroCollection>;
  heroFeaturedCollection?: Maybe<Swnz_HeroFeaturedCollection>;
  heroTabCollection?: Maybe<Swnz_HeroTabCollection>;
  homepageCardCollection?: Maybe<Swnz_HomepageCardCollection>;
  homepagePanelCollection?: Maybe<Swnz_HomepagePanelCollection>;
  impactTextCollection?: Maybe<Swnz_ImpactTextCollection>;
  introTextWithJumpMenuCollection?: Maybe<Swnz_IntroTextWithJumpMenuCollection>;
  keepExploringCollection?: Maybe<Swnz_KeepExploringCollection>;
  nextStepsItemCollection?: Maybe<Swnz_NextStepsItemCollection>;
  promoCollection?: Maybe<Swnz_PromoCollection>;
  tileCollection?: Maybe<Swnz_TileCollection>;
  valueCollection?: Maybe<Swnz_ValueCollection>;
};


export type Swnz_CallToActionLinkingCollectionsAlternatingContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CallToActionLinkingCollectionsAlternatingContentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CallToActionLinkingCollectionsBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CallToActionLinkingCollectionsBannerCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CallToActionLinkingCollectionsEmotionItemResponseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CallToActionLinkingCollectionsEmotionItemResponseCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CallToActionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CallToActionLinkingCollectionsGenericTextCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CallToActionLinkingCollectionsGenericTextCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CallToActionLinkingCollectionsGlobalSettingsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CallToActionLinkingCollectionsGlobalSettingsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CallToActionLinkingCollectionsHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CallToActionLinkingCollectionsHeroCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CallToActionLinkingCollectionsHeroFeaturedCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CallToActionLinkingCollectionsHeroFeaturedCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CallToActionLinkingCollectionsHeroTabCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CallToActionLinkingCollectionsHeroTabCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CallToActionLinkingCollectionsHomepageCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CallToActionLinkingCollectionsHomepageCardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CallToActionLinkingCollectionsHomepagePanelCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CallToActionLinkingCollectionsHomepagePanelCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CallToActionLinkingCollectionsImpactTextCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CallToActionLinkingCollectionsImpactTextCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CallToActionLinkingCollectionsIntroTextWithJumpMenuCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CallToActionLinkingCollectionsIntroTextWithJumpMenuCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CallToActionLinkingCollectionsKeepExploringCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CallToActionLinkingCollectionsKeepExploringCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CallToActionLinkingCollectionsNextStepsItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CallToActionLinkingCollectionsNextStepsItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CallToActionLinkingCollectionsPromoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CallToActionLinkingCollectionsPromoCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CallToActionLinkingCollectionsTileCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CallToActionLinkingCollectionsTileCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CallToActionLinkingCollectionsValueCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CallToActionLinkingCollectionsValueCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_CallToActionLinkingCollectionsAlternatingContentCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  MediaOrientationAsc = 'mediaOrientation_ASC',
  MediaOrientationDesc = 'mediaOrientation_DESC',
  MediaPlacementAsc = 'mediaPlacement_ASC',
  MediaPlacementDesc = 'mediaPlacement_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_CallToActionLinkingCollectionsBannerCollectionOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_CallToActionLinkingCollectionsEmotionItemResponseCollectionOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_CallToActionLinkingCollectionsGenericTextCollectionOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  ContentAlignmentAsc = 'contentAlignment_ASC',
  ContentAlignmentDesc = 'contentAlignment_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeBackgroundPositionAsc = 'themeBackgroundPosition_ASC',
  ThemeBackgroundPositionDesc = 'themeBackgroundPosition_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_CallToActionLinkingCollectionsGlobalSettingsCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_CallToActionLinkingCollectionsHeroCollectionOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  DisplayTypeAsc = 'displayType_ASC',
  DisplayTypeDesc = 'displayType_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_CallToActionLinkingCollectionsHeroFeaturedCollectionOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SubHeadingAsc = 'subHeading_ASC',
  SubHeadingDesc = 'subHeading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextThemeColourAsc = 'textThemeColour_ASC',
  TextThemeColourDesc = 'textThemeColour_DESC'
}

export enum Swnz_CallToActionLinkingCollectionsHeroTabCollectionOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  QuoteAuthorAsc = 'quoteAuthor_ASC',
  QuoteAuthorDesc = 'quoteAuthor_DESC',
  QuoteStudyLocationAsc = 'quoteStudyLocation_ASC',
  QuoteStudyLocationDesc = 'quoteStudyLocation_DESC',
  QuoteTextAsc = 'quoteText_ASC',
  QuoteTextDesc = 'quoteText_DESC',
  StoryTitleAsc = 'storyTitle_ASC',
  StoryTitleDesc = 'storyTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TabHeadingAsc = 'tabHeading_ASC',
  TabHeadingDesc = 'tabHeading_DESC',
  TabSubHeadingAsc = 'tabSubHeading_ASC',
  TabSubHeadingDesc = 'tabSubHeading_DESC'
}

export enum Swnz_CallToActionLinkingCollectionsHomepageCardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  IsNewAsc = 'isNew_ASC',
  IsNewDesc = 'isNew_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_CallToActionLinkingCollectionsHomepagePanelCollectionOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  PreHeadingAsc = 'preHeading_ASC',
  PreHeadingDesc = 'preHeading_DESC',
  SubHeadingAsc = 'subHeading_ASC',
  SubHeadingDesc = 'subHeading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_CallToActionLinkingCollectionsImpactTextCollectionOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DisplayAsQuoteAsc = 'displayAsQuote_ASC',
  DisplayAsQuoteDesc = 'displayAsQuote_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignmentAsc = 'textAlignment_ASC',
  TextAlignmentDesc = 'textAlignment_DESC',
  TextColourAsc = 'textColour_ASC',
  TextColourDesc = 'textColour_DESC'
}

export enum Swnz_CallToActionLinkingCollectionsIntroTextWithJumpMenuCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_CallToActionLinkingCollectionsKeepExploringCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_CallToActionLinkingCollectionsNextStepsItemCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_CallToActionLinkingCollectionsPromoCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  ShowFilterCtaAsc = 'showFilterCta_ASC',
  ShowFilterCtaDesc = 'showFilterCta_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_CallToActionLinkingCollectionsTileCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_CallToActionLinkingCollectionsValueCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  DrawerPronunciationAsc = 'drawerPronunciation_ASC',
  DrawerPronunciationDesc = 'drawerPronunciation_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_CallToActionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  ModalIdAsc = 'modalId_ASC',
  ModalIdDesc = 'modalId_DESC',
  OpenInNewWindowAsc = 'openInNewWindow_ASC',
  OpenInNewWindowDesc = 'openInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlLinkAsc = 'urlLink_ASC',
  UrlLinkDesc = 'urlLink_DESC'
}

/** Container to display cards with links or modals [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardContainer) */
export type Swnz_CardContainer = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_CardContainer';
  _id: Scalars['ID']['output'];
  applyCardBackgroundColour?: Maybe<Scalars['Boolean']['output']>;
  backgroundColour?: Maybe<Scalars['String']['output']>;
  cardsWithLinkCollection?: Maybe<Swnz_CardContainerCardsWithLinkCollection>;
  cardsWithModalCollection?: Maybe<Swnz_CardContainerCardsWithModalCollection>;
  codeId?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  display?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  introText?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_CardContainerLinkingCollections>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** Container to display cards with links or modals [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardContainer) */
export type Swnz_CardContainerApplyCardBackgroundColourArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display cards with links or modals [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardContainer) */
export type Swnz_CardContainerBackgroundColourArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display cards with links or modals [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardContainer) */
export type Swnz_CardContainerCardsWithLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CardContainerCardsWithLinkCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_CardWithLinkFilter>;
};


/** Container to display cards with links or modals [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardContainer) */
export type Swnz_CardContainerCardsWithModalCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CardContainerCardsWithModalCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_CardWithModalFilter>;
};


/** Container to display cards with links or modals [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardContainer) */
export type Swnz_CardContainerCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display cards with links or modals [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardContainer) */
export type Swnz_CardContainerDisplayArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display cards with links or modals [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardContainer) */
export type Swnz_CardContainerIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display cards with links or modals [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardContainer) */
export type Swnz_CardContainerIntroTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display cards with links or modals [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardContainer) */
export type Swnz_CardContainerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Container to display cards with links or modals [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardContainer) */
export type Swnz_CardContainerTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_CardContainerCardsWithLinkCollection = {
  __typename?: 'SWNZ_CardContainerCardsWithLinkCollection';
  items: Array<Maybe<Swnz_CardWithLink>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_CardContainerCardsWithLinkCollectionOrder {
  CustomUrlAsc = 'customUrl_ASC',
  CustomUrlDesc = 'customUrl_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Swnz_CardContainerCardsWithModalCollection = {
  __typename?: 'SWNZ_CardContainerCardsWithModalCollection';
  items: Array<Maybe<Swnz_CardWithModal>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_CardContainerCardsWithModalCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Swnz_CardContainerCollection = {
  __typename?: 'SWNZ_CardContainerCollection';
  items: Array<Maybe<Swnz_CardContainer>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_CardContainerFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CardContainerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CardContainerFilter>>>;
  applyCardBackgroundColour?: InputMaybe<Scalars['Boolean']['input']>;
  applyCardBackgroundColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  applyCardBackgroundColour_not?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColour?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColour_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  backgroundColour_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cardsWithLink?: InputMaybe<Swnz_CfCardWithLinkNestedFilter>;
  cardsWithLinkCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cardsWithModal?: InputMaybe<Swnz_CfCardWithModalNestedFilter>;
  cardsWithModalCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  display?: InputMaybe<Scalars['String']['input']>;
  display_contains?: InputMaybe<Scalars['String']['input']>;
  display_exists?: InputMaybe<Scalars['Boolean']['input']>;
  display_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  display_not?: InputMaybe<Scalars['String']['input']>;
  display_not_contains?: InputMaybe<Scalars['String']['input']>;
  display_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introText?: InputMaybe<Scalars['String']['input']>;
  introText_contains?: InputMaybe<Scalars['String']['input']>;
  introText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  introText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introText_not?: InputMaybe<Scalars['String']['input']>;
  introText_not_contains?: InputMaybe<Scalars['String']['input']>;
  introText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CardContainerLinkingCollections = {
  __typename?: 'SWNZ_CardContainerLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageHomepageCollection?: Maybe<Swnz_PageHomepageCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_CardContainerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CardContainerLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CardContainerLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CardContainerLinkingCollectionsPageHomepageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CardContainerLinkingCollectionsPageHomepageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CardContainerLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CardContainerLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_CardContainerLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_CardContainerLinkingCollectionsPageHomepageCollectionOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  HeroHeadingAsc = 'heroHeading_ASC',
  HeroHeadingDesc = 'heroHeading_DESC',
  HeroJumpTextAsc = 'heroJumpText_ASC',
  HeroJumpTextDesc = 'heroJumpText_DESC',
  HeroPreHeadingAsc = 'heroPreHeading_ASC',
  HeroPreHeadingDesc = 'heroPreHeading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_CardContainerLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_CardContainerOrder {
  ApplyCardBackgroundColourAsc = 'applyCardBackgroundColour_ASC',
  ApplyCardBackgroundColourDesc = 'applyCardBackgroundColour_DESC',
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Card with a URL or Page link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardWithLink) */
export type Swnz_CardWithLink = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_CardWithLink';
  _id: Scalars['ID']['output'];
  assetLink?: Maybe<Swnz_Asset>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  customUrl?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Swnz_MediaImage>;
  link?: Maybe<Swnz_CardWithLinkLink>;
  linkedFrom?: Maybe<Swnz_CardWithLinkLinkingCollections>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** Card with a URL or Page link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardWithLink) */
export type Swnz_CardWithLinkAssetLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Card with a URL or Page link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardWithLink) */
export type Swnz_CardWithLinkCustomUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Card with a URL or Page link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardWithLink) */
export type Swnz_CardWithLinkDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Card with a URL or Page link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardWithLink) */
export type Swnz_CardWithLinkIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Card with a URL or Page link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardWithLink) */
export type Swnz_CardWithLinkImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_MediaImageFilter>;
};


/** Card with a URL or Page link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardWithLink) */
export type Swnz_CardWithLinkLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Card with a URL or Page link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardWithLink) */
export type Swnz_CardWithLinkLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Card with a URL or Page link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardWithLink) */
export type Swnz_CardWithLinkTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_CardWithLinkCollection = {
  __typename?: 'SWNZ_CardWithLinkCollection';
  items: Array<Maybe<Swnz_CardWithLink>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_CardWithLinkFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CardWithLinkFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CardWithLinkFilter>>>;
  assetLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  customUrl?: InputMaybe<Scalars['String']['input']>;
  customUrl_contains?: InputMaybe<Scalars['String']['input']>;
  customUrl_exists?: InputMaybe<Scalars['Boolean']['input']>;
  customUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  customUrl_not?: InputMaybe<Scalars['String']['input']>;
  customUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  customUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<Swnz_CfMediaImageNestedFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CardWithLinkLink = Swnz_NaumaiPageStandard | Swnz_Page;

export type Swnz_CardWithLinkLinkingCollections = {
  __typename?: 'SWNZ_CardWithLinkLinkingCollections';
  cardContainerCollection?: Maybe<Swnz_CardContainerCollection>;
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_CardWithLinkLinkingCollectionsCardContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CardWithLinkLinkingCollectionsCardContainerCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CardWithLinkLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_CardWithLinkLinkingCollectionsCardContainerCollectionOrder {
  ApplyCardBackgroundColourAsc = 'applyCardBackgroundColour_ASC',
  ApplyCardBackgroundColourDesc = 'applyCardBackgroundColour_DESC',
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_CardWithLinkOrder {
  CustomUrlAsc = 'customUrl_ASC',
  CustomUrlDesc = 'customUrl_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Card that opens a modal page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardWithModal) */
export type Swnz_CardWithModal = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_CardWithModal';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  description?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Swnz_MediaImage>;
  linkedFrom?: Maybe<Swnz_CardWithModalLinkingCollections>;
  modalContent?: Maybe<Swnz_PageModal>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** Card that opens a modal page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardWithModal) */
export type Swnz_CardWithModalDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Card that opens a modal page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardWithModal) */
export type Swnz_CardWithModalIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Card that opens a modal page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardWithModal) */
export type Swnz_CardWithModalImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_MediaImageFilter>;
};


/** Card that opens a modal page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardWithModal) */
export type Swnz_CardWithModalLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Card that opens a modal page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardWithModal) */
export type Swnz_CardWithModalModalContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_PageModalFilter>;
};


/** Card that opens a modal page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/cardWithModal) */
export type Swnz_CardWithModalTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_CardWithModalCollection = {
  __typename?: 'SWNZ_CardWithModalCollection';
  items: Array<Maybe<Swnz_CardWithModal>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_CardWithModalFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CardWithModalFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CardWithModalFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<Swnz_CfMediaImageNestedFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  modalContent?: InputMaybe<Swnz_CfPageModalNestedFilter>;
  modalContent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CardWithModalLinkingCollections = {
  __typename?: 'SWNZ_CardWithModalLinkingCollections';
  cardContainerCollection?: Maybe<Swnz_CardContainerCollection>;
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_CardWithModalLinkingCollectionsCardContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_CardWithModalLinkingCollectionsCardContainerCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_CardWithModalLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_CardWithModalLinkingCollectionsCardContainerCollectionOrder {
  ApplyCardBackgroundColourAsc = 'applyCardBackgroundColour_ASC',
  ApplyCardBackgroundColourDesc = 'applyCardBackgroundColour_DESC',
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_CardWithModalOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Holds static components such as mailing list form [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/componentStatic) */
export type Swnz_ComponentStatic = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_ComponentStatic';
  _id: Scalars['ID']['output'];
  codeId?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_ComponentStaticLinkingCollections>;
  sys: Swnz_Sys;
};


/** Holds static components such as mailing list form [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/componentStatic) */
export type Swnz_ComponentStaticCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Holds static components such as mailing list form [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/componentStatic) */
export type Swnz_ComponentStaticIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Holds static components such as mailing list form [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/componentStatic) */
export type Swnz_ComponentStaticLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_ComponentStaticCollection = {
  __typename?: 'SWNZ_ComponentStaticCollection';
  items: Array<Maybe<Swnz_ComponentStatic>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_ComponentStaticFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_ComponentStaticFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_ComponentStaticFilter>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_ComponentStaticLinkingCollections = {
  __typename?: 'SWNZ_ComponentStaticLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_ComponentStaticLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_ComponentStaticLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_ComponentStaticLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_ComponentStaticLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_ComponentStaticLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_ComponentStaticLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_ComponentStaticLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_ComponentStaticOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Swnz_ContentfulMetadata = {
  __typename?: 'SWNZ_ContentfulMetadata';
  tags: Array<Maybe<Swnz_ContentfulTag>>;
};

export type Swnz_ContentfulMetadataFilter = {
  tags?: InputMaybe<Swnz_ContentfulMetadataTagsFilter>;
  tags_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Swnz_ContentfulMetadataTagsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *       Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type Swnz_ContentfulTag = {
  __typename?: 'SWNZ_ContentfulTag';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Container to display Emotion Items [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/emotionContainer) */
export type Swnz_EmotionContainer = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_EmotionContainer';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  emotionItemsCollection?: Maybe<Swnz_EmotionContainerEmotionItemsCollection>;
  heading?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  introText?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_EmotionContainerLinkingCollections>;
  sys: Swnz_Sys;
};


/** Container to display Emotion Items [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/emotionContainer) */
export type Swnz_EmotionContainerEmotionItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_EmotionContainerEmotionItemsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_EmotionItemFilter>;
};


/** Container to display Emotion Items [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/emotionContainer) */
export type Swnz_EmotionContainerHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display Emotion Items [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/emotionContainer) */
export type Swnz_EmotionContainerIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display Emotion Items [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/emotionContainer) */
export type Swnz_EmotionContainerIntroTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display Emotion Items [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/emotionContainer) */
export type Swnz_EmotionContainerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_EmotionContainerCollection = {
  __typename?: 'SWNZ_EmotionContainerCollection';
  items: Array<Maybe<Swnz_EmotionContainer>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_EmotionContainerEmotionItemsCollection = {
  __typename?: 'SWNZ_EmotionContainerEmotionItemsCollection';
  items: Array<Maybe<Swnz_EmotionItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_EmotionContainerEmotionItemsCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Swnz_EmotionContainerFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_EmotionContainerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_EmotionContainerFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  emotionItems?: InputMaybe<Swnz_CfEmotionItemNestedFilter>;
  emotionItemsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Scalars['String']['input']>;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading_not?: InputMaybe<Scalars['String']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introText?: InputMaybe<Scalars['String']['input']>;
  introText_contains?: InputMaybe<Scalars['String']['input']>;
  introText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  introText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introText_not?: InputMaybe<Scalars['String']['input']>;
  introText_not_contains?: InputMaybe<Scalars['String']['input']>;
  introText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_EmotionContainerLinkingCollections = {
  __typename?: 'SWNZ_EmotionContainerLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
};


export type Swnz_EmotionContainerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_EmotionContainerLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_EmotionContainerLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_EmotionContainerLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_EmotionContainerOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/emotionItem) */
export type Swnz_EmotionItem = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_EmotionItem';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  icon?: Maybe<Swnz_MediaIcon>;
  identifier?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_EmotionItemLinkingCollections>;
  responsesCollection?: Maybe<Swnz_EmotionItemResponsesCollection>;
  sys: Swnz_Sys;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/emotionItem) */
export type Swnz_EmotionItemIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_MediaIconFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/emotionItem) */
export type Swnz_EmotionItemIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/emotionItem) */
export type Swnz_EmotionItemLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/emotionItem) */
export type Swnz_EmotionItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/emotionItem) */
export type Swnz_EmotionItemResponsesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_EmotionItemResponsesCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_EmotionItemResponseFilter>;
};

export type Swnz_EmotionItemCollection = {
  __typename?: 'SWNZ_EmotionItemCollection';
  items: Array<Maybe<Swnz_EmotionItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_EmotionItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_EmotionItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_EmotionItemFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  icon?: InputMaybe<Swnz_CfMediaIconNestedFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  responses?: InputMaybe<Swnz_CfEmotionItemResponseNestedFilter>;
  responsesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_EmotionItemLinkingCollections = {
  __typename?: 'SWNZ_EmotionItemLinkingCollections';
  emotionContainerCollection?: Maybe<Swnz_EmotionContainerCollection>;
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_EmotionItemLinkingCollectionsEmotionContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_EmotionItemLinkingCollectionsEmotionContainerCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_EmotionItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_EmotionItemLinkingCollectionsEmotionContainerCollectionOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_EmotionItemOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** The response shown on selection of a single Emotion Item [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/emotionItemResponse) */
export type Swnz_EmotionItemResponse = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_EmotionItemResponse';
  _id: Scalars['ID']['output'];
  bodyText?: Maybe<Swnz_EmotionItemResponseBodyText>;
  callToAction?: Maybe<Swnz_CallToAction>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  heading?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_EmotionItemResponseLinkingCollections>;
  sys: Swnz_Sys;
};


/** The response shown on selection of a single Emotion Item [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/emotionItemResponse) */
export type Swnz_EmotionItemResponseBodyTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The response shown on selection of a single Emotion Item [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/emotionItemResponse) */
export type Swnz_EmotionItemResponseCallToActionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** The response shown on selection of a single Emotion Item [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/emotionItemResponse) */
export type Swnz_EmotionItemResponseHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The response shown on selection of a single Emotion Item [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/emotionItemResponse) */
export type Swnz_EmotionItemResponseIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The response shown on selection of a single Emotion Item [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/emotionItemResponse) */
export type Swnz_EmotionItemResponseLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_EmotionItemResponseBodyText = {
  __typename?: 'SWNZ_EmotionItemResponseBodyText';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_EmotionItemResponseBodyTextLinks;
};

export type Swnz_EmotionItemResponseBodyTextAssets = {
  __typename?: 'SWNZ_EmotionItemResponseBodyTextAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_EmotionItemResponseBodyTextEntries = {
  __typename?: 'SWNZ_EmotionItemResponseBodyTextEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_EmotionItemResponseBodyTextLinks = {
  __typename?: 'SWNZ_EmotionItemResponseBodyTextLinks';
  assets: Swnz_EmotionItemResponseBodyTextAssets;
  entries: Swnz_EmotionItemResponseBodyTextEntries;
  resources: Swnz_EmotionItemResponseBodyTextResources;
};

export type Swnz_EmotionItemResponseBodyTextResources = {
  __typename?: 'SWNZ_EmotionItemResponseBodyTextResources';
  block: Array<Swnz_EmotionItemResponseBodyTextResourcesBlock>;
  hyperlink: Array<Swnz_EmotionItemResponseBodyTextResourcesHyperlink>;
  inline: Array<Swnz_EmotionItemResponseBodyTextResourcesInline>;
};

export type Swnz_EmotionItemResponseBodyTextResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_EmotionItemResponseBodyTextResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_EmotionItemResponseBodyTextResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_EmotionItemResponseBodyTextResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_EmotionItemResponseBodyTextResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_EmotionItemResponseBodyTextResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_EmotionItemResponseCollection = {
  __typename?: 'SWNZ_EmotionItemResponseCollection';
  items: Array<Maybe<Swnz_EmotionItemResponse>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_EmotionItemResponseFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_EmotionItemResponseFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_EmotionItemResponseFilter>>>;
  bodyText_contains?: InputMaybe<Scalars['String']['input']>;
  bodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  callToAction?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  callToAction_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  heading?: InputMaybe<Scalars['String']['input']>;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading_not?: InputMaybe<Scalars['String']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_EmotionItemResponseLinkingCollections = {
  __typename?: 'SWNZ_EmotionItemResponseLinkingCollections';
  emotionItemCollection?: Maybe<Swnz_EmotionItemCollection>;
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_EmotionItemResponseLinkingCollectionsEmotionItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_EmotionItemResponseLinkingCollectionsEmotionItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_EmotionItemResponseLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_EmotionItemResponseLinkingCollectionsEmotionItemCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_EmotionItemResponseOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Swnz_EmotionItemResponsesCollection = {
  __typename?: 'SWNZ_EmotionItemResponsesCollection';
  items: Array<Maybe<Swnz_EmotionItemResponse>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_EmotionItemResponsesCollectionOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Swnz_Entry = {
  contentfulMetadata: Swnz_ContentfulMetadata;
  sys: Swnz_Sys;
};

export type Swnz_EntryCollection = {
  __typename?: 'SWNZ_EntryCollection';
  items: Array<Maybe<Swnz_Entry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_EntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_EntryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_EntryFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export enum Swnz_EntryOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Holds a list of dropdown elements. Used for populating form dropdowns [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formDropdown) */
export type Swnz_FormDropdown = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_FormDropdown';
  _id: Scalars['ID']['output'];
  codeId?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  dropdownOptionsCollection?: Maybe<Swnz_FormDropdownDropdownOptionsCollection>;
  identifier?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_FormDropdownLinkingCollections>;
  sys: Swnz_Sys;
};


/** Holds a list of dropdown elements. Used for populating form dropdowns [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formDropdown) */
export type Swnz_FormDropdownCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Holds a list of dropdown elements. Used for populating form dropdowns [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formDropdown) */
export type Swnz_FormDropdownDropdownOptionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_FormDropdownDropdownOptionsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_FormDropdownOptionFilter>;
};


/** Holds a list of dropdown elements. Used for populating form dropdowns [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formDropdown) */
export type Swnz_FormDropdownIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Holds a list of dropdown elements. Used for populating form dropdowns [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formDropdown) */
export type Swnz_FormDropdownLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Holds a list of dropdown elements. Used for populating form dropdowns [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formDropdown) */
export type Swnz_FormDropdownLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_FormDropdownCollection = {
  __typename?: 'SWNZ_FormDropdownCollection';
  items: Array<Maybe<Swnz_FormDropdown>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_FormDropdownDropdownOptionsCollection = {
  __typename?: 'SWNZ_FormDropdownDropdownOptionsCollection';
  items: Array<Maybe<Swnz_FormDropdownOption>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_FormDropdownDropdownOptionsCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC'
}

export type Swnz_FormDropdownFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_FormDropdownFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_FormDropdownFilter>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  dropdownOptions?: InputMaybe<Swnz_CfFormDropdownOptionNestedFilter>;
  dropdownOptionsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_FormDropdownLinkingCollections = {
  __typename?: 'SWNZ_FormDropdownLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  formLeadCaptureCollection?: Maybe<Swnz_FormLeadCaptureCollection>;
};


export type Swnz_FormDropdownLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_FormDropdownLinkingCollectionsFormLeadCaptureCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_FormDropdownLinkingCollectionsFormLeadCaptureCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_FormDropdownLinkingCollectionsFormLeadCaptureCollectionOrder {
  ButtonLabelAsc = 'buttonLabel_ASC',
  ButtonLabelDesc = 'buttonLabel_DESC',
  DisplayDateOfBirthAsc = 'displayDateOfBirth_ASC',
  DisplayDateOfBirthDesc = 'displayDateOfBirth_DESC',
  DisplayHowDidYouHearAboutTheFairAsc = 'displayHowDidYouHearAboutTheFair_ASC',
  DisplayHowDidYouHearAboutTheFairDesc = 'displayHowDidYouHearAboutTheFair_DESC',
  DisplayIntendedAreaOfStudyAsc = 'displayIntendedAreaOfStudy_ASC',
  DisplayIntendedAreaOfStudyDesc = 'displayIntendedAreaOfStudy_DESC',
  DisplayLineIdFieldAsc = 'displayLineIdField_ASC',
  DisplayLineIdFieldDesc = 'displayLineIdField_DESC',
  DisplayPersonTypeIAmAAsc = 'displayPersonTypeIAmA_ASC',
  DisplayPersonTypeIAmADesc = 'displayPersonTypeIAmA_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SourceIdAsc = 'sourceId_ASC',
  SourceIdDesc = 'sourceId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC'
}

/** Holds options for a dropdown [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formDropdownOption) */
export type Swnz_FormDropdownOption = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_FormDropdownOption';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_FormDropdownOptionLinkingCollections>;
  sys: Swnz_Sys;
  value?: Maybe<Scalars['String']['output']>;
};


/** Holds options for a dropdown [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formDropdownOption) */
export type Swnz_FormDropdownOptionIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Holds options for a dropdown [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formDropdownOption) */
export type Swnz_FormDropdownOptionLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Holds options for a dropdown [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formDropdownOption) */
export type Swnz_FormDropdownOptionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Holds options for a dropdown [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formDropdownOption) */
export type Swnz_FormDropdownOptionValueArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_FormDropdownOptionCollection = {
  __typename?: 'SWNZ_FormDropdownOptionCollection';
  items: Array<Maybe<Swnz_FormDropdownOption>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_FormDropdownOptionFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_FormDropdownOptionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_FormDropdownOptionFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_exists?: InputMaybe<Scalars['Boolean']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_FormDropdownOptionLinkingCollections = {
  __typename?: 'SWNZ_FormDropdownOptionLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  formDropdownCollection?: Maybe<Swnz_FormDropdownCollection>;
};


export type Swnz_FormDropdownOptionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_FormDropdownOptionLinkingCollectionsFormDropdownCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_FormDropdownOptionLinkingCollectionsFormDropdownCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_FormDropdownOptionLinkingCollectionsFormDropdownCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_FormDropdownOptionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC'
}

export enum Swnz_FormDropdownOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** City selection option for Form Lead Capture [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formFieldCity) */
export type Swnz_FormFieldCity = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_FormFieldCity';
  _id: Scalars['ID']['output'];
  cityLabel?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_FormFieldCityLinkingCollections>;
  sys: Swnz_Sys;
};


/** City selection option for Form Lead Capture [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formFieldCity) */
export type Swnz_FormFieldCityCityLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** City selection option for Form Lead Capture [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formFieldCity) */
export type Swnz_FormFieldCityIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** City selection option for Form Lead Capture [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formFieldCity) */
export type Swnz_FormFieldCityKeyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** City selection option for Form Lead Capture [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formFieldCity) */
export type Swnz_FormFieldCityLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_FormFieldCityCollection = {
  __typename?: 'SWNZ_FormFieldCityCollection';
  items: Array<Maybe<Swnz_FormFieldCity>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_FormFieldCityFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_FormFieldCityFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_FormFieldCityFilter>>>;
  cityLabel?: InputMaybe<Scalars['String']['input']>;
  cityLabel_contains?: InputMaybe<Scalars['String']['input']>;
  cityLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cityLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cityLabel_not?: InputMaybe<Scalars['String']['input']>;
  cityLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  cityLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_exists?: InputMaybe<Scalars['Boolean']['input']>;
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_FormFieldCityLinkingCollections = {
  __typename?: 'SWNZ_FormFieldCityLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  formLeadCaptureCollection?: Maybe<Swnz_FormLeadCaptureCollection>;
};


export type Swnz_FormFieldCityLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_FormFieldCityLinkingCollectionsFormLeadCaptureCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_FormFieldCityLinkingCollectionsFormLeadCaptureCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_FormFieldCityLinkingCollectionsFormLeadCaptureCollectionOrder {
  ButtonLabelAsc = 'buttonLabel_ASC',
  ButtonLabelDesc = 'buttonLabel_DESC',
  DisplayDateOfBirthAsc = 'displayDateOfBirth_ASC',
  DisplayDateOfBirthDesc = 'displayDateOfBirth_DESC',
  DisplayHowDidYouHearAboutTheFairAsc = 'displayHowDidYouHearAboutTheFair_ASC',
  DisplayHowDidYouHearAboutTheFairDesc = 'displayHowDidYouHearAboutTheFair_DESC',
  DisplayIntendedAreaOfStudyAsc = 'displayIntendedAreaOfStudy_ASC',
  DisplayIntendedAreaOfStudyDesc = 'displayIntendedAreaOfStudy_DESC',
  DisplayLineIdFieldAsc = 'displayLineIdField_ASC',
  DisplayLineIdFieldDesc = 'displayLineIdField_DESC',
  DisplayPersonTypeIAmAAsc = 'displayPersonTypeIAmA_ASC',
  DisplayPersonTypeIAmADesc = 'displayPersonTypeIAmA_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SourceIdAsc = 'sourceId_ASC',
  SourceIdDesc = 'sourceId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC'
}

export enum Swnz_FormFieldCityOrder {
  CityLabelAsc = 'cityLabel_ASC',
  CityLabelDesc = 'cityLabel_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Embedded form module configuration for customisable forms e.g. promotions, registrations [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formLeadCapture) */
export type Swnz_FormLeadCapture = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_FormLeadCapture';
  _id: Scalars['ID']['output'];
  buttonLabel?: Maybe<Scalars['String']['output']>;
  citiesCollection?: Maybe<Swnz_FormLeadCaptureCitiesCollection>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  displayDateOfBirth?: Maybe<Scalars['Boolean']['output']>;
  displayHowDidYouHearAboutTheFair?: Maybe<Scalars['Boolean']['output']>;
  displayIntendedAreaOfStudy?: Maybe<Scalars['Boolean']['output']>;
  displayLineIdField?: Maybe<Scalars['Boolean']['output']>;
  displayPersonTypeIAmA?: Maybe<Scalars['Boolean']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_FormLeadCaptureLinkingCollections>;
  questionHowDidYouHearAboutTheFair?: Maybe<Swnz_FormDropdown>;
  sourceId?: Maybe<Scalars['String']['output']>;
  subHeading?: Maybe<Scalars['String']['output']>;
  sys: Swnz_Sys;
  theme?: Maybe<Scalars['String']['output']>;
};


/** Embedded form module configuration for customisable forms e.g. promotions, registrations [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formLeadCapture) */
export type Swnz_FormLeadCaptureButtonLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Embedded form module configuration for customisable forms e.g. promotions, registrations [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formLeadCapture) */
export type Swnz_FormLeadCaptureCitiesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_FormLeadCaptureCitiesCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_FormFieldCityFilter>;
};


/** Embedded form module configuration for customisable forms e.g. promotions, registrations [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formLeadCapture) */
export type Swnz_FormLeadCaptureDisplayDateOfBirthArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Embedded form module configuration for customisable forms e.g. promotions, registrations [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formLeadCapture) */
export type Swnz_FormLeadCaptureDisplayHowDidYouHearAboutTheFairArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Embedded form module configuration for customisable forms e.g. promotions, registrations [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formLeadCapture) */
export type Swnz_FormLeadCaptureDisplayIntendedAreaOfStudyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Embedded form module configuration for customisable forms e.g. promotions, registrations [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formLeadCapture) */
export type Swnz_FormLeadCaptureDisplayLineIdFieldArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Embedded form module configuration for customisable forms e.g. promotions, registrations [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formLeadCapture) */
export type Swnz_FormLeadCaptureDisplayPersonTypeIAmAArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Embedded form module configuration for customisable forms e.g. promotions, registrations [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formLeadCapture) */
export type Swnz_FormLeadCaptureHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Embedded form module configuration for customisable forms e.g. promotions, registrations [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formLeadCapture) */
export type Swnz_FormLeadCaptureIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Embedded form module configuration for customisable forms e.g. promotions, registrations [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formLeadCapture) */
export type Swnz_FormLeadCaptureLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Embedded form module configuration for customisable forms e.g. promotions, registrations [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formLeadCapture) */
export type Swnz_FormLeadCaptureQuestionHowDidYouHearAboutTheFairArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_FormDropdownFilter>;
};


/** Embedded form module configuration for customisable forms e.g. promotions, registrations [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formLeadCapture) */
export type Swnz_FormLeadCaptureSourceIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Embedded form module configuration for customisable forms e.g. promotions, registrations [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formLeadCapture) */
export type Swnz_FormLeadCaptureSubHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Embedded form module configuration for customisable forms e.g. promotions, registrations [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/formLeadCapture) */
export type Swnz_FormLeadCaptureThemeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_FormLeadCaptureCitiesCollection = {
  __typename?: 'SWNZ_FormLeadCaptureCitiesCollection';
  items: Array<Maybe<Swnz_FormFieldCity>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_FormLeadCaptureCitiesCollectionOrder {
  CityLabelAsc = 'cityLabel_ASC',
  CityLabelDesc = 'cityLabel_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Swnz_FormLeadCaptureCollection = {
  __typename?: 'SWNZ_FormLeadCaptureCollection';
  items: Array<Maybe<Swnz_FormLeadCapture>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_FormLeadCaptureFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_FormLeadCaptureFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_FormLeadCaptureFilter>>>;
  buttonLabel?: InputMaybe<Scalars['String']['input']>;
  buttonLabel_contains?: InputMaybe<Scalars['String']['input']>;
  buttonLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  buttonLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  buttonLabel_not?: InputMaybe<Scalars['String']['input']>;
  buttonLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  buttonLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cities?: InputMaybe<Swnz_CfFormFieldCityNestedFilter>;
  citiesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  displayDateOfBirth?: InputMaybe<Scalars['Boolean']['input']>;
  displayDateOfBirth_exists?: InputMaybe<Scalars['Boolean']['input']>;
  displayDateOfBirth_not?: InputMaybe<Scalars['Boolean']['input']>;
  displayHowDidYouHearAboutTheFair?: InputMaybe<Scalars['Boolean']['input']>;
  displayHowDidYouHearAboutTheFair_exists?: InputMaybe<Scalars['Boolean']['input']>;
  displayHowDidYouHearAboutTheFair_not?: InputMaybe<Scalars['Boolean']['input']>;
  displayIntendedAreaOfStudy?: InputMaybe<Scalars['Boolean']['input']>;
  displayIntendedAreaOfStudy_exists?: InputMaybe<Scalars['Boolean']['input']>;
  displayIntendedAreaOfStudy_not?: InputMaybe<Scalars['Boolean']['input']>;
  displayLineIdField?: InputMaybe<Scalars['Boolean']['input']>;
  displayLineIdField_exists?: InputMaybe<Scalars['Boolean']['input']>;
  displayLineIdField_not?: InputMaybe<Scalars['Boolean']['input']>;
  displayPersonTypeIAmA?: InputMaybe<Scalars['Boolean']['input']>;
  displayPersonTypeIAmA_exists?: InputMaybe<Scalars['Boolean']['input']>;
  displayPersonTypeIAmA_not?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Scalars['String']['input']>;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading_not?: InputMaybe<Scalars['String']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionHowDidYouHearAboutTheFair?: InputMaybe<Swnz_CfFormDropdownNestedFilter>;
  questionHowDidYouHearAboutTheFair_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sourceId?: InputMaybe<Scalars['String']['input']>;
  sourceId_contains?: InputMaybe<Scalars['String']['input']>;
  sourceId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sourceId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sourceId_not?: InputMaybe<Scalars['String']['input']>;
  sourceId_not_contains?: InputMaybe<Scalars['String']['input']>;
  sourceId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subHeading?: InputMaybe<Scalars['String']['input']>;
  subHeading_contains?: InputMaybe<Scalars['String']['input']>;
  subHeading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subHeading_not?: InputMaybe<Scalars['String']['input']>;
  subHeading_not_contains?: InputMaybe<Scalars['String']['input']>;
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  theme?: InputMaybe<Scalars['String']['input']>;
  theme_contains?: InputMaybe<Scalars['String']['input']>;
  theme_exists?: InputMaybe<Scalars['Boolean']['input']>;
  theme_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  theme_not?: InputMaybe<Scalars['String']['input']>;
  theme_not_contains?: InputMaybe<Scalars['String']['input']>;
  theme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_FormLeadCaptureLinkingCollections = {
  __typename?: 'SWNZ_FormLeadCaptureLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_FormLeadCaptureLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_FormLeadCaptureLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_FormLeadCaptureLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_FormLeadCaptureLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_FormLeadCaptureOrder {
  ButtonLabelAsc = 'buttonLabel_ASC',
  ButtonLabelDesc = 'buttonLabel_DESC',
  DisplayDateOfBirthAsc = 'displayDateOfBirth_ASC',
  DisplayDateOfBirthDesc = 'displayDateOfBirth_DESC',
  DisplayHowDidYouHearAboutTheFairAsc = 'displayHowDidYouHearAboutTheFair_ASC',
  DisplayHowDidYouHearAboutTheFairDesc = 'displayHowDidYouHearAboutTheFair_DESC',
  DisplayIntendedAreaOfStudyAsc = 'displayIntendedAreaOfStudy_ASC',
  DisplayIntendedAreaOfStudyDesc = 'displayIntendedAreaOfStudy_DESC',
  DisplayLineIdFieldAsc = 'displayLineIdField_ASC',
  DisplayLineIdFieldDesc = 'displayLineIdField_DESC',
  DisplayPersonTypeIAmAAsc = 'displayPersonTypeIAmA_ASC',
  DisplayPersonTypeIAmADesc = 'displayPersonTypeIAmA_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SourceIdAsc = 'sourceId_ASC',
  SourceIdDesc = 'sourceId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC'
}

/** Carousel of images or videos [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/galleryCarousel) */
export type Swnz_GalleryCarousel = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_GalleryCarousel';
  _id: Scalars['ID']['output'];
  codeId?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_GalleryCarouselLinkingCollections>;
  mediaItemsCollection?: Maybe<Swnz_GalleryCarouselMediaItemsCollection>;
  sys: Swnz_Sys;
};


/** Carousel of images or videos [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/galleryCarousel) */
export type Swnz_GalleryCarouselCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Carousel of images or videos [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/galleryCarousel) */
export type Swnz_GalleryCarouselIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Carousel of images or videos [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/galleryCarousel) */
export type Swnz_GalleryCarouselLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Carousel of images or videos [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/galleryCarousel) */
export type Swnz_GalleryCarouselMediaItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_GalleryCarouselMediaItemsFilter>;
};

export type Swnz_GalleryCarouselCollection = {
  __typename?: 'SWNZ_GalleryCarouselCollection';
  items: Array<Maybe<Swnz_GalleryCarousel>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_GalleryCarouselFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_GalleryCarouselFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_GalleryCarouselFilter>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mediaItems?: InputMaybe<Swnz_CfmediaItemsMultiTypeNestedFilter>;
  mediaItemsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_GalleryCarouselLinkingCollections = {
  __typename?: 'SWNZ_GalleryCarouselLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_GalleryCarouselLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_GalleryCarouselLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_GalleryCarouselLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_GalleryCarouselLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_GalleryCarouselLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_GalleryCarouselLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_GalleryCarouselLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Swnz_GalleryCarouselMediaItemsCollection = {
  __typename?: 'SWNZ_GalleryCarouselMediaItemsCollection';
  items: Array<Maybe<Swnz_GalleryCarouselMediaItemsItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_GalleryCarouselMediaItemsFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_GalleryCarouselMediaItemsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_GalleryCarouselMediaItemsFilter>>>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_contains?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_not?: InputMaybe<Scalars['String']['input']>;
  caption_not_contains?: InputMaybe<Scalars['String']['input']>;
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_GalleryCarouselMediaItemsItem = Swnz_MediaImage | Swnz_MediaVideo;

export enum Swnz_GalleryCarouselOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Text displaying in 1 or 2 columns - allows links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/genericText) */
export type Swnz_GenericText = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_GenericText';
  _id: Scalars['ID']['output'];
  backgroundColour?: Maybe<Scalars['String']['output']>;
  bodyText?: Maybe<Swnz_GenericTextBodyText>;
  codeId?: Maybe<Scalars['String']['output']>;
  contentAlignment?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_GenericTextLinkingCollections>;
  primaryCta?: Maybe<Swnz_CallToAction>;
  secondaryCta?: Maybe<Swnz_CallToAction>;
  sys: Swnz_Sys;
  themeBackgroundPosition?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};


/** Text displaying in 1 or 2 columns - allows links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/genericText) */
export type Swnz_GenericTextBackgroundColourArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Text displaying in 1 or 2 columns - allows links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/genericText) */
export type Swnz_GenericTextBodyTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Text displaying in 1 or 2 columns - allows links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/genericText) */
export type Swnz_GenericTextCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Text displaying in 1 or 2 columns - allows links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/genericText) */
export type Swnz_GenericTextContentAlignmentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Text displaying in 1 or 2 columns - allows links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/genericText) */
export type Swnz_GenericTextIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Text displaying in 1 or 2 columns - allows links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/genericText) */
export type Swnz_GenericTextLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Text displaying in 1 or 2 columns - allows links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/genericText) */
export type Swnz_GenericTextPrimaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** Text displaying in 1 or 2 columns - allows links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/genericText) */
export type Swnz_GenericTextSecondaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** Text displaying in 1 or 2 columns - allows links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/genericText) */
export type Swnz_GenericTextThemeBackgroundPositionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Text displaying in 1 or 2 columns - allows links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/genericText) */
export type Swnz_GenericTextTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_GenericTextBodyText = {
  __typename?: 'SWNZ_GenericTextBodyText';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_GenericTextBodyTextLinks;
};

export type Swnz_GenericTextBodyTextAssets = {
  __typename?: 'SWNZ_GenericTextBodyTextAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_GenericTextBodyTextEntries = {
  __typename?: 'SWNZ_GenericTextBodyTextEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_GenericTextBodyTextLinks = {
  __typename?: 'SWNZ_GenericTextBodyTextLinks';
  assets: Swnz_GenericTextBodyTextAssets;
  entries: Swnz_GenericTextBodyTextEntries;
  resources: Swnz_GenericTextBodyTextResources;
};

export type Swnz_GenericTextBodyTextResources = {
  __typename?: 'SWNZ_GenericTextBodyTextResources';
  block: Array<Swnz_GenericTextBodyTextResourcesBlock>;
  hyperlink: Array<Swnz_GenericTextBodyTextResourcesHyperlink>;
  inline: Array<Swnz_GenericTextBodyTextResourcesInline>;
};

export type Swnz_GenericTextBodyTextResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_GenericTextBodyTextResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_GenericTextBodyTextResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_GenericTextBodyTextResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_GenericTextBodyTextResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_GenericTextBodyTextResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_GenericTextCollection = {
  __typename?: 'SWNZ_GenericTextCollection';
  items: Array<Maybe<Swnz_GenericText>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_GenericTextFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_GenericTextFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_GenericTextFilter>>>;
  backgroundColour?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColour_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  backgroundColour_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bodyText_contains?: InputMaybe<Scalars['String']['input']>;
  bodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentAlignment?: InputMaybe<Scalars['String']['input']>;
  contentAlignment_contains?: InputMaybe<Scalars['String']['input']>;
  contentAlignment_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentAlignment_not?: InputMaybe<Scalars['String']['input']>;
  contentAlignment_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  primaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  primaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  secondaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  themeBackgroundPosition?: InputMaybe<Scalars['String']['input']>;
  themeBackgroundPosition_contains?: InputMaybe<Scalars['String']['input']>;
  themeBackgroundPosition_exists?: InputMaybe<Scalars['Boolean']['input']>;
  themeBackgroundPosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  themeBackgroundPosition_not?: InputMaybe<Scalars['String']['input']>;
  themeBackgroundPosition_not_contains?: InputMaybe<Scalars['String']['input']>;
  themeBackgroundPosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_GenericTextLinkingCollections = {
  __typename?: 'SWNZ_GenericTextLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageModalCollection?: Maybe<Swnz_PageModalCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_GenericTextLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_GenericTextLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_GenericTextLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_GenericTextLinkingCollectionsPageModalCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_GenericTextLinkingCollectionsPageModalCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_GenericTextLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_GenericTextLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_GenericTextLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_GenericTextLinkingCollectionsPageModalCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_GenericTextLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_GenericTextOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  ContentAlignmentAsc = 'contentAlignment_ASC',
  ContentAlignmentDesc = 'contentAlignment_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeBackgroundPositionAsc = 'themeBackgroundPosition_ASC',
  ThemeBackgroundPositionDesc = 'themeBackgroundPosition_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Global settings [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/globalSettings) */
export type Swnz_GlobalSettings = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_GlobalSettings';
  _id: Scalars['ID']['output'];
  callToActionCollection?: Maybe<Swnz_GlobalSettingsCallToActionCollection>;
  codeId?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_GlobalSettingsLinkingCollections>;
  media?: Maybe<Swnz_Asset>;
  richText?: Maybe<Swnz_GlobalSettingsRichText>;
  sys: Swnz_Sys;
  value?: Maybe<Scalars['String']['output']>;
};


/** Global settings [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/globalSettings) */
export type Swnz_GlobalSettingsCallToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_GlobalSettingsCallToActionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** Global settings [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/globalSettings) */
export type Swnz_GlobalSettingsCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Global settings [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/globalSettings) */
export type Swnz_GlobalSettingsIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Global settings [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/globalSettings) */
export type Swnz_GlobalSettingsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Global settings [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/globalSettings) */
export type Swnz_GlobalSettingsMediaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Global settings [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/globalSettings) */
export type Swnz_GlobalSettingsRichTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Global settings [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/globalSettings) */
export type Swnz_GlobalSettingsValueArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_GlobalSettingsCallToActionCollection = {
  __typename?: 'SWNZ_GlobalSettingsCallToActionCollection';
  items: Array<Maybe<Swnz_CallToAction>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_GlobalSettingsCallToActionCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  ModalIdAsc = 'modalId_ASC',
  ModalIdDesc = 'modalId_DESC',
  OpenInNewWindowAsc = 'openInNewWindow_ASC',
  OpenInNewWindowDesc = 'openInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlLinkAsc = 'urlLink_ASC',
  UrlLinkDesc = 'urlLink_DESC'
}

export type Swnz_GlobalSettingsCollection = {
  __typename?: 'SWNZ_GlobalSettingsCollection';
  items: Array<Maybe<Swnz_GlobalSettings>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_GlobalSettingsFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_GlobalSettingsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_GlobalSettingsFilter>>>;
  callToAction?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  callToActionCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  media_exists?: InputMaybe<Scalars['Boolean']['input']>;
  richText_contains?: InputMaybe<Scalars['String']['input']>;
  richText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  richText_not_contains?: InputMaybe<Scalars['String']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_exists?: InputMaybe<Scalars['Boolean']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_GlobalSettingsLinkingCollections = {
  __typename?: 'SWNZ_GlobalSettingsLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_GlobalSettingsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_GlobalSettingsOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Swnz_GlobalSettingsRichText = {
  __typename?: 'SWNZ_GlobalSettingsRichText';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_GlobalSettingsRichTextLinks;
};

export type Swnz_GlobalSettingsRichTextAssets = {
  __typename?: 'SWNZ_GlobalSettingsRichTextAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_GlobalSettingsRichTextEntries = {
  __typename?: 'SWNZ_GlobalSettingsRichTextEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_GlobalSettingsRichTextLinks = {
  __typename?: 'SWNZ_GlobalSettingsRichTextLinks';
  assets: Swnz_GlobalSettingsRichTextAssets;
  entries: Swnz_GlobalSettingsRichTextEntries;
  resources: Swnz_GlobalSettingsRichTextResources;
};

export type Swnz_GlobalSettingsRichTextResources = {
  __typename?: 'SWNZ_GlobalSettingsRichTextResources';
  block: Array<Swnz_GlobalSettingsRichTextResourcesBlock>;
  hyperlink: Array<Swnz_GlobalSettingsRichTextResourcesHyperlink>;
  inline: Array<Swnz_GlobalSettingsRichTextResourcesInline>;
};

export type Swnz_GlobalSettingsRichTextResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_GlobalSettingsRichTextResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_GlobalSettingsRichTextResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_GlobalSettingsRichTextResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_GlobalSettingsRichTextResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_GlobalSettingsRichTextResourcesInline';
  sys: Swnz_ResourceSys;
};

/** Glossary term and definition used site-wide [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/glossary) */
export type Swnz_Glossary = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_Glossary';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  glossaryDefinition?: Maybe<Scalars['String']['output']>;
  glossaryTerm?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_GlossaryLinkingCollections>;
  sys: Swnz_Sys;
};


/** Glossary term and definition used site-wide [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/glossary) */
export type Swnz_GlossaryGlossaryDefinitionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Glossary term and definition used site-wide [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/glossary) */
export type Swnz_GlossaryGlossaryTermArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Glossary term and definition used site-wide [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/glossary) */
export type Swnz_GlossaryIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Glossary term and definition used site-wide [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/glossary) */
export type Swnz_GlossaryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_GlossaryCollection = {
  __typename?: 'SWNZ_GlossaryCollection';
  items: Array<Maybe<Swnz_Glossary>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_GlossaryFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_GlossaryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_GlossaryFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  glossaryDefinition?: InputMaybe<Scalars['String']['input']>;
  glossaryDefinition_contains?: InputMaybe<Scalars['String']['input']>;
  glossaryDefinition_exists?: InputMaybe<Scalars['Boolean']['input']>;
  glossaryDefinition_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  glossaryDefinition_not?: InputMaybe<Scalars['String']['input']>;
  glossaryDefinition_not_contains?: InputMaybe<Scalars['String']['input']>;
  glossaryDefinition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  glossaryTerm?: InputMaybe<Scalars['String']['input']>;
  glossaryTerm_contains?: InputMaybe<Scalars['String']['input']>;
  glossaryTerm_exists?: InputMaybe<Scalars['Boolean']['input']>;
  glossaryTerm_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  glossaryTerm_not?: InputMaybe<Scalars['String']['input']>;
  glossaryTerm_not_contains?: InputMaybe<Scalars['String']['input']>;
  glossaryTerm_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_GlossaryLinkingCollections = {
  __typename?: 'SWNZ_GlossaryLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_GlossaryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_GlossaryOrder {
  GlossaryTermAsc = 'glossaryTerm_ASC',
  GlossaryTermDesc = 'glossaryTerm_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Hero : Full Bleed and Reduced hero [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/hero) */
export type Swnz_Hero = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_Hero';
  _id: Scalars['ID']['output'];
  backgroundColour?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  displayType?: Maybe<Scalars['String']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  heroBackgroundVideo?: Maybe<Swnz_Asset>;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_HeroLinkingCollections>;
  mediaItem?: Maybe<Swnz_HeroMediaItem>;
  primaryCta?: Maybe<Swnz_CallToAction>;
  sys: Swnz_Sys;
};


/** Hero : Full Bleed and Reduced hero [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/hero) */
export type Swnz_HeroBackgroundColourArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Hero : Full Bleed and Reduced hero [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/hero) */
export type Swnz_HeroDisplayTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Hero : Full Bleed and Reduced hero [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/hero) */
export type Swnz_HeroHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Hero : Full Bleed and Reduced hero [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/hero) */
export type Swnz_HeroHeroBackgroundVideoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Hero : Full Bleed and Reduced hero [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/hero) */
export type Swnz_HeroIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Hero : Full Bleed and Reduced hero [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/hero) */
export type Swnz_HeroLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Hero : Full Bleed and Reduced hero [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/hero) */
export type Swnz_HeroMediaItemArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_HeroMediaItemFilter>;
};


/** Hero : Full Bleed and Reduced hero [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/hero) */
export type Swnz_HeroPrimaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};

export type Swnz_HeroCollection = {
  __typename?: 'SWNZ_HeroCollection';
  items: Array<Maybe<Swnz_Hero>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** Hero Featured: Image or Video with CTA hero and/or floating card [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroFeatured) */
export type Swnz_HeroFeatured = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_HeroFeatured';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  heading?: Maybe<Scalars['String']['output']>;
  heroFloatingCard?: Maybe<Swnz_HeroFeaturedFloatingCard>;
  identifier?: Maybe<Scalars['String']['output']>;
  introText?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_HeroFeaturedLinkingCollections>;
  mediaItem?: Maybe<Swnz_HeroFeaturedMediaItem>;
  primaryCta?: Maybe<Swnz_CallToAction>;
  secondaryCta?: Maybe<Swnz_CallToAction>;
  subHeading?: Maybe<Scalars['String']['output']>;
  sys: Swnz_Sys;
  textThemeColour?: Maybe<Scalars['String']['output']>;
};


/** Hero Featured: Image or Video with CTA hero and/or floating card [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroFeatured) */
export type Swnz_HeroFeaturedHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Hero Featured: Image or Video with CTA hero and/or floating card [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroFeatured) */
export type Swnz_HeroFeaturedHeroFloatingCardArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_HeroFeaturedFloatingCardFilter>;
};


/** Hero Featured: Image or Video with CTA hero and/or floating card [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroFeatured) */
export type Swnz_HeroFeaturedIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Hero Featured: Image or Video with CTA hero and/or floating card [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroFeatured) */
export type Swnz_HeroFeaturedIntroTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Hero Featured: Image or Video with CTA hero and/or floating card [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroFeatured) */
export type Swnz_HeroFeaturedLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Hero Featured: Image or Video with CTA hero and/or floating card [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroFeatured) */
export type Swnz_HeroFeaturedMediaItemArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_HeroFeaturedMediaItemFilter>;
};


/** Hero Featured: Image or Video with CTA hero and/or floating card [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroFeatured) */
export type Swnz_HeroFeaturedPrimaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** Hero Featured: Image or Video with CTA hero and/or floating card [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroFeatured) */
export type Swnz_HeroFeaturedSecondaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** Hero Featured: Image or Video with CTA hero and/or floating card [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroFeatured) */
export type Swnz_HeroFeaturedSubHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Hero Featured: Image or Video with CTA hero and/or floating card [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroFeatured) */
export type Swnz_HeroFeaturedTextThemeColourArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_HeroFeaturedCollection = {
  __typename?: 'SWNZ_HeroFeaturedCollection';
  items: Array<Maybe<Swnz_HeroFeatured>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_HeroFeaturedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_HeroFeaturedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_HeroFeaturedFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  heading?: InputMaybe<Scalars['String']['input']>;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading_not?: InputMaybe<Scalars['String']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heroFloatingCard?: InputMaybe<Swnz_CfHeroFeaturedFloatingCardNestedFilter>;
  heroFloatingCard_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introText?: InputMaybe<Scalars['String']['input']>;
  introText_contains?: InputMaybe<Scalars['String']['input']>;
  introText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  introText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introText_not?: InputMaybe<Scalars['String']['input']>;
  introText_not_contains?: InputMaybe<Scalars['String']['input']>;
  introText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mediaItem?: InputMaybe<Swnz_CfmediaItemMultiTypeNestedFilter>;
  mediaItem_exists?: InputMaybe<Scalars['Boolean']['input']>;
  primaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  primaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  secondaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subHeading?: InputMaybe<Scalars['String']['input']>;
  subHeading_contains?: InputMaybe<Scalars['String']['input']>;
  subHeading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subHeading_not?: InputMaybe<Scalars['String']['input']>;
  subHeading_not_contains?: InputMaybe<Scalars['String']['input']>;
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  textThemeColour?: InputMaybe<Scalars['String']['input']>;
  textThemeColour_contains?: InputMaybe<Scalars['String']['input']>;
  textThemeColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  textThemeColour_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  textThemeColour_not?: InputMaybe<Scalars['String']['input']>;
  textThemeColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  textThemeColour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** A floating card to display on the Hero Featured [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroFeaturedFloatingCard) */
export type Swnz_HeroFeaturedFloatingCard = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_HeroFeaturedFloatingCard';
  _id: Scalars['ID']['output'];
  cardHeading?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  internalLink?: Maybe<Swnz_HeroFeaturedFloatingCardInternalLink>;
  linkedFrom?: Maybe<Swnz_HeroFeaturedFloatingCardLinkingCollections>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** A floating card to display on the Hero Featured [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroFeaturedFloatingCard) */
export type Swnz_HeroFeaturedFloatingCardCardHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A floating card to display on the Hero Featured [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroFeaturedFloatingCard) */
export type Swnz_HeroFeaturedFloatingCardIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A floating card to display on the Hero Featured [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroFeaturedFloatingCard) */
export type Swnz_HeroFeaturedFloatingCardInternalLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** A floating card to display on the Hero Featured [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroFeaturedFloatingCard) */
export type Swnz_HeroFeaturedFloatingCardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** A floating card to display on the Hero Featured [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroFeaturedFloatingCard) */
export type Swnz_HeroFeaturedFloatingCardTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_HeroFeaturedFloatingCardCollection = {
  __typename?: 'SWNZ_HeroFeaturedFloatingCardCollection';
  items: Array<Maybe<Swnz_HeroFeaturedFloatingCard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_HeroFeaturedFloatingCardFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_HeroFeaturedFloatingCardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_HeroFeaturedFloatingCardFilter>>>;
  cardHeading?: InputMaybe<Scalars['String']['input']>;
  cardHeading_contains?: InputMaybe<Scalars['String']['input']>;
  cardHeading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cardHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cardHeading_not?: InputMaybe<Scalars['String']['input']>;
  cardHeading_not_contains?: InputMaybe<Scalars['String']['input']>;
  cardHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  internalLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_HeroFeaturedFloatingCardInternalLink = Swnz_MediaVideo | Swnz_NaumaiPageStandard | Swnz_Page;

export type Swnz_HeroFeaturedFloatingCardLinkingCollections = {
  __typename?: 'SWNZ_HeroFeaturedFloatingCardLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  heroFeaturedCollection?: Maybe<Swnz_HeroFeaturedCollection>;
};


export type Swnz_HeroFeaturedFloatingCardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_HeroFeaturedFloatingCardLinkingCollectionsHeroFeaturedCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_HeroFeaturedFloatingCardLinkingCollectionsHeroFeaturedCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_HeroFeaturedFloatingCardLinkingCollectionsHeroFeaturedCollectionOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SubHeadingAsc = 'subHeading_ASC',
  SubHeadingDesc = 'subHeading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextThemeColourAsc = 'textThemeColour_ASC',
  TextThemeColourDesc = 'textThemeColour_DESC'
}

export enum Swnz_HeroFeaturedFloatingCardOrder {
  CardHeadingAsc = 'cardHeading_ASC',
  CardHeadingDesc = 'cardHeading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Swnz_HeroFeaturedLinkingCollections = {
  __typename?: 'SWNZ_HeroFeaturedLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageHomepageCollection?: Maybe<Swnz_PageHomepageCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_HeroFeaturedLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_HeroFeaturedLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_HeroFeaturedLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_HeroFeaturedLinkingCollectionsPageHomepageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_HeroFeaturedLinkingCollectionsPageHomepageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_HeroFeaturedLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_HeroFeaturedLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_HeroFeaturedLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_HeroFeaturedLinkingCollectionsPageHomepageCollectionOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  HeroHeadingAsc = 'heroHeading_ASC',
  HeroHeadingDesc = 'heroHeading_DESC',
  HeroJumpTextAsc = 'heroJumpText_ASC',
  HeroJumpTextDesc = 'heroJumpText_DESC',
  HeroPreHeadingAsc = 'heroPreHeading_ASC',
  HeroPreHeadingDesc = 'heroPreHeading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_HeroFeaturedLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Swnz_HeroFeaturedMediaItem = Swnz_MediaImage | Swnz_MediaVideo;

export type Swnz_HeroFeaturedMediaItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_HeroFeaturedMediaItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_HeroFeaturedMediaItemFilter>>>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_contains?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_not?: InputMaybe<Scalars['String']['input']>;
  caption_not_contains?: InputMaybe<Scalars['String']['input']>;
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export enum Swnz_HeroFeaturedOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SubHeadingAsc = 'subHeading_ASC',
  SubHeadingDesc = 'subHeading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextThemeColourAsc = 'textThemeColour_ASC',
  TextThemeColourDesc = 'textThemeColour_DESC'
}

export type Swnz_HeroFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_HeroFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_HeroFilter>>>;
  backgroundColour?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColour_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  backgroundColour_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  displayType?: InputMaybe<Scalars['String']['input']>;
  displayType_contains?: InputMaybe<Scalars['String']['input']>;
  displayType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  displayType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayType_not?: InputMaybe<Scalars['String']['input']>;
  displayType_not_contains?: InputMaybe<Scalars['String']['input']>;
  displayType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading?: InputMaybe<Scalars['String']['input']>;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading_not?: InputMaybe<Scalars['String']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heroBackgroundVideo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mediaItem?: InputMaybe<Swnz_CfmediaItemMultiTypeNestedFilter>;
  mediaItem_exists?: InputMaybe<Scalars['Boolean']['input']>;
  primaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  primaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_HeroLinkingCollections = {
  __typename?: 'SWNZ_HeroLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_HeroLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_HeroLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_HeroLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_HeroLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_HeroLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_HeroLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_HeroLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Swnz_HeroMediaItem = Swnz_MediaImage | Swnz_MediaVideo;

export type Swnz_HeroMediaItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_HeroMediaItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_HeroMediaItemFilter>>>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_contains?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_not?: InputMaybe<Scalars['String']['input']>;
  caption_not_contains?: InputMaybe<Scalars['String']['input']>;
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export enum Swnz_HeroOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  DisplayTypeAsc = 'displayType_ASC',
  DisplayTypeDesc = 'displayType_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTab = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_HeroTab';
  _id: Scalars['ID']['output'];
  backgroundColour?: Maybe<Scalars['String']['output']>;
  backgroundImage?: Maybe<Swnz_MediaImage>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  foregroundImage?: Maybe<Swnz_MediaImage>;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_HeroTabLinkingCollections>;
  quoteAuthor?: Maybe<Scalars['String']['output']>;
  quoteStudyLocation?: Maybe<Scalars['String']['output']>;
  quoteText?: Maybe<Scalars['String']['output']>;
  secondaryCta?: Maybe<Swnz_CallToAction>;
  storyImageOne?: Maybe<Swnz_MediaImage>;
  storyImageTwo?: Maybe<Swnz_MediaImage>;
  storyLearnMoreOne?: Maybe<Swnz_Page>;
  storyLearnMoreTwo?: Maybe<Swnz_Page>;
  storySummaryOne?: Maybe<Swnz_HeroTabStorySummaryOne>;
  storySummaryTwo?: Maybe<Swnz_HeroTabStorySummaryTwo>;
  storyTitle?: Maybe<Scalars['String']['output']>;
  sys: Swnz_Sys;
  tabBackgroundImage?: Maybe<Swnz_MediaImage>;
  tabHeading?: Maybe<Scalars['String']['output']>;
  tabSubHeading?: Maybe<Scalars['String']['output']>;
  tabThumbnailImage?: Maybe<Swnz_MediaImage>;
};


/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTabBackgroundColourArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTabBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_MediaImageFilter>;
};


/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTabForegroundImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_MediaImageFilter>;
};


/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTabIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTabLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTabQuoteAuthorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTabQuoteStudyLocationArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTabQuoteTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTabSecondaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTabStoryImageOneArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_MediaImageFilter>;
};


/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTabStoryImageTwoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_MediaImageFilter>;
};


/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTabStoryLearnMoreOneArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_PageFilter>;
};


/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTabStoryLearnMoreTwoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_PageFilter>;
};


/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTabStorySummaryOneArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTabStorySummaryTwoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTabStoryTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTabTabBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_MediaImageFilter>;
};


/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTabTabHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTabTabSubHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Homepage hero tab featuring person & story [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/heroTab) */
export type Swnz_HeroTabTabThumbnailImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_MediaImageFilter>;
};

export type Swnz_HeroTabCollection = {
  __typename?: 'SWNZ_HeroTabCollection';
  items: Array<Maybe<Swnz_HeroTab>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_HeroTabFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_HeroTabFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_HeroTabFilter>>>;
  backgroundColour?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColour_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  backgroundColour_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  backgroundImage?: InputMaybe<Swnz_CfMediaImageNestedFilter>;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  foregroundImage?: InputMaybe<Swnz_CfMediaImageNestedFilter>;
  foregroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  quoteAuthor?: InputMaybe<Scalars['String']['input']>;
  quoteAuthor_contains?: InputMaybe<Scalars['String']['input']>;
  quoteAuthor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  quoteAuthor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  quoteAuthor_not?: InputMaybe<Scalars['String']['input']>;
  quoteAuthor_not_contains?: InputMaybe<Scalars['String']['input']>;
  quoteAuthor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  quoteStudyLocation?: InputMaybe<Scalars['String']['input']>;
  quoteStudyLocation_contains?: InputMaybe<Scalars['String']['input']>;
  quoteStudyLocation_exists?: InputMaybe<Scalars['Boolean']['input']>;
  quoteStudyLocation_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  quoteStudyLocation_not?: InputMaybe<Scalars['String']['input']>;
  quoteStudyLocation_not_contains?: InputMaybe<Scalars['String']['input']>;
  quoteStudyLocation_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  quoteText?: InputMaybe<Scalars['String']['input']>;
  quoteText_contains?: InputMaybe<Scalars['String']['input']>;
  quoteText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  quoteText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  quoteText_not?: InputMaybe<Scalars['String']['input']>;
  quoteText_not_contains?: InputMaybe<Scalars['String']['input']>;
  quoteText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  secondaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  secondaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  storyImageOne?: InputMaybe<Swnz_CfMediaImageNestedFilter>;
  storyImageOne_exists?: InputMaybe<Scalars['Boolean']['input']>;
  storyImageTwo?: InputMaybe<Swnz_CfMediaImageNestedFilter>;
  storyImageTwo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  storyLearnMoreOne?: InputMaybe<Swnz_CfPageNestedFilter>;
  storyLearnMoreOne_exists?: InputMaybe<Scalars['Boolean']['input']>;
  storyLearnMoreTwo?: InputMaybe<Swnz_CfPageNestedFilter>;
  storyLearnMoreTwo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  storySummaryOne_contains?: InputMaybe<Scalars['String']['input']>;
  storySummaryOne_exists?: InputMaybe<Scalars['Boolean']['input']>;
  storySummaryOne_not_contains?: InputMaybe<Scalars['String']['input']>;
  storySummaryTwo_contains?: InputMaybe<Scalars['String']['input']>;
  storySummaryTwo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  storySummaryTwo_not_contains?: InputMaybe<Scalars['String']['input']>;
  storyTitle?: InputMaybe<Scalars['String']['input']>;
  storyTitle_contains?: InputMaybe<Scalars['String']['input']>;
  storyTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  storyTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  storyTitle_not?: InputMaybe<Scalars['String']['input']>;
  storyTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  storyTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  tabBackgroundImage?: InputMaybe<Swnz_CfMediaImageNestedFilter>;
  tabBackgroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tabHeading?: InputMaybe<Scalars['String']['input']>;
  tabHeading_contains?: InputMaybe<Scalars['String']['input']>;
  tabHeading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tabHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tabHeading_not?: InputMaybe<Scalars['String']['input']>;
  tabHeading_not_contains?: InputMaybe<Scalars['String']['input']>;
  tabHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tabSubHeading?: InputMaybe<Scalars['String']['input']>;
  tabSubHeading_contains?: InputMaybe<Scalars['String']['input']>;
  tabSubHeading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tabSubHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tabSubHeading_not?: InputMaybe<Scalars['String']['input']>;
  tabSubHeading_not_contains?: InputMaybe<Scalars['String']['input']>;
  tabSubHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tabThumbnailImage?: InputMaybe<Swnz_CfMediaImageNestedFilter>;
  tabThumbnailImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Swnz_HeroTabLinkingCollections = {
  __typename?: 'SWNZ_HeroTabLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  pageHomepageCollection?: Maybe<Swnz_PageHomepageCollection>;
};


export type Swnz_HeroTabLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_HeroTabLinkingCollectionsPageHomepageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_HeroTabLinkingCollectionsPageHomepageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_HeroTabLinkingCollectionsPageHomepageCollectionOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  HeroHeadingAsc = 'heroHeading_ASC',
  HeroHeadingDesc = 'heroHeading_DESC',
  HeroJumpTextAsc = 'heroJumpText_ASC',
  HeroJumpTextDesc = 'heroJumpText_DESC',
  HeroPreHeadingAsc = 'heroPreHeading_ASC',
  HeroPreHeadingDesc = 'heroPreHeading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_HeroTabOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  QuoteAuthorAsc = 'quoteAuthor_ASC',
  QuoteAuthorDesc = 'quoteAuthor_DESC',
  QuoteStudyLocationAsc = 'quoteStudyLocation_ASC',
  QuoteStudyLocationDesc = 'quoteStudyLocation_DESC',
  QuoteTextAsc = 'quoteText_ASC',
  QuoteTextDesc = 'quoteText_DESC',
  StoryTitleAsc = 'storyTitle_ASC',
  StoryTitleDesc = 'storyTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TabHeadingAsc = 'tabHeading_ASC',
  TabHeadingDesc = 'tabHeading_DESC',
  TabSubHeadingAsc = 'tabSubHeading_ASC',
  TabSubHeadingDesc = 'tabSubHeading_DESC'
}

export type Swnz_HeroTabStorySummaryOne = {
  __typename?: 'SWNZ_HeroTabStorySummaryOne';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_HeroTabStorySummaryOneLinks;
};

export type Swnz_HeroTabStorySummaryOneAssets = {
  __typename?: 'SWNZ_HeroTabStorySummaryOneAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_HeroTabStorySummaryOneEntries = {
  __typename?: 'SWNZ_HeroTabStorySummaryOneEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_HeroTabStorySummaryOneLinks = {
  __typename?: 'SWNZ_HeroTabStorySummaryOneLinks';
  assets: Swnz_HeroTabStorySummaryOneAssets;
  entries: Swnz_HeroTabStorySummaryOneEntries;
  resources: Swnz_HeroTabStorySummaryOneResources;
};

export type Swnz_HeroTabStorySummaryOneResources = {
  __typename?: 'SWNZ_HeroTabStorySummaryOneResources';
  block: Array<Swnz_HeroTabStorySummaryOneResourcesBlock>;
  hyperlink: Array<Swnz_HeroTabStorySummaryOneResourcesHyperlink>;
  inline: Array<Swnz_HeroTabStorySummaryOneResourcesInline>;
};

export type Swnz_HeroTabStorySummaryOneResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_HeroTabStorySummaryOneResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_HeroTabStorySummaryOneResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_HeroTabStorySummaryOneResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_HeroTabStorySummaryOneResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_HeroTabStorySummaryOneResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_HeroTabStorySummaryTwo = {
  __typename?: 'SWNZ_HeroTabStorySummaryTwo';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_HeroTabStorySummaryTwoLinks;
};

export type Swnz_HeroTabStorySummaryTwoAssets = {
  __typename?: 'SWNZ_HeroTabStorySummaryTwoAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_HeroTabStorySummaryTwoEntries = {
  __typename?: 'SWNZ_HeroTabStorySummaryTwoEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_HeroTabStorySummaryTwoLinks = {
  __typename?: 'SWNZ_HeroTabStorySummaryTwoLinks';
  assets: Swnz_HeroTabStorySummaryTwoAssets;
  entries: Swnz_HeroTabStorySummaryTwoEntries;
  resources: Swnz_HeroTabStorySummaryTwoResources;
};

export type Swnz_HeroTabStorySummaryTwoResources = {
  __typename?: 'SWNZ_HeroTabStorySummaryTwoResources';
  block: Array<Swnz_HeroTabStorySummaryTwoResourcesBlock>;
  hyperlink: Array<Swnz_HeroTabStorySummaryTwoResourcesHyperlink>;
  inline: Array<Swnz_HeroTabStorySummaryTwoResourcesInline>;
};

export type Swnz_HeroTabStorySummaryTwoResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_HeroTabStorySummaryTwoResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_HeroTabStorySummaryTwoResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_HeroTabStorySummaryTwoResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_HeroTabStorySummaryTwoResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_HeroTabStorySummaryTwoResourcesInline';
  sys: Swnz_ResourceSys;
};

/** Cards to display on Homepage Panel [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepageCard) */
export type Swnz_HomepageCard = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_HomepageCard';
  _id: Scalars['ID']['output'];
  bodyText?: Maybe<Swnz_HomepageCardBodyText>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  icon?: Maybe<Swnz_MediaImage>;
  identifier?: Maybe<Scalars['String']['output']>;
  isNew?: Maybe<Scalars['Boolean']['output']>;
  linkedFrom?: Maybe<Swnz_HomepageCardLinkingCollections>;
  primaryCta?: Maybe<Swnz_CallToAction>;
  subtitle?: Maybe<Scalars['String']['output']>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** Cards to display on Homepage Panel [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepageCard) */
export type Swnz_HomepageCardBodyTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Cards to display on Homepage Panel [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepageCard) */
export type Swnz_HomepageCardIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_MediaImageFilter>;
};


/** Cards to display on Homepage Panel [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepageCard) */
export type Swnz_HomepageCardIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Cards to display on Homepage Panel [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepageCard) */
export type Swnz_HomepageCardIsNewArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Cards to display on Homepage Panel [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepageCard) */
export type Swnz_HomepageCardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Cards to display on Homepage Panel [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepageCard) */
export type Swnz_HomepageCardPrimaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** Cards to display on Homepage Panel [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepageCard) */
export type Swnz_HomepageCardSubtitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Cards to display on Homepage Panel [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepageCard) */
export type Swnz_HomepageCardTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_HomepageCardBodyText = {
  __typename?: 'SWNZ_HomepageCardBodyText';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_HomepageCardBodyTextLinks;
};

export type Swnz_HomepageCardBodyTextAssets = {
  __typename?: 'SWNZ_HomepageCardBodyTextAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_HomepageCardBodyTextEntries = {
  __typename?: 'SWNZ_HomepageCardBodyTextEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_HomepageCardBodyTextLinks = {
  __typename?: 'SWNZ_HomepageCardBodyTextLinks';
  assets: Swnz_HomepageCardBodyTextAssets;
  entries: Swnz_HomepageCardBodyTextEntries;
  resources: Swnz_HomepageCardBodyTextResources;
};

export type Swnz_HomepageCardBodyTextResources = {
  __typename?: 'SWNZ_HomepageCardBodyTextResources';
  block: Array<Swnz_HomepageCardBodyTextResourcesBlock>;
  hyperlink: Array<Swnz_HomepageCardBodyTextResourcesHyperlink>;
  inline: Array<Swnz_HomepageCardBodyTextResourcesInline>;
};

export type Swnz_HomepageCardBodyTextResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_HomepageCardBodyTextResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_HomepageCardBodyTextResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_HomepageCardBodyTextResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_HomepageCardBodyTextResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_HomepageCardBodyTextResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_HomepageCardCollection = {
  __typename?: 'SWNZ_HomepageCardCollection';
  items: Array<Maybe<Swnz_HomepageCard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_HomepageCardFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_HomepageCardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_HomepageCardFilter>>>;
  bodyText_contains?: InputMaybe<Scalars['String']['input']>;
  bodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  icon?: InputMaybe<Swnz_CfMediaImageNestedFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isNew?: InputMaybe<Scalars['Boolean']['input']>;
  isNew_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isNew_not?: InputMaybe<Scalars['Boolean']['input']>;
  primaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  primaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  subtitle_contains?: InputMaybe<Scalars['String']['input']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subtitle_not?: InputMaybe<Scalars['String']['input']>;
  subtitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_HomepageCardLinkingCollections = {
  __typename?: 'SWNZ_HomepageCardLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  homepagePanelCollection?: Maybe<Swnz_HomepagePanelCollection>;
};


export type Swnz_HomepageCardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_HomepageCardLinkingCollectionsHomepagePanelCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_HomepageCardLinkingCollectionsHomepagePanelCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_HomepageCardLinkingCollectionsHomepagePanelCollectionOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  PreHeadingAsc = 'preHeading_ASC',
  PreHeadingDesc = 'preHeading_DESC',
  SubHeadingAsc = 'subHeading_ASC',
  SubHeadingDesc = 'subHeading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_HomepageCardOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  IsNewAsc = 'isNew_ASC',
  IsNewDesc = 'isNew_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Content Block with Text / Cards on the home page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepagePanel) */
export type Swnz_HomepagePanel = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_HomepagePanel';
  _id: Scalars['ID']['output'];
  backgroundColour?: Maybe<Scalars['String']['output']>;
  codeId?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  footerText?: Maybe<Swnz_HomepagePanelFooterText>;
  heading?: Maybe<Scalars['String']['output']>;
  homepageCardsCollection?: Maybe<Swnz_HomepagePanelHomepageCardsCollection>;
  identifier?: Maybe<Scalars['String']['output']>;
  introText?: Maybe<Swnz_HomepagePanelIntroText>;
  linkedFrom?: Maybe<Swnz_HomepagePanelLinkingCollections>;
  preHeading?: Maybe<Scalars['String']['output']>;
  primaryCta?: Maybe<Swnz_CallToAction>;
  secondaryCta?: Maybe<Swnz_CallToAction>;
  subHeading?: Maybe<Scalars['String']['output']>;
  sys: Swnz_Sys;
};


/** Content Block with Text / Cards on the home page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepagePanel) */
export type Swnz_HomepagePanelBackgroundColourArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Content Block with Text / Cards on the home page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepagePanel) */
export type Swnz_HomepagePanelCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Content Block with Text / Cards on the home page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepagePanel) */
export type Swnz_HomepagePanelFooterTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Content Block with Text / Cards on the home page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepagePanel) */
export type Swnz_HomepagePanelHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Content Block with Text / Cards on the home page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepagePanel) */
export type Swnz_HomepagePanelHomepageCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_HomepagePanelHomepageCardsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_HomepageCardFilter>;
};


/** Content Block with Text / Cards on the home page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepagePanel) */
export type Swnz_HomepagePanelIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Content Block with Text / Cards on the home page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepagePanel) */
export type Swnz_HomepagePanelIntroTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Content Block with Text / Cards on the home page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepagePanel) */
export type Swnz_HomepagePanelLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Content Block with Text / Cards on the home page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepagePanel) */
export type Swnz_HomepagePanelPreHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Content Block with Text / Cards on the home page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepagePanel) */
export type Swnz_HomepagePanelPrimaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** Content Block with Text / Cards on the home page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepagePanel) */
export type Swnz_HomepagePanelSecondaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** Content Block with Text / Cards on the home page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepagePanel) */
export type Swnz_HomepagePanelSubHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_HomepagePanelCollection = {
  __typename?: 'SWNZ_HomepagePanelCollection';
  items: Array<Maybe<Swnz_HomepagePanel>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_HomepagePanelFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_HomepagePanelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_HomepagePanelFilter>>>;
  backgroundColour?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColour_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  backgroundColour_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  footerText_contains?: InputMaybe<Scalars['String']['input']>;
  footerText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  footerText_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading?: InputMaybe<Scalars['String']['input']>;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading_not?: InputMaybe<Scalars['String']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  homepageCards?: InputMaybe<Swnz_CfHomepageCardNestedFilter>;
  homepageCardsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introText_contains?: InputMaybe<Scalars['String']['input']>;
  introText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  introText_not_contains?: InputMaybe<Scalars['String']['input']>;
  preHeading?: InputMaybe<Scalars['String']['input']>;
  preHeading_contains?: InputMaybe<Scalars['String']['input']>;
  preHeading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  preHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preHeading_not?: InputMaybe<Scalars['String']['input']>;
  preHeading_not_contains?: InputMaybe<Scalars['String']['input']>;
  preHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  primaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  primaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  secondaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subHeading?: InputMaybe<Scalars['String']['input']>;
  subHeading_contains?: InputMaybe<Scalars['String']['input']>;
  subHeading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subHeading_not?: InputMaybe<Scalars['String']['input']>;
  subHeading_not_contains?: InputMaybe<Scalars['String']['input']>;
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_HomepagePanelFooterText = {
  __typename?: 'SWNZ_HomepagePanelFooterText';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_HomepagePanelFooterTextLinks;
};

export type Swnz_HomepagePanelFooterTextAssets = {
  __typename?: 'SWNZ_HomepagePanelFooterTextAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_HomepagePanelFooterTextEntries = {
  __typename?: 'SWNZ_HomepagePanelFooterTextEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_HomepagePanelFooterTextLinks = {
  __typename?: 'SWNZ_HomepagePanelFooterTextLinks';
  assets: Swnz_HomepagePanelFooterTextAssets;
  entries: Swnz_HomepagePanelFooterTextEntries;
  resources: Swnz_HomepagePanelFooterTextResources;
};

export type Swnz_HomepagePanelFooterTextResources = {
  __typename?: 'SWNZ_HomepagePanelFooterTextResources';
  block: Array<Swnz_HomepagePanelFooterTextResourcesBlock>;
  hyperlink: Array<Swnz_HomepagePanelFooterTextResourcesHyperlink>;
  inline: Array<Swnz_HomepagePanelFooterTextResourcesInline>;
};

export type Swnz_HomepagePanelFooterTextResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_HomepagePanelFooterTextResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_HomepagePanelFooterTextResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_HomepagePanelFooterTextResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_HomepagePanelFooterTextResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_HomepagePanelFooterTextResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_HomepagePanelHomepageCardsCollection = {
  __typename?: 'SWNZ_HomepagePanelHomepageCardsCollection';
  items: Array<Maybe<Swnz_HomepageCard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_HomepagePanelHomepageCardsCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  IsNewAsc = 'isNew_ASC',
  IsNewDesc = 'isNew_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Swnz_HomepagePanelIntroText = {
  __typename?: 'SWNZ_HomepagePanelIntroText';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_HomepagePanelIntroTextLinks;
};

export type Swnz_HomepagePanelIntroTextAssets = {
  __typename?: 'SWNZ_HomepagePanelIntroTextAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_HomepagePanelIntroTextEntries = {
  __typename?: 'SWNZ_HomepagePanelIntroTextEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_HomepagePanelIntroTextLinks = {
  __typename?: 'SWNZ_HomepagePanelIntroTextLinks';
  assets: Swnz_HomepagePanelIntroTextAssets;
  entries: Swnz_HomepagePanelIntroTextEntries;
  resources: Swnz_HomepagePanelIntroTextResources;
};

export type Swnz_HomepagePanelIntroTextResources = {
  __typename?: 'SWNZ_HomepagePanelIntroTextResources';
  block: Array<Swnz_HomepagePanelIntroTextResourcesBlock>;
  hyperlink: Array<Swnz_HomepagePanelIntroTextResourcesHyperlink>;
  inline: Array<Swnz_HomepagePanelIntroTextResourcesInline>;
};

export type Swnz_HomepagePanelIntroTextResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_HomepagePanelIntroTextResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_HomepagePanelIntroTextResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_HomepagePanelIntroTextResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_HomepagePanelIntroTextResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_HomepagePanelIntroTextResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_HomepagePanelLinkingCollections = {
  __typename?: 'SWNZ_HomepagePanelLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  pageHomepageCollection?: Maybe<Swnz_PageHomepageCollection>;
};


export type Swnz_HomepagePanelLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_HomepagePanelLinkingCollectionsPageHomepageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_HomepagePanelLinkingCollectionsPageHomepageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_HomepagePanelLinkingCollectionsPageHomepageCollectionOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  HeroHeadingAsc = 'heroHeading_ASC',
  HeroHeadingDesc = 'heroHeading_DESC',
  HeroJumpTextAsc = 'heroJumpText_ASC',
  HeroJumpTextDesc = 'heroJumpText_DESC',
  HeroPreHeadingAsc = 'heroPreHeading_ASC',
  HeroPreHeadingDesc = 'heroPreHeading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_HomepagePanelOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  PreHeadingAsc = 'preHeading_ASC',
  PreHeadingDesc = 'preHeading_DESC',
  SubHeadingAsc = 'subHeading_ASC',
  SubHeadingDesc = 'subHeading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Panel containing Story Cards [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepageStoryPanel) */
export type Swnz_HomepageStoryPanel = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_HomepageStoryPanel';
  _id: Scalars['ID']['output'];
  backgroundColour?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_HomepageStoryPanelLinkingCollections>;
  storyCardCarouselCollection?: Maybe<Swnz_HomepageStoryPanelStoryCardCarouselCollection>;
  sys: Swnz_Sys;
  tagHeading?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};


/** Panel containing Story Cards [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepageStoryPanel) */
export type Swnz_HomepageStoryPanelBackgroundColourArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Panel containing Story Cards [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepageStoryPanel) */
export type Swnz_HomepageStoryPanelIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Panel containing Story Cards [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepageStoryPanel) */
export type Swnz_HomepageStoryPanelLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Panel containing Story Cards [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepageStoryPanel) */
export type Swnz_HomepageStoryPanelStoryCardCarouselCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_HomepageStoryPanelStoryCardCarouselCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_StoryCardFilter>;
};


/** Panel containing Story Cards [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepageStoryPanel) */
export type Swnz_HomepageStoryPanelTagHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Panel containing Story Cards [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/homepageStoryPanel) */
export type Swnz_HomepageStoryPanelTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_HomepageStoryPanelCollection = {
  __typename?: 'SWNZ_HomepageStoryPanelCollection';
  items: Array<Maybe<Swnz_HomepageStoryPanel>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_HomepageStoryPanelFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_HomepageStoryPanelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_HomepageStoryPanelFilter>>>;
  backgroundColour?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColour_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  backgroundColour_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  storyCardCarousel?: InputMaybe<Swnz_CfStoryCardNestedFilter>;
  storyCardCarouselCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  tagHeading?: InputMaybe<Scalars['String']['input']>;
  tagHeading_contains?: InputMaybe<Scalars['String']['input']>;
  tagHeading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tagHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tagHeading_not?: InputMaybe<Scalars['String']['input']>;
  tagHeading_not_contains?: InputMaybe<Scalars['String']['input']>;
  tagHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_HomepageStoryPanelLinkingCollections = {
  __typename?: 'SWNZ_HomepageStoryPanelLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  pageHomepageCollection?: Maybe<Swnz_PageHomepageCollection>;
};


export type Swnz_HomepageStoryPanelLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_HomepageStoryPanelLinkingCollectionsPageHomepageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_HomepageStoryPanelLinkingCollectionsPageHomepageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_HomepageStoryPanelLinkingCollectionsPageHomepageCollectionOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  HeroHeadingAsc = 'heroHeading_ASC',
  HeroHeadingDesc = 'heroHeading_DESC',
  HeroJumpTextAsc = 'heroJumpText_ASC',
  HeroJumpTextDesc = 'heroJumpText_DESC',
  HeroPreHeadingAsc = 'heroPreHeading_ASC',
  HeroPreHeadingDesc = 'heroPreHeading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_HomepageStoryPanelOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagHeadingAsc = 'tagHeading_ASC',
  TagHeadingDesc = 'tagHeading_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Swnz_HomepageStoryPanelStoryCardCarouselCollection = {
  __typename?: 'SWNZ_HomepageStoryPanelStoryCardCarouselCollection';
  items: Array<Maybe<Swnz_StoryCard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_HomepageStoryPanelStoryCardCarouselCollectionOrder {
  AreaOfStudyAsc = 'areaOfStudy_ASC',
  AreaOfStudyDesc = 'areaOfStudy_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LocationAsc = 'location_ASC',
  LocationDesc = 'location_DESC',
  StudentNameAsc = 'studentName_ASC',
  StudentNameDesc = 'studentName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** CTA icon buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/iconCallToActions) */
export type Swnz_IconCallToActions = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_IconCallToActions';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  icon?: Maybe<Swnz_MediaImage>;
  identifier?: Maybe<Scalars['String']['output']>;
  internalLink?: Maybe<Swnz_Page>;
  label?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_IconCallToActionsLinkingCollections>;
  openInNewWindow?: Maybe<Scalars['Boolean']['output']>;
  sys: Swnz_Sys;
  urlLink?: Maybe<Scalars['String']['output']>;
};


/** CTA icon buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/iconCallToActions) */
export type Swnz_IconCallToActionsIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_MediaImageFilter>;
};


/** CTA icon buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/iconCallToActions) */
export type Swnz_IconCallToActionsIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** CTA icon buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/iconCallToActions) */
export type Swnz_IconCallToActionsInternalLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_PageFilter>;
};


/** CTA icon buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/iconCallToActions) */
export type Swnz_IconCallToActionsLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** CTA icon buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/iconCallToActions) */
export type Swnz_IconCallToActionsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** CTA icon buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/iconCallToActions) */
export type Swnz_IconCallToActionsOpenInNewWindowArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** CTA icon buttons with internal or external links [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/iconCallToActions) */
export type Swnz_IconCallToActionsUrlLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_IconCallToActionsCollection = {
  __typename?: 'SWNZ_IconCallToActionsCollection';
  items: Array<Maybe<Swnz_IconCallToActions>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_IconCallToActionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_IconCallToActionsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_IconCallToActionsFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  icon?: InputMaybe<Swnz_CfMediaImageNestedFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  internalLink?: InputMaybe<Swnz_CfPageNestedFilter>;
  internalLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  openInNewWindow?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewWindow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewWindow_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  urlLink?: InputMaybe<Scalars['String']['input']>;
  urlLink_contains?: InputMaybe<Scalars['String']['input']>;
  urlLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  urlLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  urlLink_not?: InputMaybe<Scalars['String']['input']>;
  urlLink_not_contains?: InputMaybe<Scalars['String']['input']>;
  urlLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_IconCallToActionsLinkingCollections = {
  __typename?: 'SWNZ_IconCallToActionsLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_IconCallToActionsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_IconCallToActionsOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  OpenInNewWindowAsc = 'openInNewWindow_ASC',
  OpenInNewWindowDesc = 'openInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlLinkAsc = 'urlLink_ASC',
  UrlLinkDesc = 'urlLink_DESC'
}

export enum Swnz_ImageFormat {
  Avif = 'AVIF',
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP'
}

export enum Swnz_ImageResizeFocus {
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT'
}

export enum Swnz_ImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB'
}

export type Swnz_ImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: InputMaybe<Scalars['SWNZ_HexColor']['input']>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: InputMaybe<Scalars['Int']['input']>;
  /** Desired image format. Defaults to the original image format. */
  format?: InputMaybe<Swnz_ImageFormat>;
  /** Desired height in pixels. Defaults to the original image height. */
  height?: InputMaybe<Scalars['SWNZ_Dimension']['input']>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: InputMaybe<Scalars['SWNZ_Quality']['input']>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: InputMaybe<Swnz_ImageResizeFocus>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: InputMaybe<Swnz_ImageResizeStrategy>;
  /** Desired width in pixels. Defaults to the original image width. */
  width?: InputMaybe<Scalars['SWNZ_Dimension']['input']>;
};

/** Quote or impact statement [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/impactText) */
export type Swnz_ImpactText = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_ImpactText';
  _id: Scalars['ID']['output'];
  backgroundColour?: Maybe<Scalars['String']['output']>;
  bodyText?: Maybe<Swnz_ImpactTextBodyText>;
  codeId?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  description?: Maybe<Scalars['String']['output']>;
  displayAsQuote?: Maybe<Scalars['Boolean']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_ImpactTextLinkingCollections>;
  primaryCta?: Maybe<Swnz_CallToAction>;
  secondaryCta?: Maybe<Swnz_CallToAction>;
  sys: Swnz_Sys;
  textAlignment?: Maybe<Scalars['String']['output']>;
  textColour?: Maybe<Scalars['String']['output']>;
};


/** Quote or impact statement [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/impactText) */
export type Swnz_ImpactTextBackgroundColourArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Quote or impact statement [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/impactText) */
export type Swnz_ImpactTextBodyTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Quote or impact statement [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/impactText) */
export type Swnz_ImpactTextCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Quote or impact statement [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/impactText) */
export type Swnz_ImpactTextDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Quote or impact statement [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/impactText) */
export type Swnz_ImpactTextDisplayAsQuoteArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Quote or impact statement [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/impactText) */
export type Swnz_ImpactTextIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Quote or impact statement [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/impactText) */
export type Swnz_ImpactTextLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Quote or impact statement [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/impactText) */
export type Swnz_ImpactTextPrimaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** Quote or impact statement [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/impactText) */
export type Swnz_ImpactTextSecondaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** Quote or impact statement [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/impactText) */
export type Swnz_ImpactTextTextAlignmentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Quote or impact statement [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/impactText) */
export type Swnz_ImpactTextTextColourArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_ImpactTextBodyText = {
  __typename?: 'SWNZ_ImpactTextBodyText';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_ImpactTextBodyTextLinks;
};

export type Swnz_ImpactTextBodyTextAssets = {
  __typename?: 'SWNZ_ImpactTextBodyTextAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_ImpactTextBodyTextEntries = {
  __typename?: 'SWNZ_ImpactTextBodyTextEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_ImpactTextBodyTextLinks = {
  __typename?: 'SWNZ_ImpactTextBodyTextLinks';
  assets: Swnz_ImpactTextBodyTextAssets;
  entries: Swnz_ImpactTextBodyTextEntries;
  resources: Swnz_ImpactTextBodyTextResources;
};

export type Swnz_ImpactTextBodyTextResources = {
  __typename?: 'SWNZ_ImpactTextBodyTextResources';
  block: Array<Swnz_ImpactTextBodyTextResourcesBlock>;
  hyperlink: Array<Swnz_ImpactTextBodyTextResourcesHyperlink>;
  inline: Array<Swnz_ImpactTextBodyTextResourcesInline>;
};

export type Swnz_ImpactTextBodyTextResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_ImpactTextBodyTextResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_ImpactTextBodyTextResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_ImpactTextBodyTextResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_ImpactTextBodyTextResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_ImpactTextBodyTextResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_ImpactTextCollection = {
  __typename?: 'SWNZ_ImpactTextCollection';
  items: Array<Maybe<Swnz_ImpactText>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_ImpactTextFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_ImpactTextFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_ImpactTextFilter>>>;
  backgroundColour?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColour_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  backgroundColour_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bodyText_contains?: InputMaybe<Scalars['String']['input']>;
  bodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayAsQuote?: InputMaybe<Scalars['Boolean']['input']>;
  displayAsQuote_exists?: InputMaybe<Scalars['Boolean']['input']>;
  displayAsQuote_not?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  primaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  primaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  secondaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  textAlignment?: InputMaybe<Scalars['String']['input']>;
  textAlignment_contains?: InputMaybe<Scalars['String']['input']>;
  textAlignment_exists?: InputMaybe<Scalars['Boolean']['input']>;
  textAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  textAlignment_not?: InputMaybe<Scalars['String']['input']>;
  textAlignment_not_contains?: InputMaybe<Scalars['String']['input']>;
  textAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  textColour?: InputMaybe<Scalars['String']['input']>;
  textColour_contains?: InputMaybe<Scalars['String']['input']>;
  textColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  textColour_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  textColour_not?: InputMaybe<Scalars['String']['input']>;
  textColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  textColour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_ImpactTextLinkingCollections = {
  __typename?: 'SWNZ_ImpactTextLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageModalCollection?: Maybe<Swnz_PageModalCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_ImpactTextLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_ImpactTextLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_ImpactTextLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_ImpactTextLinkingCollectionsPageModalCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_ImpactTextLinkingCollectionsPageModalCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_ImpactTextLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_ImpactTextLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_ImpactTextLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_ImpactTextLinkingCollectionsPageModalCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_ImpactTextLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_ImpactTextOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DisplayAsQuoteAsc = 'displayAsQuote_ASC',
  DisplayAsQuoteDesc = 'displayAsQuote_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignmentAsc = 'textAlignment_ASC',
  TextAlignmentDesc = 'textAlignment_DESC',
  TextColourAsc = 'textColour_ASC',
  TextColourDesc = 'textColour_DESC'
}

/** An information box to display inside the Generic Text rich text [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/infoBox) */
export type Swnz_InfoBox = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_InfoBox';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  infoText?: Maybe<Swnz_InfoBoxInfoText>;
  linkedFrom?: Maybe<Swnz_InfoBoxLinkingCollections>;
  sys: Swnz_Sys;
};


/** An information box to display inside the Generic Text rich text [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/infoBox) */
export type Swnz_InfoBoxIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** An information box to display inside the Generic Text rich text [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/infoBox) */
export type Swnz_InfoBoxInfoTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** An information box to display inside the Generic Text rich text [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/infoBox) */
export type Swnz_InfoBoxLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_InfoBoxCollection = {
  __typename?: 'SWNZ_InfoBoxCollection';
  items: Array<Maybe<Swnz_InfoBox>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_InfoBoxFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_InfoBoxFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_InfoBoxFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  infoText_contains?: InputMaybe<Scalars['String']['input']>;
  infoText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  infoText_not_contains?: InputMaybe<Scalars['String']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_InfoBoxInfoText = {
  __typename?: 'SWNZ_InfoBoxInfoText';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_InfoBoxInfoTextLinks;
};

export type Swnz_InfoBoxInfoTextAssets = {
  __typename?: 'SWNZ_InfoBoxInfoTextAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_InfoBoxInfoTextEntries = {
  __typename?: 'SWNZ_InfoBoxInfoTextEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_InfoBoxInfoTextLinks = {
  __typename?: 'SWNZ_InfoBoxInfoTextLinks';
  assets: Swnz_InfoBoxInfoTextAssets;
  entries: Swnz_InfoBoxInfoTextEntries;
  resources: Swnz_InfoBoxInfoTextResources;
};

export type Swnz_InfoBoxInfoTextResources = {
  __typename?: 'SWNZ_InfoBoxInfoTextResources';
  block: Array<Swnz_InfoBoxInfoTextResourcesBlock>;
  hyperlink: Array<Swnz_InfoBoxInfoTextResourcesHyperlink>;
  inline: Array<Swnz_InfoBoxInfoTextResourcesInline>;
};

export type Swnz_InfoBoxInfoTextResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_InfoBoxInfoTextResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_InfoBoxInfoTextResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_InfoBoxInfoTextResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_InfoBoxInfoTextResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_InfoBoxInfoTextResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_InfoBoxLinkingCollections = {
  __typename?: 'SWNZ_InfoBoxLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_InfoBoxLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_InfoBoxOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Shows intro text with a Jump menu if jump ahead links exists in the page. [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/introTextWithJumpMenu) */
export type Swnz_IntroTextWithJumpMenu = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_IntroTextWithJumpMenu';
  _id: Scalars['ID']['output'];
  bodyText?: Maybe<Swnz_IntroTextWithJumpMenuBodyText>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_IntroTextWithJumpMenuLinkingCollections>;
  primaryCta?: Maybe<Swnz_CallToAction>;
  sys: Swnz_Sys;
};


/** Shows intro text with a Jump menu if jump ahead links exists in the page. [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/introTextWithJumpMenu) */
export type Swnz_IntroTextWithJumpMenuBodyTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Shows intro text with a Jump menu if jump ahead links exists in the page. [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/introTextWithJumpMenu) */
export type Swnz_IntroTextWithJumpMenuIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Shows intro text with a Jump menu if jump ahead links exists in the page. [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/introTextWithJumpMenu) */
export type Swnz_IntroTextWithJumpMenuLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Shows intro text with a Jump menu if jump ahead links exists in the page. [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/introTextWithJumpMenu) */
export type Swnz_IntroTextWithJumpMenuPrimaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};

export type Swnz_IntroTextWithJumpMenuBodyText = {
  __typename?: 'SWNZ_IntroTextWithJumpMenuBodyText';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_IntroTextWithJumpMenuBodyTextLinks;
};

export type Swnz_IntroTextWithJumpMenuBodyTextAssets = {
  __typename?: 'SWNZ_IntroTextWithJumpMenuBodyTextAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_IntroTextWithJumpMenuBodyTextEntries = {
  __typename?: 'SWNZ_IntroTextWithJumpMenuBodyTextEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_IntroTextWithJumpMenuBodyTextLinks = {
  __typename?: 'SWNZ_IntroTextWithJumpMenuBodyTextLinks';
  assets: Swnz_IntroTextWithJumpMenuBodyTextAssets;
  entries: Swnz_IntroTextWithJumpMenuBodyTextEntries;
  resources: Swnz_IntroTextWithJumpMenuBodyTextResources;
};

export type Swnz_IntroTextWithJumpMenuBodyTextResources = {
  __typename?: 'SWNZ_IntroTextWithJumpMenuBodyTextResources';
  block: Array<Swnz_IntroTextWithJumpMenuBodyTextResourcesBlock>;
  hyperlink: Array<Swnz_IntroTextWithJumpMenuBodyTextResourcesHyperlink>;
  inline: Array<Swnz_IntroTextWithJumpMenuBodyTextResourcesInline>;
};

export type Swnz_IntroTextWithJumpMenuBodyTextResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_IntroTextWithJumpMenuBodyTextResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_IntroTextWithJumpMenuBodyTextResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_IntroTextWithJumpMenuBodyTextResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_IntroTextWithJumpMenuBodyTextResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_IntroTextWithJumpMenuBodyTextResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_IntroTextWithJumpMenuCollection = {
  __typename?: 'SWNZ_IntroTextWithJumpMenuCollection';
  items: Array<Maybe<Swnz_IntroTextWithJumpMenu>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_IntroTextWithJumpMenuFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_IntroTextWithJumpMenuFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_IntroTextWithJumpMenuFilter>>>;
  bodyText_contains?: InputMaybe<Scalars['String']['input']>;
  bodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  primaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  primaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_IntroTextWithJumpMenuLinkingCollections = {
  __typename?: 'SWNZ_IntroTextWithJumpMenuLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_IntroTextWithJumpMenuLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_IntroTextWithJumpMenuLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_IntroTextWithJumpMenuLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_IntroTextWithJumpMenuLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_IntroTextWithJumpMenuLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_IntroTextWithJumpMenuLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_IntroTextWithJumpMenuLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_IntroTextWithJumpMenuOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Inventory course. Hold extra content for a course. [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/invCourse) */
export type Swnz_InvCourse = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_InvCourse';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  courseId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Swnz_InvCourseDescription>;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_InvCourseLinkingCollections>;
  summary?: Maybe<Scalars['String']['output']>;
  sys: Swnz_Sys;
};


/** Inventory course. Hold extra content for a course. [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/invCourse) */
export type Swnz_InvCourseCourseIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Inventory course. Hold extra content for a course. [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/invCourse) */
export type Swnz_InvCourseDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Inventory course. Hold extra content for a course. [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/invCourse) */
export type Swnz_InvCourseIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Inventory course. Hold extra content for a course. [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/invCourse) */
export type Swnz_InvCourseLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Inventory course. Hold extra content for a course. [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/invCourse) */
export type Swnz_InvCourseSummaryArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_InvCourseCollection = {
  __typename?: 'SWNZ_InvCourseCollection';
  items: Array<Maybe<Swnz_InvCourse>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_InvCourseDescription = {
  __typename?: 'SWNZ_InvCourseDescription';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_InvCourseDescriptionLinks;
};

export type Swnz_InvCourseDescriptionAssets = {
  __typename?: 'SWNZ_InvCourseDescriptionAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_InvCourseDescriptionEntries = {
  __typename?: 'SWNZ_InvCourseDescriptionEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_InvCourseDescriptionLinks = {
  __typename?: 'SWNZ_InvCourseDescriptionLinks';
  assets: Swnz_InvCourseDescriptionAssets;
  entries: Swnz_InvCourseDescriptionEntries;
  resources: Swnz_InvCourseDescriptionResources;
};

export type Swnz_InvCourseDescriptionResources = {
  __typename?: 'SWNZ_InvCourseDescriptionResources';
  block: Array<Swnz_InvCourseDescriptionResourcesBlock>;
  hyperlink: Array<Swnz_InvCourseDescriptionResourcesHyperlink>;
  inline: Array<Swnz_InvCourseDescriptionResourcesInline>;
};

export type Swnz_InvCourseDescriptionResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_InvCourseDescriptionResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_InvCourseDescriptionResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_InvCourseDescriptionResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_InvCourseDescriptionResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_InvCourseDescriptionResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_InvCourseFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_InvCourseFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_InvCourseFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  courseId?: InputMaybe<Scalars['String']['input']>;
  courseId_contains?: InputMaybe<Scalars['String']['input']>;
  courseId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  courseId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  courseId_not?: InputMaybe<Scalars['String']['input']>;
  courseId_not_contains?: InputMaybe<Scalars['String']['input']>;
  courseId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  summary?: InputMaybe<Scalars['String']['input']>;
  summary_contains?: InputMaybe<Scalars['String']['input']>;
  summary_exists?: InputMaybe<Scalars['Boolean']['input']>;
  summary_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  summary_not?: InputMaybe<Scalars['String']['input']>;
  summary_not_contains?: InputMaybe<Scalars['String']['input']>;
  summary_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_InvCourseLinkingCollections = {
  __typename?: 'SWNZ_InvCourseLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_InvCourseLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_InvCourseOrder {
  CourseIdAsc = 'courseId_ASC',
  CourseIdDesc = 'courseId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Institution. Hold extra content for an Institution. [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/invInstitution) */
export type Swnz_InvInstitution = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_InvInstitution';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  imageCarouselCollection?: Maybe<Swnz_InvInstitutionImageCarouselCollection>;
  institutionId?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_InvInstitutionLinkingCollections>;
  logo?: Maybe<Swnz_Asset>;
  sys: Swnz_Sys;
};


/** Institution. Hold extra content for an Institution. [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/invInstitution) */
export type Swnz_InvInstitutionIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Institution. Hold extra content for an Institution. [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/invInstitution) */
export type Swnz_InvInstitutionImageCarouselCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_InvInstitutionImageCarouselFilter>;
};


/** Institution. Hold extra content for an Institution. [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/invInstitution) */
export type Swnz_InvInstitutionInstitutionIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Institution. Hold extra content for an Institution. [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/invInstitution) */
export type Swnz_InvInstitutionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Institution. Hold extra content for an Institution. [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/invInstitution) */
export type Swnz_InvInstitutionLogoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Swnz_InvInstitutionCollection = {
  __typename?: 'SWNZ_InvInstitutionCollection';
  items: Array<Maybe<Swnz_InvInstitution>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_InvInstitutionFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_InvInstitutionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_InvInstitutionFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  imageCarousel?: InputMaybe<Swnz_CfimageCarouselMultiTypeNestedFilter>;
  imageCarouselCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  institutionId?: InputMaybe<Scalars['String']['input']>;
  institutionId_contains?: InputMaybe<Scalars['String']['input']>;
  institutionId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  institutionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  institutionId_not?: InputMaybe<Scalars['String']['input']>;
  institutionId_not_contains?: InputMaybe<Scalars['String']['input']>;
  institutionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  logo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_InvInstitutionImageCarouselCollection = {
  __typename?: 'SWNZ_InvInstitutionImageCarouselCollection';
  items: Array<Maybe<Swnz_InvInstitutionImageCarouselItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_InvInstitutionImageCarouselFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_InvInstitutionImageCarouselFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_InvInstitutionImageCarouselFilter>>>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_contains?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_not?: InputMaybe<Scalars['String']['input']>;
  caption_not_contains?: InputMaybe<Scalars['String']['input']>;
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_InvInstitutionImageCarouselItem = Swnz_MediaImage | Swnz_MediaVideo;

export type Swnz_InvInstitutionLinkingCollections = {
  __typename?: 'SWNZ_InvInstitutionLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_InvInstitutionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_InvInstitutionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  InstitutionIdAsc = 'institutionId_ASC',
  InstitutionIdDesc = 'institutionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** An anchor link, to allow jumping to different locations within the page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/jumpAhead) */
export type Swnz_JumpAhead = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_JumpAhead';
  _id: Scalars['ID']['output'];
  anchorName?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  displayInMenu?: Maybe<Scalars['Boolean']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_JumpAheadLinkingCollections>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** An anchor link, to allow jumping to different locations within the page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/jumpAhead) */
export type Swnz_JumpAheadAnchorNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** An anchor link, to allow jumping to different locations within the page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/jumpAhead) */
export type Swnz_JumpAheadDisplayInMenuArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** An anchor link, to allow jumping to different locations within the page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/jumpAhead) */
export type Swnz_JumpAheadIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** An anchor link, to allow jumping to different locations within the page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/jumpAhead) */
export type Swnz_JumpAheadLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** An anchor link, to allow jumping to different locations within the page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/jumpAhead) */
export type Swnz_JumpAheadTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_JumpAheadCollection = {
  __typename?: 'SWNZ_JumpAheadCollection';
  items: Array<Maybe<Swnz_JumpAhead>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_JumpAheadFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_JumpAheadFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_JumpAheadFilter>>>;
  anchorName?: InputMaybe<Scalars['String']['input']>;
  anchorName_contains?: InputMaybe<Scalars['String']['input']>;
  anchorName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  anchorName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  anchorName_not?: InputMaybe<Scalars['String']['input']>;
  anchorName_not_contains?: InputMaybe<Scalars['String']['input']>;
  anchorName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  displayInMenu?: InputMaybe<Scalars['Boolean']['input']>;
  displayInMenu_exists?: InputMaybe<Scalars['Boolean']['input']>;
  displayInMenu_not?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_JumpAheadLinkingCollections = {
  __typename?: 'SWNZ_JumpAheadLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_JumpAheadLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_JumpAheadLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_JumpAheadLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_JumpAheadLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_JumpAheadLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_JumpAheadLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_JumpAheadLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_JumpAheadOrder {
  AnchorNameAsc = 'anchorName_ASC',
  AnchorNameDesc = 'anchorName_DESC',
  DisplayInMenuAsc = 'displayInMenu_ASC',
  DisplayInMenuDesc = 'displayInMenu_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/keepExploring) */
export type Swnz_KeepExploring = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_KeepExploring';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  ctaLinksCollection?: Maybe<Swnz_KeepExploringCtaLinksCollection>;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_KeepExploringLinkingCollections>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/keepExploring) */
export type Swnz_KeepExploringCtaLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_KeepExploringCtaLinksCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/keepExploring) */
export type Swnz_KeepExploringIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/keepExploring) */
export type Swnz_KeepExploringLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/keepExploring) */
export type Swnz_KeepExploringTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_KeepExploringCollection = {
  __typename?: 'SWNZ_KeepExploringCollection';
  items: Array<Maybe<Swnz_KeepExploring>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_KeepExploringCtaLinksCollection = {
  __typename?: 'SWNZ_KeepExploringCtaLinksCollection';
  items: Array<Maybe<Swnz_CallToAction>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_KeepExploringCtaLinksCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  ModalIdAsc = 'modalId_ASC',
  ModalIdDesc = 'modalId_DESC',
  OpenInNewWindowAsc = 'openInNewWindow_ASC',
  OpenInNewWindowDesc = 'openInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlLinkAsc = 'urlLink_ASC',
  UrlLinkDesc = 'urlLink_DESC'
}

export type Swnz_KeepExploringFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_KeepExploringFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_KeepExploringFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  ctaLinks?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  ctaLinksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_KeepExploringLinkingCollections = {
  __typename?: 'SWNZ_KeepExploringLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageSearchResultsCollection?: Maybe<Swnz_PageSearchResultsCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_KeepExploringLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_KeepExploringLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_KeepExploringLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_KeepExploringLinkingCollectionsPageSearchResultsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_KeepExploringLinkingCollectionsPageSearchResultsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_KeepExploringLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_KeepExploringLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_KeepExploringLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_KeepExploringLinkingCollectionsPageSearchResultsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_KeepExploringLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_KeepExploringOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Container to display map and all referenced regions [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mapContainer) */
export type Swnz_MapContainer = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_MapContainer';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_MapContainerLinkingCollections>;
  pagesCollection?: Maybe<Swnz_MapContainerPagesCollection>;
  sys: Swnz_Sys;
};


/** Container to display map and all referenced regions [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mapContainer) */
export type Swnz_MapContainerIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display map and all referenced regions [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mapContainer) */
export type Swnz_MapContainerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Container to display map and all referenced regions [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mapContainer) */
export type Swnz_MapContainerPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MapContainerPagesCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_NaumaiPageStandardFilter>;
};

export type Swnz_MapContainerCollection = {
  __typename?: 'SWNZ_MapContainerCollection';
  items: Array<Maybe<Swnz_MapContainer>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_MapContainerFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_MapContainerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_MapContainerFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pages?: InputMaybe<Swnz_CfNaumaiPageStandardNestedFilter>;
  pagesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_MapContainerLinkingCollections = {
  __typename?: 'SWNZ_MapContainerLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
};


export type Swnz_MapContainerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MapContainerLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MapContainerLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_MapContainerLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_MapContainerOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Swnz_MapContainerPagesCollection = {
  __typename?: 'SWNZ_MapContainerPagesCollection';
  items: Array<Maybe<Swnz_NaumaiPageStandard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_MapContainerPagesCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Holds an Audio file OR a link to an audio file [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaAudio) */
export type Swnz_MediaAudio = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_MediaAudio';
  _id: Scalars['ID']['output'];
  audio?: Maybe<Swnz_Asset>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_MediaAudioLinkingCollections>;
  sourceUrl?: Maybe<Scalars['String']['output']>;
  sys: Swnz_Sys;
};


/** Holds an Audio file OR a link to an audio file [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaAudio) */
export type Swnz_MediaAudioAudioArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Holds an Audio file OR a link to an audio file [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaAudio) */
export type Swnz_MediaAudioIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Holds an Audio file OR a link to an audio file [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaAudio) */
export type Swnz_MediaAudioLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Holds an Audio file OR a link to an audio file [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaAudio) */
export type Swnz_MediaAudioSourceUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_MediaAudioCollection = {
  __typename?: 'SWNZ_MediaAudioCollection';
  items: Array<Maybe<Swnz_MediaAudio>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_MediaAudioFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_MediaAudioFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_MediaAudioFilter>>>;
  audio_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sourceUrl?: InputMaybe<Scalars['String']['input']>;
  sourceUrl_contains?: InputMaybe<Scalars['String']['input']>;
  sourceUrl_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sourceUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sourceUrl_not?: InputMaybe<Scalars['String']['input']>;
  sourceUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  sourceUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_MediaAudioLinkingCollections = {
  __typename?: 'SWNZ_MediaAudioLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  richMediaCollection?: Maybe<Swnz_RichMediaCollection>;
};


export type Swnz_MediaAudioLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaAudioLinkingCollectionsRichMediaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaAudioLinkingCollectionsRichMediaCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_MediaAudioLinkingCollectionsRichMediaCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TileSubTitleAsc = 'tileSubTitle_ASC',
  TileSubTitleDesc = 'tileSubTitle_DESC'
}

export enum Swnz_MediaAudioOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SourceUrlAsc = 'sourceUrl_ASC',
  SourceUrlDesc = 'sourceUrl_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaIcon) */
export type Swnz_MediaIcon = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_MediaIcon';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_MediaIconLinkingCollections>;
  source?: Maybe<Swnz_Asset>;
  sys: Swnz_Sys;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaIcon) */
export type Swnz_MediaIconIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaIcon) */
export type Swnz_MediaIconLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaIcon) */
export type Swnz_MediaIconSourceArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Swnz_MediaIconCollection = {
  __typename?: 'SWNZ_MediaIconCollection';
  items: Array<Maybe<Swnz_MediaIcon>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_MediaIconFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_MediaIconFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_MediaIconFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  source_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_MediaIconLinkingCollections = {
  __typename?: 'SWNZ_MediaIconLinkingCollections';
  bulletPointCollection?: Maybe<Swnz_BulletPointCollection>;
  emotionItemCollection?: Maybe<Swnz_EmotionItemCollection>;
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_MediaIconLinkingCollectionsBulletPointCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaIconLinkingCollectionsBulletPointCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaIconLinkingCollectionsEmotionItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaIconLinkingCollectionsEmotionItemCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaIconLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_MediaIconLinkingCollectionsBulletPointCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_MediaIconLinkingCollectionsEmotionItemCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_MediaIconOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Holds an image [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaImage) */
export type Swnz_MediaImage = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_MediaImage';
  _id: Scalars['ID']['output'];
  caption?: Maybe<Scalars['String']['output']>;
  codeId?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  isZoomable?: Maybe<Scalars['Boolean']['output']>;
  linkedFrom?: Maybe<Swnz_MediaImageLinkingCollections>;
  source?: Maybe<Swnz_Asset>;
  sys: Swnz_Sys;
};


/** Holds an image [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaImage) */
export type Swnz_MediaImageCaptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Holds an image [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaImage) */
export type Swnz_MediaImageCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Holds an image [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaImage) */
export type Swnz_MediaImageIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Holds an image [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaImage) */
export type Swnz_MediaImageIsZoomableArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Holds an image [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaImage) */
export type Swnz_MediaImageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Holds an image [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaImage) */
export type Swnz_MediaImageSourceArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Swnz_MediaImageCollection = {
  __typename?: 'SWNZ_MediaImageCollection';
  items: Array<Maybe<Swnz_MediaImage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_MediaImageFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_MediaImageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_MediaImageFilter>>>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_contains?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_not?: InputMaybe<Scalars['String']['input']>;
  caption_not_contains?: InputMaybe<Scalars['String']['input']>;
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isZoomable?: InputMaybe<Scalars['Boolean']['input']>;
  isZoomable_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isZoomable_not?: InputMaybe<Scalars['Boolean']['input']>;
  source_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_MediaImageLinkingCollections = {
  __typename?: 'SWNZ_MediaImageLinkingCollections';
  alternatingContentCollection?: Maybe<Swnz_AlternatingContentCollection>;
  alternatingQuoteCollection?: Maybe<Swnz_AlternatingQuoteCollection>;
  blogCardWithLinkCollection?: Maybe<Swnz_BlogCardWithLinkCollection>;
  blogPostCollection?: Maybe<Swnz_BlogPostCollection>;
  cardWithLinkCollection?: Maybe<Swnz_CardWithLinkCollection>;
  cardWithModalCollection?: Maybe<Swnz_CardWithModalCollection>;
  entryCollection?: Maybe<Swnz_EntryCollection>;
  galleryCarouselCollection?: Maybe<Swnz_GalleryCarouselCollection>;
  heroCollection?: Maybe<Swnz_HeroCollection>;
  heroFeaturedCollection?: Maybe<Swnz_HeroFeaturedCollection>;
  heroTabCollection?: Maybe<Swnz_HeroTabCollection>;
  homepageCardCollection?: Maybe<Swnz_HomepageCardCollection>;
  iconCallToActionsCollection?: Maybe<Swnz_IconCallToActionsCollection>;
  invInstitutionCollection?: Maybe<Swnz_InvInstitutionCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageHomepageCollection?: Maybe<Swnz_PageHomepageCollection>;
  pageModalCollection?: Maybe<Swnz_PageModalCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
  promoCollection?: Maybe<Swnz_PromoCollection>;
  richMediaCollection?: Maybe<Swnz_RichMediaCollection>;
  storyCardCollection?: Maybe<Swnz_StoryCardCollection>;
  tileCollection?: Maybe<Swnz_TileCollection>;
};


export type Swnz_MediaImageLinkingCollectionsAlternatingContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsAlternatingContentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsAlternatingQuoteCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsAlternatingQuoteCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsBlogCardWithLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsBlogCardWithLinkCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsBlogPostCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsBlogPostCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsCardWithLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsCardWithLinkCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsCardWithModalCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsCardWithModalCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsGalleryCarouselCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsGalleryCarouselCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsHeroCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsHeroFeaturedCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsHeroFeaturedCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsHeroTabCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsHeroTabCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsHomepageCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsHomepageCardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsIconCallToActionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsIconCallToActionsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsInvInstitutionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsInvInstitutionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsPageHomepageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsPageHomepageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsPageModalCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsPageModalCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsPromoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsPromoCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsRichMediaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsRichMediaCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsStoryCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsStoryCardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaImageLinkingCollectionsTileCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaImageLinkingCollectionsTileCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_MediaImageLinkingCollectionsAlternatingContentCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  MediaOrientationAsc = 'mediaOrientation_ASC',
  MediaOrientationDesc = 'mediaOrientation_DESC',
  MediaPlacementAsc = 'mediaPlacement_ASC',
  MediaPlacementDesc = 'mediaPlacement_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_MediaImageLinkingCollectionsAlternatingQuoteCollectionOrder {
  CreditSuffixAsc = 'creditSuffix_ASC',
  CreditSuffixDesc = 'creditSuffix_DESC',
  CreditAsc = 'credit_ASC',
  CreditDesc = 'credit_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  MediaOrientationAsc = 'mediaOrientation_ASC',
  MediaOrientationDesc = 'mediaOrientation_DESC',
  MediaPlacementAsc = 'mediaPlacement_ASC',
  MediaPlacementDesc = 'mediaPlacement_DESC',
  QuoteTextColourAsc = 'quoteTextColour_ASC',
  QuoteTextColourDesc = 'quoteTextColour_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_MediaImageLinkingCollectionsBlogCardWithLinkCollectionOrder {
  CustomUrlAsc = 'customUrl_ASC',
  CustomUrlDesc = 'customUrl_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_MediaImageLinkingCollectionsBlogPostCollectionOrder {
  FeaturedBlogPositionAsc = 'featuredBlogPosition_ASC',
  FeaturedBlogPositionDesc = 'featuredBlogPosition_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LastUpdatedDateAsc = 'lastUpdatedDate_ASC',
  LastUpdatedDateDesc = 'lastUpdatedDate_DESC',
  NoFollowAsc = 'no_follow_ASC',
  NoFollowDesc = 'no_follow_DESC',
  NoIndexAsc = 'no_index_ASC',
  NoIndexDesc = 'no_index_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SeoTitleAsc = 'seoTitle_ASC',
  SeoTitleDesc = 'seoTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagBrandPillarsAsc = 'tagBrandPillars_ASC',
  TagBrandPillarsDesc = 'tagBrandPillars_DESC',
  TagCampaignAsc = 'tagCampaign_ASC',
  TagCampaignDesc = 'tagCampaign_DESC',
  TagLearnerJourneyStageAsc = 'tagLearnerJourneyStage_ASC',
  TagLearnerJourneyStageDesc = 'tagLearnerJourneyStage_DESC',
  TagTopicAsc = 'tagTopic_ASC',
  TagTopicDesc = 'tagTopic_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_MediaImageLinkingCollectionsCardWithLinkCollectionOrder {
  CustomUrlAsc = 'customUrl_ASC',
  CustomUrlDesc = 'customUrl_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_MediaImageLinkingCollectionsCardWithModalCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_MediaImageLinkingCollectionsGalleryCarouselCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_MediaImageLinkingCollectionsHeroCollectionOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  DisplayTypeAsc = 'displayType_ASC',
  DisplayTypeDesc = 'displayType_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_MediaImageLinkingCollectionsHeroFeaturedCollectionOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SubHeadingAsc = 'subHeading_ASC',
  SubHeadingDesc = 'subHeading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextThemeColourAsc = 'textThemeColour_ASC',
  TextThemeColourDesc = 'textThemeColour_DESC'
}

export enum Swnz_MediaImageLinkingCollectionsHeroTabCollectionOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  QuoteAuthorAsc = 'quoteAuthor_ASC',
  QuoteAuthorDesc = 'quoteAuthor_DESC',
  QuoteStudyLocationAsc = 'quoteStudyLocation_ASC',
  QuoteStudyLocationDesc = 'quoteStudyLocation_DESC',
  QuoteTextAsc = 'quoteText_ASC',
  QuoteTextDesc = 'quoteText_DESC',
  StoryTitleAsc = 'storyTitle_ASC',
  StoryTitleDesc = 'storyTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TabHeadingAsc = 'tabHeading_ASC',
  TabHeadingDesc = 'tabHeading_DESC',
  TabSubHeadingAsc = 'tabSubHeading_ASC',
  TabSubHeadingDesc = 'tabSubHeading_DESC'
}

export enum Swnz_MediaImageLinkingCollectionsHomepageCardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  IsNewAsc = 'isNew_ASC',
  IsNewDesc = 'isNew_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_MediaImageLinkingCollectionsIconCallToActionsCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  OpenInNewWindowAsc = 'openInNewWindow_ASC',
  OpenInNewWindowDesc = 'openInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlLinkAsc = 'urlLink_ASC',
  UrlLinkDesc = 'urlLink_DESC'
}

export enum Swnz_MediaImageLinkingCollectionsInvInstitutionCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  InstitutionIdAsc = 'institutionId_ASC',
  InstitutionIdDesc = 'institutionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_MediaImageLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_MediaImageLinkingCollectionsPageHomepageCollectionOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  HeroHeadingAsc = 'heroHeading_ASC',
  HeroHeadingDesc = 'heroHeading_DESC',
  HeroJumpTextAsc = 'heroJumpText_ASC',
  HeroJumpTextDesc = 'heroJumpText_DESC',
  HeroPreHeadingAsc = 'heroPreHeading_ASC',
  HeroPreHeadingDesc = 'heroPreHeading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_MediaImageLinkingCollectionsPageModalCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_MediaImageLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_MediaImageLinkingCollectionsPromoCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  ShowFilterCtaAsc = 'showFilterCta_ASC',
  ShowFilterCtaDesc = 'showFilterCta_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_MediaImageLinkingCollectionsRichMediaCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TileSubTitleAsc = 'tileSubTitle_ASC',
  TileSubTitleDesc = 'tileSubTitle_DESC'
}

export enum Swnz_MediaImageLinkingCollectionsStoryCardCollectionOrder {
  AreaOfStudyAsc = 'areaOfStudy_ASC',
  AreaOfStudyDesc = 'areaOfStudy_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LocationAsc = 'location_ASC',
  LocationDesc = 'location_DESC',
  StudentNameAsc = 'studentName_ASC',
  StudentNameDesc = 'studentName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_MediaImageLinkingCollectionsTileCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_MediaImageOrder {
  CaptionAsc = 'caption_ASC',
  CaptionDesc = 'caption_DESC',
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  IsZoomableAsc = 'isZoomable_ASC',
  IsZoomableDesc = 'isZoomable_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Holds a Video URL, for youtube etc [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaVideo) */
export type Swnz_MediaVideo = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_MediaVideo';
  _id: Scalars['ID']['output'];
  caption?: Maybe<Scalars['String']['output']>;
  codeId?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_MediaVideoLinkingCollections>;
  sourceUrl?: Maybe<Scalars['String']['output']>;
  sys: Swnz_Sys;
  thumbnail?: Maybe<Swnz_Asset>;
};


/** Holds a Video URL, for youtube etc [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaVideo) */
export type Swnz_MediaVideoCaptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Holds a Video URL, for youtube etc [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaVideo) */
export type Swnz_MediaVideoCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Holds a Video URL, for youtube etc [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaVideo) */
export type Swnz_MediaVideoIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Holds a Video URL, for youtube etc [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaVideo) */
export type Swnz_MediaVideoLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Holds a Video URL, for youtube etc [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaVideo) */
export type Swnz_MediaVideoSourceUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Holds a Video URL, for youtube etc [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/mediaVideo) */
export type Swnz_MediaVideoThumbnailArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Swnz_MediaVideoCollection = {
  __typename?: 'SWNZ_MediaVideoCollection';
  items: Array<Maybe<Swnz_MediaVideo>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_MediaVideoFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_MediaVideoFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_MediaVideoFilter>>>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_contains?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_not?: InputMaybe<Scalars['String']['input']>;
  caption_not_contains?: InputMaybe<Scalars['String']['input']>;
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sourceUrl?: InputMaybe<Scalars['String']['input']>;
  sourceUrl_contains?: InputMaybe<Scalars['String']['input']>;
  sourceUrl_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sourceUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sourceUrl_not?: InputMaybe<Scalars['String']['input']>;
  sourceUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  sourceUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  thumbnail_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Swnz_MediaVideoLinkingCollections = {
  __typename?: 'SWNZ_MediaVideoLinkingCollections';
  alternatingContentCollection?: Maybe<Swnz_AlternatingContentCollection>;
  blogPostCollection?: Maybe<Swnz_BlogPostCollection>;
  entryCollection?: Maybe<Swnz_EntryCollection>;
  galleryCarouselCollection?: Maybe<Swnz_GalleryCarouselCollection>;
  heroCollection?: Maybe<Swnz_HeroCollection>;
  heroFeaturedCollection?: Maybe<Swnz_HeroFeaturedCollection>;
  heroFeaturedFloatingCardCollection?: Maybe<Swnz_HeroFeaturedFloatingCardCollection>;
  invInstitutionCollection?: Maybe<Swnz_InvInstitutionCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageModalCollection?: Maybe<Swnz_PageModalCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
  richMediaCollection?: Maybe<Swnz_RichMediaCollection>;
};


export type Swnz_MediaVideoLinkingCollectionsAlternatingContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaVideoLinkingCollectionsAlternatingContentCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaVideoLinkingCollectionsBlogPostCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaVideoLinkingCollectionsBlogPostCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaVideoLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaVideoLinkingCollectionsGalleryCarouselCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaVideoLinkingCollectionsGalleryCarouselCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaVideoLinkingCollectionsHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaVideoLinkingCollectionsHeroCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaVideoLinkingCollectionsHeroFeaturedCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaVideoLinkingCollectionsHeroFeaturedCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaVideoLinkingCollectionsHeroFeaturedFloatingCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaVideoLinkingCollectionsHeroFeaturedFloatingCardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaVideoLinkingCollectionsInvInstitutionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaVideoLinkingCollectionsInvInstitutionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaVideoLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaVideoLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaVideoLinkingCollectionsPageModalCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaVideoLinkingCollectionsPageModalCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaVideoLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaVideoLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_MediaVideoLinkingCollectionsRichMediaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_MediaVideoLinkingCollectionsRichMediaCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_MediaVideoLinkingCollectionsAlternatingContentCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  MediaOrientationAsc = 'mediaOrientation_ASC',
  MediaOrientationDesc = 'mediaOrientation_DESC',
  MediaPlacementAsc = 'mediaPlacement_ASC',
  MediaPlacementDesc = 'mediaPlacement_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_MediaVideoLinkingCollectionsBlogPostCollectionOrder {
  FeaturedBlogPositionAsc = 'featuredBlogPosition_ASC',
  FeaturedBlogPositionDesc = 'featuredBlogPosition_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LastUpdatedDateAsc = 'lastUpdatedDate_ASC',
  LastUpdatedDateDesc = 'lastUpdatedDate_DESC',
  NoFollowAsc = 'no_follow_ASC',
  NoFollowDesc = 'no_follow_DESC',
  NoIndexAsc = 'no_index_ASC',
  NoIndexDesc = 'no_index_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SeoTitleAsc = 'seoTitle_ASC',
  SeoTitleDesc = 'seoTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagBrandPillarsAsc = 'tagBrandPillars_ASC',
  TagBrandPillarsDesc = 'tagBrandPillars_DESC',
  TagCampaignAsc = 'tagCampaign_ASC',
  TagCampaignDesc = 'tagCampaign_DESC',
  TagLearnerJourneyStageAsc = 'tagLearnerJourneyStage_ASC',
  TagLearnerJourneyStageDesc = 'tagLearnerJourneyStage_DESC',
  TagTopicAsc = 'tagTopic_ASC',
  TagTopicDesc = 'tagTopic_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_MediaVideoLinkingCollectionsGalleryCarouselCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_MediaVideoLinkingCollectionsHeroCollectionOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  DisplayTypeAsc = 'displayType_ASC',
  DisplayTypeDesc = 'displayType_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_MediaVideoLinkingCollectionsHeroFeaturedCollectionOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SubHeadingAsc = 'subHeading_ASC',
  SubHeadingDesc = 'subHeading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextThemeColourAsc = 'textThemeColour_ASC',
  TextThemeColourDesc = 'textThemeColour_DESC'
}

export enum Swnz_MediaVideoLinkingCollectionsHeroFeaturedFloatingCardCollectionOrder {
  CardHeadingAsc = 'cardHeading_ASC',
  CardHeadingDesc = 'cardHeading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_MediaVideoLinkingCollectionsInvInstitutionCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  InstitutionIdAsc = 'institutionId_ASC',
  InstitutionIdDesc = 'institutionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_MediaVideoLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_MediaVideoLinkingCollectionsPageModalCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_MediaVideoLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_MediaVideoLinkingCollectionsRichMediaCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TileSubTitleAsc = 'tileSubTitle_ASC',
  TileSubTitleDesc = 'tileSubTitle_DESC'
}

export enum Swnz_MediaVideoOrder {
  CaptionAsc = 'caption_ASC',
  CaptionDesc = 'caption_DESC',
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SourceUrlAsc = 'sourceUrl_ASC',
  SourceUrlDesc = 'sourceUrl_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Meta data to store the state of content model through migrations [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/migration) */
export type Swnz_Migration = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_Migration';
  _id: Scalars['ID']['output'];
  contentTypeId?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  linkedFrom?: Maybe<Swnz_MigrationLinkingCollections>;
  state?: Maybe<Scalars['SWNZ_JSON']['output']>;
  sys: Swnz_Sys;
};


/** Meta data to store the state of content model through migrations [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/migration) */
export type Swnz_MigrationContentTypeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Meta data to store the state of content model through migrations [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/migration) */
export type Swnz_MigrationLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Meta data to store the state of content model through migrations [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/migration) */
export type Swnz_MigrationStateArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_MigrationCollection = {
  __typename?: 'SWNZ_MigrationCollection';
  items: Array<Maybe<Swnz_Migration>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_MigrationFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_MigrationFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_MigrationFilter>>>;
  contentTypeId?: InputMaybe<Scalars['String']['input']>;
  contentTypeId_contains?: InputMaybe<Scalars['String']['input']>;
  contentTypeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentTypeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentTypeId_not?: InputMaybe<Scalars['String']['input']>;
  contentTypeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentTypeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  state_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_MigrationLinkingCollections = {
  __typename?: 'SWNZ_MigrationLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_MigrationLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_MigrationOrder {
  ContentTypeIdAsc = 'contentTypeId_ASC',
  ContentTypeIdDesc = 'contentTypeId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** The standard page for NauMai [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/naumaiPageStandard) */
export type Swnz_NaumaiPageStandard = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_NaumaiPageStandard';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  hero?: Maybe<Swnz_NaumaiPageStandardHero>;
  identifier?: Maybe<Scalars['String']['output']>;
  intro?: Maybe<Swnz_NaumaiPageStandardIntro>;
  linkedFrom?: Maybe<Swnz_NaumaiPageStandardLinkingCollections>;
  parentPage?: Maybe<Swnz_NaumaiPageStandard>;
  region?: Maybe<Scalars['String']['output']>;
  sectionsCollection?: Maybe<Swnz_NaumaiPageStandardSectionsCollection>;
  seo?: Maybe<Swnz_Seo>;
  slug?: Maybe<Scalars['String']['output']>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** The standard page for NauMai [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/naumaiPageStandard) */
export type Swnz_NaumaiPageStandardHeroArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** The standard page for NauMai [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/naumaiPageStandard) */
export type Swnz_NaumaiPageStandardIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The standard page for NauMai [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/naumaiPageStandard) */
export type Swnz_NaumaiPageStandardIntroArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** The standard page for NauMai [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/naumaiPageStandard) */
export type Swnz_NaumaiPageStandardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** The standard page for NauMai [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/naumaiPageStandard) */
export type Swnz_NaumaiPageStandardParentPageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_NaumaiPageStandardFilter>;
};


/** The standard page for NauMai [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/naumaiPageStandard) */
export type Swnz_NaumaiPageStandardRegionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The standard page for NauMai [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/naumaiPageStandard) */
export type Swnz_NaumaiPageStandardSectionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_NaumaiPageStandardSectionsFilter>;
};


/** The standard page for NauMai [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/naumaiPageStandard) */
export type Swnz_NaumaiPageStandardSeoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_SeoFilter>;
};


/** The standard page for NauMai [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/naumaiPageStandard) */
export type Swnz_NaumaiPageStandardSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The standard page for NauMai [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/naumaiPageStandard) */
export type Swnz_NaumaiPageStandardTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_NaumaiPageStandardCollection = {
  __typename?: 'SWNZ_NaumaiPageStandardCollection';
  items: Array<Maybe<Swnz_NaumaiPageStandard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_NaumaiPageStandardFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_NaumaiPageStandardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_NaumaiPageStandardFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  hero_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  intro_exists?: InputMaybe<Scalars['Boolean']['input']>;
  parentPage?: InputMaybe<Swnz_CfNaumaiPageStandardNestedFilter>;
  parentPage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  region_contains?: InputMaybe<Scalars['String']['input']>;
  region_exists?: InputMaybe<Scalars['Boolean']['input']>;
  region_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  region_not?: InputMaybe<Scalars['String']['input']>;
  region_not_contains?: InputMaybe<Scalars['String']['input']>;
  region_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sections?: InputMaybe<Swnz_CfsectionsMultiTypeNestedFilter>;
  sectionsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  seo?: InputMaybe<Swnz_CfSeoNestedFilter>;
  seo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_NaumaiPageStandardHero = Swnz_Hero | Swnz_HeroFeatured;

export type Swnz_NaumaiPageStandardIntro = Swnz_GenericText | Swnz_IntroTextWithJumpMenu;

export type Swnz_NaumaiPageStandardLinkingCollections = {
  __typename?: 'SWNZ_NaumaiPageStandardLinkingCollections';
  callToActionCollection?: Maybe<Swnz_CallToActionCollection>;
  cardWithLinkCollection?: Maybe<Swnz_CardWithLinkCollection>;
  entryCollection?: Maybe<Swnz_EntryCollection>;
  heroFeaturedFloatingCardCollection?: Maybe<Swnz_HeroFeaturedFloatingCardCollection>;
  mapContainerCollection?: Maybe<Swnz_MapContainerCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  navigationElementCollection?: Maybe<Swnz_NavigationElementCollection>;
};


export type Swnz_NaumaiPageStandardLinkingCollectionsCallToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_NaumaiPageStandardLinkingCollectionsCallToActionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_NaumaiPageStandardLinkingCollectionsCardWithLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_NaumaiPageStandardLinkingCollectionsCardWithLinkCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_NaumaiPageStandardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_NaumaiPageStandardLinkingCollectionsHeroFeaturedFloatingCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_NaumaiPageStandardLinkingCollectionsHeroFeaturedFloatingCardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_NaumaiPageStandardLinkingCollectionsMapContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_NaumaiPageStandardLinkingCollectionsMapContainerCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_NaumaiPageStandardLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_NaumaiPageStandardLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_NaumaiPageStandardLinkingCollectionsNavigationElementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_NaumaiPageStandardLinkingCollectionsNavigationElementCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_NaumaiPageStandardLinkingCollectionsCallToActionCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  ModalIdAsc = 'modalId_ASC',
  ModalIdDesc = 'modalId_DESC',
  OpenInNewWindowAsc = 'openInNewWindow_ASC',
  OpenInNewWindowDesc = 'openInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlLinkAsc = 'urlLink_ASC',
  UrlLinkDesc = 'urlLink_DESC'
}

export enum Swnz_NaumaiPageStandardLinkingCollectionsCardWithLinkCollectionOrder {
  CustomUrlAsc = 'customUrl_ASC',
  CustomUrlDesc = 'customUrl_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_NaumaiPageStandardLinkingCollectionsHeroFeaturedFloatingCardCollectionOrder {
  CardHeadingAsc = 'cardHeading_ASC',
  CardHeadingDesc = 'cardHeading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_NaumaiPageStandardLinkingCollectionsMapContainerCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_NaumaiPageStandardLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_NaumaiPageStandardLinkingCollectionsNavigationElementCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  CustomUrlAsc = 'customUrl_ASC',
  CustomUrlDesc = 'customUrl_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LinkTypeAsc = 'linkType_ASC',
  LinkTypeDesc = 'linkType_DESC',
  OpenLinkInNewWindowAsc = 'openLinkInNewWindow_ASC',
  OpenLinkInNewWindowDesc = 'openLinkInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_NaumaiPageStandardOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Swnz_NaumaiPageStandardSectionsCollection = {
  __typename?: 'SWNZ_NaumaiPageStandardSectionsCollection';
  items: Array<Maybe<Swnz_NaumaiPageStandardSectionsItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_NaumaiPageStandardSectionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_NaumaiPageStandardSectionsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_NaumaiPageStandardSectionsFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_NaumaiPageStandardSectionsItem = Swnz_AccordionContainer | Swnz_AlternatingContent | Swnz_AlternatingQuote | Swnz_Banner | Swnz_Blockquote | Swnz_BulletPointContainer | Swnz_CardContainer | Swnz_ComponentStatic | Swnz_EmotionContainer | Swnz_GalleryCarousel | Swnz_GenericText | Swnz_ImpactText | Swnz_JumpAhead | Swnz_KeepExploring | Swnz_MapContainer | Swnz_MediaImage | Swnz_MediaVideo | Swnz_NextSteps | Swnz_TileContainer;

/** This will hold a navigation element / link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/navigation) */
export type Swnz_Navigation = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_Navigation';
  _id: Scalars['ID']['output'];
  codeId?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_NavigationLinkingCollections>;
  navigationElementsCollection?: Maybe<Swnz_NavigationNavigationElementsCollection>;
  sys: Swnz_Sys;
};


/** This will hold a navigation element / link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/navigation) */
export type Swnz_NavigationCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** This will hold a navigation element / link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/navigation) */
export type Swnz_NavigationIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** This will hold a navigation element / link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/navigation) */
export type Swnz_NavigationLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** This will hold a navigation element / link [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/navigation) */
export type Swnz_NavigationNavigationElementsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_NavigationNavigationElementsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_NavigationElementFilter>;
};

export type Swnz_NavigationCollection = {
  __typename?: 'SWNZ_NavigationCollection';
  items: Array<Maybe<Swnz_Navigation>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** A Navigation Element / Link to a page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/navigationElement) */
export type Swnz_NavigationElement = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_NavigationElement';
  _id: Scalars['ID']['output'];
  codeId?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  customUrl?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  internalPageLink?: Maybe<Swnz_NavigationElementInternalPageLink>;
  linkType?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_NavigationElementLinkingCollections>;
  navigationElementsCollection?: Maybe<Swnz_NavigationElementNavigationElementsCollection>;
  openLinkInNewWindow?: Maybe<Scalars['Boolean']['output']>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** A Navigation Element / Link to a page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/navigationElement) */
export type Swnz_NavigationElementCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A Navigation Element / Link to a page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/navigationElement) */
export type Swnz_NavigationElementCustomUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A Navigation Element / Link to a page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/navigationElement) */
export type Swnz_NavigationElementIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A Navigation Element / Link to a page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/navigationElement) */
export type Swnz_NavigationElementInternalPageLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** A Navigation Element / Link to a page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/navigationElement) */
export type Swnz_NavigationElementLinkTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A Navigation Element / Link to a page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/navigationElement) */
export type Swnz_NavigationElementLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** A Navigation Element / Link to a page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/navigationElement) */
export type Swnz_NavigationElementNavigationElementsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_NavigationElementNavigationElementsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_NavigationElementFilter>;
};


/** A Navigation Element / Link to a page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/navigationElement) */
export type Swnz_NavigationElementOpenLinkInNewWindowArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A Navigation Element / Link to a page [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/navigationElement) */
export type Swnz_NavigationElementTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_NavigationElementCollection = {
  __typename?: 'SWNZ_NavigationElementCollection';
  items: Array<Maybe<Swnz_NavigationElement>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_NavigationElementFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_NavigationElementFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_NavigationElementFilter>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  customUrl?: InputMaybe<Scalars['String']['input']>;
  customUrl_contains?: InputMaybe<Scalars['String']['input']>;
  customUrl_exists?: InputMaybe<Scalars['Boolean']['input']>;
  customUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  customUrl_not?: InputMaybe<Scalars['String']['input']>;
  customUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  customUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  internalPageLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  linkType?: InputMaybe<Scalars['String']['input']>;
  linkType_contains?: InputMaybe<Scalars['String']['input']>;
  linkType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  linkType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  linkType_not?: InputMaybe<Scalars['String']['input']>;
  linkType_not_contains?: InputMaybe<Scalars['String']['input']>;
  linkType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navigationElements?: InputMaybe<Swnz_CfNavigationElementNestedFilter>;
  navigationElementsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openLinkInNewWindow?: InputMaybe<Scalars['Boolean']['input']>;
  openLinkInNewWindow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openLinkInNewWindow_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_NavigationElementInternalPageLink = Swnz_NaumaiPageStandard | Swnz_Page;

export type Swnz_NavigationElementLinkingCollections = {
  __typename?: 'SWNZ_NavigationElementLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  navigationCollection?: Maybe<Swnz_NavigationCollection>;
  navigationElementCollection?: Maybe<Swnz_NavigationElementCollection>;
};


export type Swnz_NavigationElementLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_NavigationElementLinkingCollectionsNavigationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_NavigationElementLinkingCollectionsNavigationCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_NavigationElementLinkingCollectionsNavigationElementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_NavigationElementLinkingCollectionsNavigationElementCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_NavigationElementLinkingCollectionsNavigationCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_NavigationElementLinkingCollectionsNavigationElementCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  CustomUrlAsc = 'customUrl_ASC',
  CustomUrlDesc = 'customUrl_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LinkTypeAsc = 'linkType_ASC',
  LinkTypeDesc = 'linkType_DESC',
  OpenLinkInNewWindowAsc = 'openLinkInNewWindow_ASC',
  OpenLinkInNewWindowDesc = 'openLinkInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Swnz_NavigationElementNavigationElementsCollection = {
  __typename?: 'SWNZ_NavigationElementNavigationElementsCollection';
  items: Array<Maybe<Swnz_NavigationElement>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_NavigationElementNavigationElementsCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  CustomUrlAsc = 'customUrl_ASC',
  CustomUrlDesc = 'customUrl_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LinkTypeAsc = 'linkType_ASC',
  LinkTypeDesc = 'linkType_DESC',
  OpenLinkInNewWindowAsc = 'openLinkInNewWindow_ASC',
  OpenLinkInNewWindowDesc = 'openLinkInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_NavigationElementOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  CustomUrlAsc = 'customUrl_ASC',
  CustomUrlDesc = 'customUrl_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LinkTypeAsc = 'linkType_ASC',
  LinkTypeDesc = 'linkType_DESC',
  OpenLinkInNewWindowAsc = 'openLinkInNewWindow_ASC',
  OpenLinkInNewWindowDesc = 'openLinkInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Swnz_NavigationFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_NavigationFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_NavigationFilter>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navigationElements?: InputMaybe<Swnz_CfNavigationElementNestedFilter>;
  navigationElementsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_NavigationLinkingCollections = {
  __typename?: 'SWNZ_NavigationLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_NavigationLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type Swnz_NavigationNavigationElementsCollection = {
  __typename?: 'SWNZ_NavigationNavigationElementsCollection';
  items: Array<Maybe<Swnz_NavigationElement>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_NavigationNavigationElementsCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  CustomUrlAsc = 'customUrl_ASC',
  CustomUrlDesc = 'customUrl_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LinkTypeAsc = 'linkType_ASC',
  LinkTypeDesc = 'linkType_DESC',
  OpenLinkInNewWindowAsc = 'openLinkInNewWindow_ASC',
  OpenLinkInNewWindowDesc = 'openLinkInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_NavigationOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** NextSteps section containing multiple NextStepsItems [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/nextSteps) */
export type Swnz_NextSteps = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_NextSteps';
  _id: Scalars['ID']['output'];
  codeId?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  introText?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_NextStepsLinkingCollections>;
  nextStepsItemsCollection?: Maybe<Swnz_NextStepsNextStepsItemsCollection>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** NextSteps section containing multiple NextStepsItems [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/nextSteps) */
export type Swnz_NextStepsCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** NextSteps section containing multiple NextStepsItems [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/nextSteps) */
export type Swnz_NextStepsIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** NextSteps section containing multiple NextStepsItems [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/nextSteps) */
export type Swnz_NextStepsIntroTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** NextSteps section containing multiple NextStepsItems [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/nextSteps) */
export type Swnz_NextStepsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** NextSteps section containing multiple NextStepsItems [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/nextSteps) */
export type Swnz_NextStepsNextStepsItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_NextStepsNextStepsItemsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_NextStepsItemFilter>;
};


/** NextSteps section containing multiple NextStepsItems [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/nextSteps) */
export type Swnz_NextStepsTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_NextStepsCollection = {
  __typename?: 'SWNZ_NextStepsCollection';
  items: Array<Maybe<Swnz_NextSteps>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_NextStepsFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_NextStepsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_NextStepsFilter>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introText?: InputMaybe<Scalars['String']['input']>;
  introText_contains?: InputMaybe<Scalars['String']['input']>;
  introText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  introText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introText_not?: InputMaybe<Scalars['String']['input']>;
  introText_not_contains?: InputMaybe<Scalars['String']['input']>;
  introText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nextStepsItems?: InputMaybe<Swnz_CfNextStepsItemNestedFilter>;
  nextStepsItemsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** A single option for the NextSteps section [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/nextStepsItem) */
export type Swnz_NextStepsItem = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_NextStepsItem';
  _id: Scalars['ID']['output'];
  codeId?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  description?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_NextStepsItemLinkingCollections>;
  primaryCta?: Maybe<Swnz_CallToAction>;
  secondaryCta?: Maybe<Swnz_CallToAction>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** A single option for the NextSteps section [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/nextStepsItem) */
export type Swnz_NextStepsItemCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A single option for the NextSteps section [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/nextStepsItem) */
export type Swnz_NextStepsItemDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A single option for the NextSteps section [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/nextStepsItem) */
export type Swnz_NextStepsItemIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A single option for the NextSteps section [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/nextStepsItem) */
export type Swnz_NextStepsItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** A single option for the NextSteps section [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/nextStepsItem) */
export type Swnz_NextStepsItemPrimaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** A single option for the NextSteps section [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/nextStepsItem) */
export type Swnz_NextStepsItemSecondaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** A single option for the NextSteps section [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/nextStepsItem) */
export type Swnz_NextStepsItemTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_NextStepsItemCollection = {
  __typename?: 'SWNZ_NextStepsItemCollection';
  items: Array<Maybe<Swnz_NextStepsItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_NextStepsItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_NextStepsItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_NextStepsItemFilter>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  primaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  primaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  secondaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_NextStepsItemLinkingCollections = {
  __typename?: 'SWNZ_NextStepsItemLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  nextStepsCollection?: Maybe<Swnz_NextStepsCollection>;
};


export type Swnz_NextStepsItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_NextStepsItemLinkingCollectionsNextStepsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_NextStepsItemLinkingCollectionsNextStepsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_NextStepsItemLinkingCollectionsNextStepsCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_NextStepsItemOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Swnz_NextStepsLinkingCollections = {
  __typename?: 'SWNZ_NextStepsLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_NextStepsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_NextStepsLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_NextStepsLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_NextStepsLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_NextStepsLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_NextStepsLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_NextStepsLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Swnz_NextStepsNextStepsItemsCollection = {
  __typename?: 'SWNZ_NextStepsNextStepsItemsCollection';
  items: Array<Maybe<Swnz_NextStepsItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_NextStepsNextStepsItemsCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_NextStepsOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Represents a web page in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/page) */
export type Swnz_Page = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_Page';
  _id: Scalars['ID']['output'];
  codeId?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Swnz_PageContent>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  linkedFrom?: Maybe<Swnz_PageLinkingCollections>;
  name?: Maybe<Scalars['String']['output']>;
  pageTheme?: Maybe<Scalars['String']['output']>;
  parentPage?: Maybe<Swnz_Page>;
  seo?: Maybe<Swnz_Seo>;
  showLcf?: Maybe<Scalars['Boolean']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** Represents a web page in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/page) */
export type Swnz_PageCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a web page in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/page) */
export type Swnz_PageContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Represents a web page in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/page) */
export type Swnz_PageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Represents a web page in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/page) */
export type Swnz_PageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a web page in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/page) */
export type Swnz_PagePageThemeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a web page in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/page) */
export type Swnz_PageParentPageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_PageFilter>;
};


/** Represents a web page in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/page) */
export type Swnz_PageSeoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_SeoFilter>;
};


/** Represents a web page in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/page) */
export type Swnz_PageShowLcfArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a web page in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/page) */
export type Swnz_PageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a web page in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/page) */
export type Swnz_PageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_PageCollection = {
  __typename?: 'SWNZ_PageCollection';
  items: Array<Maybe<Swnz_Page>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_PageContent = Swnz_PageHomepage | Swnz_PageModal | Swnz_PageSearchResults | Swnz_PageStandard;

export type Swnz_PageFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_PageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_PageFilter>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pageTheme?: InputMaybe<Scalars['String']['input']>;
  pageTheme_contains?: InputMaybe<Scalars['String']['input']>;
  pageTheme_exists?: InputMaybe<Scalars['Boolean']['input']>;
  pageTheme_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pageTheme_not?: InputMaybe<Scalars['String']['input']>;
  pageTheme_not_contains?: InputMaybe<Scalars['String']['input']>;
  pageTheme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  parentPage?: InputMaybe<Swnz_CfPageNestedFilter>;
  parentPage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  seo?: InputMaybe<Swnz_CfSeoNestedFilter>;
  seo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  showLcf?: InputMaybe<Scalars['Boolean']['input']>;
  showLcf_exists?: InputMaybe<Scalars['Boolean']['input']>;
  showLcf_not?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepage = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_PageHomepage';
  _id: Scalars['ID']['output'];
  areaOfStudy?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  backgroundColour?: Maybe<Scalars['String']['output']>;
  brandPillars?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  campaign?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  chinaRegion?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  hero?: Maybe<Swnz_HeroFeatured>;
  heroBackgroundVideo?: Maybe<Swnz_Asset>;
  heroHeading?: Maybe<Scalars['String']['output']>;
  heroImage?: Maybe<Swnz_MediaImage>;
  heroJumpText?: Maybe<Scalars['String']['output']>;
  heroPreHeading?: Maybe<Scalars['String']['output']>;
  heroTabsCollection?: Maybe<Swnz_PageHomepageHeroTabsCollection>;
  heroWelcomeMessage?: Maybe<Swnz_PageHomepageHeroWelcomeMessage>;
  indiaRegion?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  learnerJourneyStage?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  levelOfStudyBroad?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  levelOfStudySpecific?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  linkedFrom?: Maybe<Swnz_PageHomepageLinkingCollections>;
  location?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  nzRegion?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  providerSubtype?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  providerType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sectionsCollection?: Maybe<Swnz_PageHomepageSectionsCollection>;
  subjectOfStudy?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sys: Swnz_Sys;
  tags?: Maybe<Scalars['SWNZ_JSON']['output']>;
  topic?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  userType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageAreaOfStudyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageBackgroundColourArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageBrandPillarsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageCampaignArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageChinaRegionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageHeroArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_HeroFeaturedFilter>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageHeroBackgroundVideoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageHeroHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageHeroImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_MediaImageFilter>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageHeroJumpTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageHeroPreHeadingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageHeroTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PageHomepageHeroTabsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_HeroTabFilter>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageHeroWelcomeMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageIndiaRegionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageLearnerJourneyStageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageLevelOfStudyBroadArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageLevelOfStudySpecificArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageLocationArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageNzRegionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageProviderSubtypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageProviderTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageSectionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_PageHomepageSectionsFilter>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageSubjectOfStudyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageTagsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageTopicArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** The homepage template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageHomepage) */
export type Swnz_PageHomepageUserTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_PageHomepageCollection = {
  __typename?: 'SWNZ_PageHomepageCollection';
  items: Array<Maybe<Swnz_PageHomepage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_PageHomepageFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_PageHomepageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_PageHomepageFilter>>>;
  areaOfStudy_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  areaOfStudy_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  areaOfStudy_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  areaOfStudy_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColour?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColour_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  backgroundColour_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  brandPillars_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  brandPillars_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  brandPillars_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  brandPillars_exists?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  campaign_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  campaign_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  campaign_exists?: InputMaybe<Scalars['Boolean']['input']>;
  chinaRegion_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chinaRegion_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chinaRegion_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chinaRegion_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  hero?: InputMaybe<Swnz_CfHeroFeaturedNestedFilter>;
  heroBackgroundVideo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heroHeading?: InputMaybe<Scalars['String']['input']>;
  heroHeading_contains?: InputMaybe<Scalars['String']['input']>;
  heroHeading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heroHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heroHeading_not?: InputMaybe<Scalars['String']['input']>;
  heroHeading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heroHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heroImage?: InputMaybe<Swnz_CfMediaImageNestedFilter>;
  heroImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heroJumpText?: InputMaybe<Scalars['String']['input']>;
  heroJumpText_contains?: InputMaybe<Scalars['String']['input']>;
  heroJumpText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heroJumpText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heroJumpText_not?: InputMaybe<Scalars['String']['input']>;
  heroJumpText_not_contains?: InputMaybe<Scalars['String']['input']>;
  heroJumpText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heroPreHeading?: InputMaybe<Scalars['String']['input']>;
  heroPreHeading_contains?: InputMaybe<Scalars['String']['input']>;
  heroPreHeading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heroPreHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heroPreHeading_not?: InputMaybe<Scalars['String']['input']>;
  heroPreHeading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heroPreHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heroTabs?: InputMaybe<Swnz_CfHeroTabNestedFilter>;
  heroTabsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heroWelcomeMessage_contains?: InputMaybe<Scalars['String']['input']>;
  heroWelcomeMessage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heroWelcomeMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  hero_exists?: InputMaybe<Scalars['Boolean']['input']>;
  indiaRegion_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  indiaRegion_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  indiaRegion_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  indiaRegion_exists?: InputMaybe<Scalars['Boolean']['input']>;
  learnerJourneyStage_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  learnerJourneyStage_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  learnerJourneyStage_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  learnerJourneyStage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  levelOfStudyBroad_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudyBroad_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudyBroad_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudyBroad_exists?: InputMaybe<Scalars['Boolean']['input']>;
  levelOfStudySpecific_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudySpecific_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudySpecific_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudySpecific_exists?: InputMaybe<Scalars['Boolean']['input']>;
  location_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nzRegion_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nzRegion_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nzRegion_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nzRegion_exists?: InputMaybe<Scalars['Boolean']['input']>;
  providerSubtype_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerSubtype_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerSubtype_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerSubtype_exists?: InputMaybe<Scalars['Boolean']['input']>;
  providerType_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerType_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerType_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sections?: InputMaybe<Swnz_CfsectionsMultiTypeNestedFilter>;
  sectionsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subjectOfStudy_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subjectOfStudy_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subjectOfStudy_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subjectOfStudy_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  tags_exists?: InputMaybe<Scalars['Boolean']['input']>;
  topic_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topic_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topic_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topic_exists?: InputMaybe<Scalars['Boolean']['input']>;
  userType_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userType_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userType_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userType_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Swnz_PageHomepageHeroTabsCollection = {
  __typename?: 'SWNZ_PageHomepageHeroTabsCollection';
  items: Array<Maybe<Swnz_HeroTab>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_PageHomepageHeroTabsCollectionOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  QuoteAuthorAsc = 'quoteAuthor_ASC',
  QuoteAuthorDesc = 'quoteAuthor_DESC',
  QuoteStudyLocationAsc = 'quoteStudyLocation_ASC',
  QuoteStudyLocationDesc = 'quoteStudyLocation_DESC',
  QuoteTextAsc = 'quoteText_ASC',
  QuoteTextDesc = 'quoteText_DESC',
  StoryTitleAsc = 'storyTitle_ASC',
  StoryTitleDesc = 'storyTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TabHeadingAsc = 'tabHeading_ASC',
  TabHeadingDesc = 'tabHeading_DESC',
  TabSubHeadingAsc = 'tabSubHeading_ASC',
  TabSubHeadingDesc = 'tabSubHeading_DESC'
}

export type Swnz_PageHomepageHeroWelcomeMessage = {
  __typename?: 'SWNZ_PageHomepageHeroWelcomeMessage';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_PageHomepageHeroWelcomeMessageLinks;
};

export type Swnz_PageHomepageHeroWelcomeMessageAssets = {
  __typename?: 'SWNZ_PageHomepageHeroWelcomeMessageAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_PageHomepageHeroWelcomeMessageEntries = {
  __typename?: 'SWNZ_PageHomepageHeroWelcomeMessageEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_PageHomepageHeroWelcomeMessageLinks = {
  __typename?: 'SWNZ_PageHomepageHeroWelcomeMessageLinks';
  assets: Swnz_PageHomepageHeroWelcomeMessageAssets;
  entries: Swnz_PageHomepageHeroWelcomeMessageEntries;
  resources: Swnz_PageHomepageHeroWelcomeMessageResources;
};

export type Swnz_PageHomepageHeroWelcomeMessageResources = {
  __typename?: 'SWNZ_PageHomepageHeroWelcomeMessageResources';
  block: Array<Swnz_PageHomepageHeroWelcomeMessageResourcesBlock>;
  hyperlink: Array<Swnz_PageHomepageHeroWelcomeMessageResourcesHyperlink>;
  inline: Array<Swnz_PageHomepageHeroWelcomeMessageResourcesInline>;
};

export type Swnz_PageHomepageHeroWelcomeMessageResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_PageHomepageHeroWelcomeMessageResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_PageHomepageHeroWelcomeMessageResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_PageHomepageHeroWelcomeMessageResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_PageHomepageHeroWelcomeMessageResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_PageHomepageHeroWelcomeMessageResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_PageHomepageLinkingCollections = {
  __typename?: 'SWNZ_PageHomepageLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  pageCollection?: Maybe<Swnz_PageCollection>;
};


export type Swnz_PageHomepageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_PageHomepageLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PageHomepageLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_PageHomepageLinkingCollectionsPageCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageThemeAsc = 'pageTheme_ASC',
  PageThemeDesc = 'pageTheme_DESC',
  ShowLcfAsc = 'showLcf_ASC',
  ShowLcfDesc = 'showLcf_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_PageHomepageOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  HeroHeadingAsc = 'heroHeading_ASC',
  HeroHeadingDesc = 'heroHeading_DESC',
  HeroJumpTextAsc = 'heroJumpText_ASC',
  HeroJumpTextDesc = 'heroJumpText_DESC',
  HeroPreHeadingAsc = 'heroPreHeading_ASC',
  HeroPreHeadingDesc = 'heroPreHeading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Swnz_PageHomepageSectionsCollection = {
  __typename?: 'SWNZ_PageHomepageSectionsCollection';
  items: Array<Maybe<Swnz_PageHomepageSectionsItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_PageHomepageSectionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_PageHomepageSectionsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_PageHomepageSectionsFilter>>>;
  backgroundColour?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColour_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  backgroundColour_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_PageHomepageSectionsItem = Swnz_CardContainer | Swnz_HomepagePanel | Swnz_HomepageStoryPanel;

export type Swnz_PageLinkingCollections = {
  __typename?: 'SWNZ_PageLinkingCollections';
  callToActionCollection?: Maybe<Swnz_CallToActionCollection>;
  cardWithLinkCollection?: Maybe<Swnz_CardWithLinkCollection>;
  entryCollection?: Maybe<Swnz_EntryCollection>;
  heroFeaturedFloatingCardCollection?: Maybe<Swnz_HeroFeaturedFloatingCardCollection>;
  heroTabCollection?: Maybe<Swnz_HeroTabCollection>;
  iconCallToActionsCollection?: Maybe<Swnz_IconCallToActionsCollection>;
  navigationElementCollection?: Maybe<Swnz_NavigationElementCollection>;
  pageCollection?: Maybe<Swnz_PageCollection>;
};


export type Swnz_PageLinkingCollectionsCallToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PageLinkingCollectionsCallToActionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_PageLinkingCollectionsCardWithLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PageLinkingCollectionsCardWithLinkCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_PageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_PageLinkingCollectionsHeroFeaturedFloatingCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PageLinkingCollectionsHeroFeaturedFloatingCardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_PageLinkingCollectionsHeroTabCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PageLinkingCollectionsHeroTabCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_PageLinkingCollectionsIconCallToActionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PageLinkingCollectionsIconCallToActionsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_PageLinkingCollectionsNavigationElementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PageLinkingCollectionsNavigationElementCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_PageLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PageLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_PageLinkingCollectionsCallToActionCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  ModalIdAsc = 'modalId_ASC',
  ModalIdDesc = 'modalId_DESC',
  OpenInNewWindowAsc = 'openInNewWindow_ASC',
  OpenInNewWindowDesc = 'openInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlLinkAsc = 'urlLink_ASC',
  UrlLinkDesc = 'urlLink_DESC'
}

export enum Swnz_PageLinkingCollectionsCardWithLinkCollectionOrder {
  CustomUrlAsc = 'customUrl_ASC',
  CustomUrlDesc = 'customUrl_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_PageLinkingCollectionsHeroFeaturedFloatingCardCollectionOrder {
  CardHeadingAsc = 'cardHeading_ASC',
  CardHeadingDesc = 'cardHeading_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_PageLinkingCollectionsHeroTabCollectionOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  QuoteAuthorAsc = 'quoteAuthor_ASC',
  QuoteAuthorDesc = 'quoteAuthor_DESC',
  QuoteStudyLocationAsc = 'quoteStudyLocation_ASC',
  QuoteStudyLocationDesc = 'quoteStudyLocation_DESC',
  QuoteTextAsc = 'quoteText_ASC',
  QuoteTextDesc = 'quoteText_DESC',
  StoryTitleAsc = 'storyTitle_ASC',
  StoryTitleDesc = 'storyTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TabHeadingAsc = 'tabHeading_ASC',
  TabHeadingDesc = 'tabHeading_DESC',
  TabSubHeadingAsc = 'tabSubHeading_ASC',
  TabSubHeadingDesc = 'tabSubHeading_DESC'
}

export enum Swnz_PageLinkingCollectionsIconCallToActionsCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  OpenInNewWindowAsc = 'openInNewWindow_ASC',
  OpenInNewWindowDesc = 'openInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlLinkAsc = 'urlLink_ASC',
  UrlLinkDesc = 'urlLink_DESC'
}

export enum Swnz_PageLinkingCollectionsNavigationElementCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  CustomUrlAsc = 'customUrl_ASC',
  CustomUrlDesc = 'customUrl_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LinkTypeAsc = 'linkType_ASC',
  LinkTypeDesc = 'linkType_DESC',
  OpenLinkInNewWindowAsc = 'openLinkInNewWindow_ASC',
  OpenLinkInNewWindowDesc = 'openLinkInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_PageLinkingCollectionsPageCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageThemeAsc = 'pageTheme_ASC',
  PageThemeDesc = 'pageTheme_DESC',
  ShowLcfAsc = 'showLcf_ASC',
  ShowLcfDesc = 'showLcf_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** A page template for modals [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageModal) */
export type Swnz_PageModal = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_PageModal';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  hero?: Maybe<Swnz_PageModalHero>;
  linkedFrom?: Maybe<Swnz_PageModalLinkingCollections>;
  name?: Maybe<Scalars['String']['output']>;
  sectionsCollection?: Maybe<Swnz_PageModalSectionsCollection>;
  sys: Swnz_Sys;
};


/** A page template for modals [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageModal) */
export type Swnz_PageModalHeroArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_PageModalHeroFilter>;
};


/** A page template for modals [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageModal) */
export type Swnz_PageModalLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** A page template for modals [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageModal) */
export type Swnz_PageModalNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A page template for modals [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageModal) */
export type Swnz_PageModalSectionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_PageModalSectionsFilter>;
};

export type Swnz_PageModalCollection = {
  __typename?: 'SWNZ_PageModalCollection';
  items: Array<Maybe<Swnz_PageModal>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_PageModalFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_PageModalFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_PageModalFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  hero?: InputMaybe<Swnz_CfheroMultiTypeNestedFilter>;
  hero_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sections?: InputMaybe<Swnz_CfsectionsMultiTypeNestedFilter>;
  sectionsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_PageModalHero = Swnz_MediaImage | Swnz_MediaVideo;

export type Swnz_PageModalHeroFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_PageModalHeroFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_PageModalHeroFilter>>>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_contains?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_not?: InputMaybe<Scalars['String']['input']>;
  caption_not_contains?: InputMaybe<Scalars['String']['input']>;
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_PageModalLinkingCollections = {
  __typename?: 'SWNZ_PageModalLinkingCollections';
  cardWithModalCollection?: Maybe<Swnz_CardWithModalCollection>;
  entryCollection?: Maybe<Swnz_EntryCollection>;
  pageCollection?: Maybe<Swnz_PageCollection>;
  storyCardCollection?: Maybe<Swnz_StoryCardCollection>;
};


export type Swnz_PageModalLinkingCollectionsCardWithModalCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PageModalLinkingCollectionsCardWithModalCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_PageModalLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_PageModalLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PageModalLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_PageModalLinkingCollectionsStoryCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PageModalLinkingCollectionsStoryCardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_PageModalLinkingCollectionsCardWithModalCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_PageModalLinkingCollectionsPageCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageThemeAsc = 'pageTheme_ASC',
  PageThemeDesc = 'pageTheme_DESC',
  ShowLcfAsc = 'showLcf_ASC',
  ShowLcfDesc = 'showLcf_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_PageModalLinkingCollectionsStoryCardCollectionOrder {
  AreaOfStudyAsc = 'areaOfStudy_ASC',
  AreaOfStudyDesc = 'areaOfStudy_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LocationAsc = 'location_ASC',
  LocationDesc = 'location_DESC',
  StudentNameAsc = 'studentName_ASC',
  StudentNameDesc = 'studentName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_PageModalOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Swnz_PageModalSectionsCollection = {
  __typename?: 'SWNZ_PageModalSectionsCollection';
  items: Array<Maybe<Swnz_PageModalSectionsItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_PageModalSectionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_PageModalSectionsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_PageModalSectionsFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_PageModalSectionsItem = Swnz_BulletPointContainer | Swnz_GenericText | Swnz_ImpactText | Swnz_MediaImage | Swnz_MediaVideo | Swnz_Value;

export enum Swnz_PageOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageThemeAsc = 'pageTheme_ASC',
  PageThemeDesc = 'pageTheme_DESC',
  ShowLcfAsc = 'showLcf_ASC',
  ShowLcfDesc = 'showLcf_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Pages to display various search results [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageSearchResults) */
export type Swnz_PageSearchResults = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_PageSearchResults';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  keepExploring?: Maybe<Swnz_KeepExploring>;
  linkedFrom?: Maybe<Swnz_PageSearchResultsLinkingCollections>;
  name?: Maybe<Scalars['String']['output']>;
  promo?: Maybe<Swnz_Promo>;
  sys: Swnz_Sys;
};


/** Pages to display various search results [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageSearchResults) */
export type Swnz_PageSearchResultsKeepExploringArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_KeepExploringFilter>;
};


/** Pages to display various search results [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageSearchResults) */
export type Swnz_PageSearchResultsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Pages to display various search results [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageSearchResults) */
export type Swnz_PageSearchResultsNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Pages to display various search results [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageSearchResults) */
export type Swnz_PageSearchResultsPromoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_PromoFilter>;
};

export type Swnz_PageSearchResultsCollection = {
  __typename?: 'SWNZ_PageSearchResultsCollection';
  items: Array<Maybe<Swnz_PageSearchResults>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_PageSearchResultsFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_PageSearchResultsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_PageSearchResultsFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  keepExploring?: InputMaybe<Swnz_CfKeepExploringNestedFilter>;
  keepExploring_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  promo?: InputMaybe<Swnz_CfPromoNestedFilter>;
  promo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_PageSearchResultsLinkingCollections = {
  __typename?: 'SWNZ_PageSearchResultsLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  pageCollection?: Maybe<Swnz_PageCollection>;
};


export type Swnz_PageSearchResultsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_PageSearchResultsLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PageSearchResultsLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_PageSearchResultsLinkingCollectionsPageCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageThemeAsc = 'pageTheme_ASC',
  PageThemeDesc = 'pageTheme_DESC',
  ShowLcfAsc = 'showLcf_ASC',
  ShowLcfDesc = 'showLcf_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_PageSearchResultsOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandard = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_PageStandard';
  _id: Scalars['ID']['output'];
  areaOfStudy?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  brandPillars?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  campaign?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  chinaRegion?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  hero?: Maybe<Swnz_PageStandardHero>;
  indiaRegion?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  intro?: Maybe<Swnz_PageStandardIntro>;
  learnerJourneyStage?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  levelOfStudyBroad?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  levelOfStudySpecific?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  linkedFrom?: Maybe<Swnz_PageStandardLinkingCollections>;
  location?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  nzRegion?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  providerSubtype?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  providerType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sectionsCollection?: Maybe<Swnz_PageStandardSectionsCollection>;
  subjectOfStudy?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sys: Swnz_Sys;
  tags?: Maybe<Scalars['SWNZ_JSON']['output']>;
  topic?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  userType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardAreaOfStudyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardBrandPillarsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardCampaignArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardChinaRegionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardHeroArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardIndiaRegionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardIntroArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardLearnerJourneyStageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardLevelOfStudyBroadArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardLevelOfStudySpecificArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardLocationArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardNzRegionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardProviderSubtypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardProviderTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardSectionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_PageStandardSectionsFilter>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardSubjectOfStudyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardTagsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardTopicArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A generic/standard page template [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/pageStandard) */
export type Swnz_PageStandardUserTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_PageStandardCollection = {
  __typename?: 'SWNZ_PageStandardCollection';
  items: Array<Maybe<Swnz_PageStandard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_PageStandardFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_PageStandardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_PageStandardFilter>>>;
  areaOfStudy_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  areaOfStudy_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  areaOfStudy_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  areaOfStudy_exists?: InputMaybe<Scalars['Boolean']['input']>;
  brandPillars_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  brandPillars_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  brandPillars_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  brandPillars_exists?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  campaign_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  campaign_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  campaign_exists?: InputMaybe<Scalars['Boolean']['input']>;
  chinaRegion_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chinaRegion_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chinaRegion_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chinaRegion_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  hero_exists?: InputMaybe<Scalars['Boolean']['input']>;
  indiaRegion_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  indiaRegion_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  indiaRegion_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  indiaRegion_exists?: InputMaybe<Scalars['Boolean']['input']>;
  intro_exists?: InputMaybe<Scalars['Boolean']['input']>;
  learnerJourneyStage_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  learnerJourneyStage_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  learnerJourneyStage_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  learnerJourneyStage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  levelOfStudyBroad_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudyBroad_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudyBroad_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudyBroad_exists?: InputMaybe<Scalars['Boolean']['input']>;
  levelOfStudySpecific_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudySpecific_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudySpecific_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudySpecific_exists?: InputMaybe<Scalars['Boolean']['input']>;
  location_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nzRegion_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nzRegion_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nzRegion_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nzRegion_exists?: InputMaybe<Scalars['Boolean']['input']>;
  providerSubtype_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerSubtype_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerSubtype_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerSubtype_exists?: InputMaybe<Scalars['Boolean']['input']>;
  providerType_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerType_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerType_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sections?: InputMaybe<Swnz_CfsectionsMultiTypeNestedFilter>;
  sectionsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subjectOfStudy_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subjectOfStudy_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subjectOfStudy_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subjectOfStudy_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  tags_exists?: InputMaybe<Scalars['Boolean']['input']>;
  topic_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topic_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topic_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topic_exists?: InputMaybe<Scalars['Boolean']['input']>;
  userType_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userType_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userType_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userType_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Swnz_PageStandardHero = Swnz_Hero | Swnz_HeroFeatured;

export type Swnz_PageStandardIntro = Swnz_GenericText | Swnz_IntroTextWithJumpMenu;

export type Swnz_PageStandardLinkingCollections = {
  __typename?: 'SWNZ_PageStandardLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  pageCollection?: Maybe<Swnz_PageCollection>;
};


export type Swnz_PageStandardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_PageStandardLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PageStandardLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_PageStandardLinkingCollectionsPageCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageThemeAsc = 'pageTheme_ASC',
  PageThemeDesc = 'pageTheme_DESC',
  ShowLcfAsc = 'showLcf_ASC',
  ShowLcfDesc = 'showLcf_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_PageStandardOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Swnz_PageStandardSectionsCollection = {
  __typename?: 'SWNZ_PageStandardSectionsCollection';
  items: Array<Maybe<Swnz_PageStandardSectionsItem>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_PageStandardSectionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_PageStandardSectionsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_PageStandardSectionsFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_PageStandardSectionsItem = Swnz_AccordionContainer | Swnz_AlternatingContent | Swnz_AlternatingQuote | Swnz_Banner | Swnz_Blockquote | Swnz_BulletPointContainer | Swnz_CardContainer | Swnz_ComponentStatic | Swnz_FormLeadCapture | Swnz_GalleryCarousel | Swnz_GenericText | Swnz_ImpactText | Swnz_JumpAhead | Swnz_KeepExploring | Swnz_MediaImage | Swnz_MediaVideo | Swnz_NextSteps | Swnz_RichMedia | Swnz_StatContainer | Swnz_StoryCardContainer | Swnz_TileContainer | Swnz_Value;

/** Search results Promo [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/promo) */
export type Swnz_Promo = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_Promo';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Swnz_MediaImage>;
  linkedFrom?: Maybe<Swnz_PromoLinkingCollections>;
  primaryCta?: Maybe<Swnz_CallToAction>;
  showFilterCta?: Maybe<Scalars['Boolean']['output']>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** Search results Promo [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/promo) */
export type Swnz_PromoIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Search results Promo [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/promo) */
export type Swnz_PromoImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_MediaImageFilter>;
};


/** Search results Promo [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/promo) */
export type Swnz_PromoLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Search results Promo [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/promo) */
export type Swnz_PromoPrimaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** Search results Promo [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/promo) */
export type Swnz_PromoShowFilterCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Search results Promo [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/promo) */
export type Swnz_PromoTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_PromoCollection = {
  __typename?: 'SWNZ_PromoCollection';
  items: Array<Maybe<Swnz_Promo>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_PromoFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_PromoFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_PromoFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<Swnz_CfMediaImageNestedFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  primaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  primaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  showFilterCta?: InputMaybe<Scalars['Boolean']['input']>;
  showFilterCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  showFilterCta_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_PromoLinkingCollections = {
  __typename?: 'SWNZ_PromoLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  pageSearchResultsCollection?: Maybe<Swnz_PageSearchResultsCollection>;
};


export type Swnz_PromoLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_PromoLinkingCollectionsPageSearchResultsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_PromoLinkingCollectionsPageSearchResultsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_PromoLinkingCollectionsPageSearchResultsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_PromoOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  ShowFilterCtaAsc = 'showFilterCta_ASC',
  ShowFilterCtaDesc = 'showFilterCta_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Swnz_ResourceLink = {
  sys: Swnz_ResourceSys;
};

export type Swnz_ResourceSys = {
  __typename?: 'SWNZ_ResourceSys';
  linkType: Scalars['String']['output'];
  urn: Scalars['String']['output'];
};

/** Parallax player widget with video and audio content [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/richMedia) */
export type Swnz_RichMedia = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_RichMedia';
  _id: Scalars['ID']['output'];
  audio?: Maybe<Swnz_MediaAudio>;
  backgroundMedia?: Maybe<Swnz_RichMediaBackgroundMedia>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_RichMediaLinkingCollections>;
  sys: Swnz_Sys;
  tileBodyText?: Maybe<Scalars['String']['output']>;
  tileSubTitle?: Maybe<Scalars['String']['output']>;
};


/** Parallax player widget with video and audio content [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/richMedia) */
export type Swnz_RichMediaAudioArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_MediaAudioFilter>;
};


/** Parallax player widget with video and audio content [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/richMedia) */
export type Swnz_RichMediaBackgroundMediaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_RichMediaBackgroundMediaFilter>;
};


/** Parallax player widget with video and audio content [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/richMedia) */
export type Swnz_RichMediaIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Parallax player widget with video and audio content [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/richMedia) */
export type Swnz_RichMediaLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Parallax player widget with video and audio content [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/richMedia) */
export type Swnz_RichMediaTileBodyTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Parallax player widget with video and audio content [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/richMedia) */
export type Swnz_RichMediaTileSubTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_RichMediaBackgroundMedia = Swnz_MediaImage | Swnz_MediaVideo;

export type Swnz_RichMediaBackgroundMediaFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_RichMediaBackgroundMediaFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_RichMediaBackgroundMediaFilter>>>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_contains?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_not?: InputMaybe<Scalars['String']['input']>;
  caption_not_contains?: InputMaybe<Scalars['String']['input']>;
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_RichMediaCollection = {
  __typename?: 'SWNZ_RichMediaCollection';
  items: Array<Maybe<Swnz_RichMedia>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_RichMediaFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_RichMediaFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_RichMediaFilter>>>;
  audio?: InputMaybe<Swnz_CfMediaAudioNestedFilter>;
  audio_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundMedia?: InputMaybe<Swnz_CfbackgroundMediaMultiTypeNestedFilter>;
  backgroundMedia_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  tileBodyText?: InputMaybe<Scalars['String']['input']>;
  tileBodyText_contains?: InputMaybe<Scalars['String']['input']>;
  tileBodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tileBodyText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tileBodyText_not?: InputMaybe<Scalars['String']['input']>;
  tileBodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  tileBodyText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tileSubTitle?: InputMaybe<Scalars['String']['input']>;
  tileSubTitle_contains?: InputMaybe<Scalars['String']['input']>;
  tileSubTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tileSubTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tileSubTitle_not?: InputMaybe<Scalars['String']['input']>;
  tileSubTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  tileSubTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_RichMediaLinkingCollections = {
  __typename?: 'SWNZ_RichMediaLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_RichMediaLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_RichMediaLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_RichMediaLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_RichMediaLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_RichMediaOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TileSubTitleAsc = 'tileSubTitle_ASC',
  TileSubTitleDesc = 'tileSubTitle_DESC'
}

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/seo) */
export type Swnz_Seo = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_Seo';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  description?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  linkedFrom?: Maybe<Swnz_SeoLinkingCollections>;
  name?: Maybe<Scalars['String']['output']>;
  noFollow?: Maybe<Scalars['Boolean']['output']>;
  noIndex?: Maybe<Scalars['Boolean']['output']>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/seo) */
export type Swnz_SeoDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/seo) */
export type Swnz_SeoKeywordsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/seo) */
export type Swnz_SeoLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/seo) */
export type Swnz_SeoNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/seo) */
export type Swnz_SeoNoFollowArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/seo) */
export type Swnz_SeoNoIndexArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/seo) */
export type Swnz_SeoTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_SeoCollection = {
  __typename?: 'SWNZ_SeoCollection';
  items: Array<Maybe<Swnz_Seo>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_SeoFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_SeoFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_SeoFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  keywords_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  keywords_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  keywords_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  keywords_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  no_follow?: InputMaybe<Scalars['Boolean']['input']>;
  no_follow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  no_follow_not?: InputMaybe<Scalars['Boolean']['input']>;
  no_index?: InputMaybe<Scalars['Boolean']['input']>;
  no_index_exists?: InputMaybe<Scalars['Boolean']['input']>;
  no_index_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_SeoLinkingCollections = {
  __typename?: 'SWNZ_SeoLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageCollection?: Maybe<Swnz_PageCollection>;
};


export type Swnz_SeoLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_SeoLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_SeoLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_SeoLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_SeoLinkingCollectionsPageCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_SeoLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_SeoLinkingCollectionsPageCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageThemeAsc = 'pageTheme_ASC',
  PageThemeDesc = 'pageTheme_DESC',
  ShowLcfAsc = 'showLcf_ASC',
  ShowLcfDesc = 'showLcf_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_SeoOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'no_follow_ASC',
  NoFollowDesc = 'no_follow_DESC',
  NoIndexAsc = 'no_index_ASC',
  NoIndexDesc = 'no_index_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Statistics tile [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/stat) */
export type Swnz_Stat = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_Stat';
  _id: Scalars['ID']['output'];
  bodyText?: Maybe<Swnz_StatBodyText>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_StatLinkingCollections>;
  statColour?: Maybe<Scalars['String']['output']>;
  statistic?: Maybe<Scalars['Int']['output']>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** Statistics tile [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/stat) */
export type Swnz_StatBodyTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Statistics tile [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/stat) */
export type Swnz_StatIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Statistics tile [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/stat) */
export type Swnz_StatLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Statistics tile [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/stat) */
export type Swnz_StatStatColourArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Statistics tile [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/stat) */
export type Swnz_StatStatisticArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Statistics tile [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/stat) */
export type Swnz_StatTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_StatBodyText = {
  __typename?: 'SWNZ_StatBodyText';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_StatBodyTextLinks;
};

export type Swnz_StatBodyTextAssets = {
  __typename?: 'SWNZ_StatBodyTextAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_StatBodyTextEntries = {
  __typename?: 'SWNZ_StatBodyTextEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_StatBodyTextLinks = {
  __typename?: 'SWNZ_StatBodyTextLinks';
  assets: Swnz_StatBodyTextAssets;
  entries: Swnz_StatBodyTextEntries;
  resources: Swnz_StatBodyTextResources;
};

export type Swnz_StatBodyTextResources = {
  __typename?: 'SWNZ_StatBodyTextResources';
  block: Array<Swnz_StatBodyTextResourcesBlock>;
  hyperlink: Array<Swnz_StatBodyTextResourcesHyperlink>;
  inline: Array<Swnz_StatBodyTextResourcesInline>;
};

export type Swnz_StatBodyTextResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_StatBodyTextResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_StatBodyTextResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_StatBodyTextResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_StatBodyTextResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_StatBodyTextResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_StatCollection = {
  __typename?: 'SWNZ_StatCollection';
  items: Array<Maybe<Swnz_Stat>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** Container for displaying statistics [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/statContainer) */
export type Swnz_StatContainer = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_StatContainer';
  _id: Scalars['ID']['output'];
  codeId?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_StatContainerLinkingCollections>;
  statsCollection?: Maybe<Swnz_StatContainerStatsCollection>;
  sys: Swnz_Sys;
};


/** Container for displaying statistics [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/statContainer) */
export type Swnz_StatContainerCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container for displaying statistics [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/statContainer) */
export type Swnz_StatContainerIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container for displaying statistics [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/statContainer) */
export type Swnz_StatContainerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Container for displaying statistics [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/statContainer) */
export type Swnz_StatContainerStatsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_StatContainerStatsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_StatFilter>;
};

export type Swnz_StatContainerCollection = {
  __typename?: 'SWNZ_StatContainerCollection';
  items: Array<Maybe<Swnz_StatContainer>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_StatContainerFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_StatContainerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_StatContainerFilter>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stats?: InputMaybe<Swnz_CfStatNestedFilter>;
  statsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_StatContainerLinkingCollections = {
  __typename?: 'SWNZ_StatContainerLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_StatContainerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_StatContainerLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_StatContainerLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_StatContainerLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_StatContainerOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Swnz_StatContainerStatsCollection = {
  __typename?: 'SWNZ_StatContainerStatsCollection';
  items: Array<Maybe<Swnz_Stat>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_StatContainerStatsCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  StatColourAsc = 'statColour_ASC',
  StatColourDesc = 'statColour_DESC',
  StatisticAsc = 'statistic_ASC',
  StatisticDesc = 'statistic_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Swnz_StatFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_StatFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_StatFilter>>>;
  bodyText_contains?: InputMaybe<Scalars['String']['input']>;
  bodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  statColour?: InputMaybe<Scalars['String']['input']>;
  statColour_contains?: InputMaybe<Scalars['String']['input']>;
  statColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  statColour_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  statColour_not?: InputMaybe<Scalars['String']['input']>;
  statColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  statColour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  statistic?: InputMaybe<Scalars['Int']['input']>;
  statistic_exists?: InputMaybe<Scalars['Boolean']['input']>;
  statistic_gt?: InputMaybe<Scalars['Int']['input']>;
  statistic_gte?: InputMaybe<Scalars['Int']['input']>;
  statistic_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  statistic_lt?: InputMaybe<Scalars['Int']['input']>;
  statistic_lte?: InputMaybe<Scalars['Int']['input']>;
  statistic_not?: InputMaybe<Scalars['Int']['input']>;
  statistic_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_StatLinkingCollections = {
  __typename?: 'SWNZ_StatLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  statContainerCollection?: Maybe<Swnz_StatContainerCollection>;
};


export type Swnz_StatLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_StatLinkingCollectionsStatContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_StatLinkingCollectionsStatContainerCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_StatLinkingCollectionsStatContainerCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_StatOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  StatColourAsc = 'statColour_ASC',
  StatColourDesc = 'statColour_DESC',
  StatisticAsc = 'statistic_ASC',
  StatisticDesc = 'statistic_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Story Cards for the Homepage Story Panel [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/storyCard) */
export type Swnz_StoryCard = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_StoryCard';
  _id: Scalars['ID']['output'];
  areaOfStudy?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Swnz_MediaImage>;
  linkedFrom?: Maybe<Swnz_StoryCardLinkingCollections>;
  location?: Maybe<Scalars['String']['output']>;
  modalContent?: Maybe<Swnz_PageModal>;
  studentName?: Maybe<Scalars['String']['output']>;
  sys: Swnz_Sys;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};


/** Story Cards for the Homepage Story Panel [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/storyCard) */
export type Swnz_StoryCardAreaOfStudyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Story Cards for the Homepage Story Panel [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/storyCard) */
export type Swnz_StoryCardIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Story Cards for the Homepage Story Panel [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/storyCard) */
export type Swnz_StoryCardImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_MediaImageFilter>;
};


/** Story Cards for the Homepage Story Panel [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/storyCard) */
export type Swnz_StoryCardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Story Cards for the Homepage Story Panel [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/storyCard) */
export type Swnz_StoryCardLocationArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Story Cards for the Homepage Story Panel [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/storyCard) */
export type Swnz_StoryCardModalContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_PageModalFilter>;
};


/** Story Cards for the Homepage Story Panel [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/storyCard) */
export type Swnz_StoryCardStudentNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Story Cards for the Homepage Story Panel [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/storyCard) */
export type Swnz_StoryCardTagsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_StoryCardCollection = {
  __typename?: 'SWNZ_StoryCardCollection';
  items: Array<Maybe<Swnz_StoryCard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** Gallery for displaying Story Cards [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/storyCardContainer) */
export type Swnz_StoryCardContainer = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_StoryCardContainer';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_StoryCardContainerLinkingCollections>;
  storyCardsCollection?: Maybe<Swnz_StoryCardContainerStoryCardsCollection>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** Gallery for displaying Story Cards [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/storyCardContainer) */
export type Swnz_StoryCardContainerIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Gallery for displaying Story Cards [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/storyCardContainer) */
export type Swnz_StoryCardContainerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Gallery for displaying Story Cards [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/storyCardContainer) */
export type Swnz_StoryCardContainerStoryCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_StoryCardContainerStoryCardsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_StoryCardFilter>;
};


/** Gallery for displaying Story Cards [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/storyCardContainer) */
export type Swnz_StoryCardContainerTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_StoryCardContainerCollection = {
  __typename?: 'SWNZ_StoryCardContainerCollection';
  items: Array<Maybe<Swnz_StoryCardContainer>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_StoryCardContainerFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_StoryCardContainerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_StoryCardContainerFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  storyCards?: InputMaybe<Swnz_CfStoryCardNestedFilter>;
  storyCardsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_StoryCardContainerLinkingCollections = {
  __typename?: 'SWNZ_StoryCardContainerLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_StoryCardContainerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_StoryCardContainerLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_StoryCardContainerLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_StoryCardContainerLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_StoryCardContainerOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Swnz_StoryCardContainerStoryCardsCollection = {
  __typename?: 'SWNZ_StoryCardContainerStoryCardsCollection';
  items: Array<Maybe<Swnz_StoryCard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_StoryCardContainerStoryCardsCollectionOrder {
  AreaOfStudyAsc = 'areaOfStudy_ASC',
  AreaOfStudyDesc = 'areaOfStudy_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LocationAsc = 'location_ASC',
  LocationDesc = 'location_DESC',
  StudentNameAsc = 'studentName_ASC',
  StudentNameDesc = 'studentName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Swnz_StoryCardFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_StoryCardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_StoryCardFilter>>>;
  areaOfStudy?: InputMaybe<Scalars['String']['input']>;
  areaOfStudy_contains?: InputMaybe<Scalars['String']['input']>;
  areaOfStudy_exists?: InputMaybe<Scalars['Boolean']['input']>;
  areaOfStudy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  areaOfStudy_not?: InputMaybe<Scalars['String']['input']>;
  areaOfStudy_not_contains?: InputMaybe<Scalars['String']['input']>;
  areaOfStudy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<Swnz_CfMediaImageNestedFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  location_contains?: InputMaybe<Scalars['String']['input']>;
  location_exists?: InputMaybe<Scalars['Boolean']['input']>;
  location_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_not?: InputMaybe<Scalars['String']['input']>;
  location_not_contains?: InputMaybe<Scalars['String']['input']>;
  location_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  modalContent?: InputMaybe<Swnz_CfPageModalNestedFilter>;
  modalContent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  studentName?: InputMaybe<Scalars['String']['input']>;
  studentName_contains?: InputMaybe<Scalars['String']['input']>;
  studentName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  studentName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  studentName_not?: InputMaybe<Scalars['String']['input']>;
  studentName_not_contains?: InputMaybe<Scalars['String']['input']>;
  studentName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  tags_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Swnz_StoryCardLinkingCollections = {
  __typename?: 'SWNZ_StoryCardLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  homepageStoryPanelCollection?: Maybe<Swnz_HomepageStoryPanelCollection>;
  storyCardContainerCollection?: Maybe<Swnz_StoryCardContainerCollection>;
};


export type Swnz_StoryCardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_StoryCardLinkingCollectionsHomepageStoryPanelCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_StoryCardLinkingCollectionsHomepageStoryPanelCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_StoryCardLinkingCollectionsStoryCardContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_StoryCardLinkingCollectionsStoryCardContainerCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_StoryCardLinkingCollectionsHomepageStoryPanelCollectionOrder {
  BackgroundColourAsc = 'backgroundColour_ASC',
  BackgroundColourDesc = 'backgroundColour_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagHeadingAsc = 'tagHeading_ASC',
  TagHeadingDesc = 'tagHeading_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_StoryCardLinkingCollectionsStoryCardContainerCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_StoryCardOrder {
  AreaOfStudyAsc = 'areaOfStudy_ASC',
  AreaOfStudyDesc = 'areaOfStudy_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  LocationAsc = 'location_ASC',
  LocationDesc = 'location_DESC',
  StudentNameAsc = 'studentName_ASC',
  StudentNameDesc = 'studentName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Swnz_Sys = {
  __typename?: 'SWNZ_Sys';
  environmentId: Scalars['String']['output'];
  firstPublishedAt?: Maybe<Scalars['SWNZ_DateTime']['output']>;
  id: Scalars['String']['output'];
  /** The locale that was requested. */
  locale?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['SWNZ_DateTime']['output']>;
  publishedVersion?: Maybe<Scalars['Int']['output']>;
  spaceId: Scalars['String']['output'];
};

export type Swnz_SysFilter = {
  firstPublishedAt?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  firstPublishedAt_exists?: InputMaybe<Scalars['Boolean']['input']>;
  firstPublishedAt_gt?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  firstPublishedAt_gte?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  firstPublishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['SWNZ_DateTime']['input']>>>;
  firstPublishedAt_lt?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  firstPublishedAt_lte?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  firstPublishedAt_not?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  firstPublishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['SWNZ_DateTime']['input']>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publishedAt?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  publishedAt_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt_gt?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  publishedAt_gte?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['SWNZ_DateTime']['input']>>>;
  publishedAt_lt?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  publishedAt_lte?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  publishedAt_not?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['SWNZ_DateTime']['input']>>>;
  publishedVersion?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publishedVersion_gt?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_gte?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  publishedVersion_lt?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_lte?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_not?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/tile) */
export type Swnz_Tile = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_Tile';
  _id: Scalars['ID']['output'];
  bodyText?: Maybe<Swnz_TileBodyText>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  identifier?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Swnz_MediaImage>;
  linkedFrom?: Maybe<Swnz_TileLinkingCollections>;
  primaryCta?: Maybe<Swnz_CallToAction>;
  secondaryCta?: Maybe<Swnz_CallToAction>;
  subtitle?: Maybe<Scalars['String']['output']>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/tile) */
export type Swnz_TileBodyTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/tile) */
export type Swnz_TileIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/tile) */
export type Swnz_TileImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_MediaImageFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/tile) */
export type Swnz_TileLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/tile) */
export type Swnz_TilePrimaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/tile) */
export type Swnz_TileSecondaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/tile) */
export type Swnz_TileSubtitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/tile) */
export type Swnz_TileTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_TileBodyText = {
  __typename?: 'SWNZ_TileBodyText';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_TileBodyTextLinks;
};

export type Swnz_TileBodyTextAssets = {
  __typename?: 'SWNZ_TileBodyTextAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_TileBodyTextEntries = {
  __typename?: 'SWNZ_TileBodyTextEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_TileBodyTextLinks = {
  __typename?: 'SWNZ_TileBodyTextLinks';
  assets: Swnz_TileBodyTextAssets;
  entries: Swnz_TileBodyTextEntries;
  resources: Swnz_TileBodyTextResources;
};

export type Swnz_TileBodyTextResources = {
  __typename?: 'SWNZ_TileBodyTextResources';
  block: Array<Swnz_TileBodyTextResourcesBlock>;
  hyperlink: Array<Swnz_TileBodyTextResourcesHyperlink>;
  inline: Array<Swnz_TileBodyTextResourcesInline>;
};

export type Swnz_TileBodyTextResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_TileBodyTextResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_TileBodyTextResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_TileBodyTextResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_TileBodyTextResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_TileBodyTextResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_TileCollection = {
  __typename?: 'SWNZ_TileCollection';
  items: Array<Maybe<Swnz_Tile>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** Container to display tiles [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/tileContainer) */
export type Swnz_TileContainer = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_TileContainer';
  _id: Scalars['ID']['output'];
  codeId?: Maybe<Scalars['String']['output']>;
  columns?: Maybe<Scalars['Int']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  displayCollapsed?: Maybe<Scalars['Boolean']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  introText?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_TileContainerLinkingCollections>;
  sys: Swnz_Sys;
  tilesCollection?: Maybe<Swnz_TileContainerTilesCollection>;
  title?: Maybe<Scalars['String']['output']>;
};


/** Container to display tiles [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/tileContainer) */
export type Swnz_TileContainerCodeIdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display tiles [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/tileContainer) */
export type Swnz_TileContainerColumnsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display tiles [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/tileContainer) */
export type Swnz_TileContainerDisplayCollapsedArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display tiles [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/tileContainer) */
export type Swnz_TileContainerIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display tiles [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/tileContainer) */
export type Swnz_TileContainerIntroTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Container to display tiles [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/tileContainer) */
export type Swnz_TileContainerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Container to display tiles [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/tileContainer) */
export type Swnz_TileContainerTilesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_TileContainerTilesCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Swnz_TileFilter>;
};


/** Container to display tiles [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/tileContainer) */
export type Swnz_TileContainerTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_TileContainerCollection = {
  __typename?: 'SWNZ_TileContainerCollection';
  items: Array<Maybe<Swnz_TileContainer>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_TileContainerFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_TileContainerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_TileContainerFilter>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  columns?: InputMaybe<Scalars['Int']['input']>;
  columns_exists?: InputMaybe<Scalars['Boolean']['input']>;
  columns_gt?: InputMaybe<Scalars['Int']['input']>;
  columns_gte?: InputMaybe<Scalars['Int']['input']>;
  columns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  columns_lt?: InputMaybe<Scalars['Int']['input']>;
  columns_lte?: InputMaybe<Scalars['Int']['input']>;
  columns_not?: InputMaybe<Scalars['Int']['input']>;
  columns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  displayCollapsed?: InputMaybe<Scalars['Boolean']['input']>;
  displayCollapsed_exists?: InputMaybe<Scalars['Boolean']['input']>;
  displayCollapsed_not?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introText?: InputMaybe<Scalars['String']['input']>;
  introText_contains?: InputMaybe<Scalars['String']['input']>;
  introText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  introText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introText_not?: InputMaybe<Scalars['String']['input']>;
  introText_not_contains?: InputMaybe<Scalars['String']['input']>;
  introText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  tiles?: InputMaybe<Swnz_CfTileNestedFilter>;
  tilesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_TileContainerLinkingCollections = {
  __typename?: 'SWNZ_TileContainerLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  naumaiPageStandardCollection?: Maybe<Swnz_NaumaiPageStandardCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_TileContainerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_TileContainerLinkingCollectionsNaumaiPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_TileContainerLinkingCollectionsNaumaiPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_TileContainerLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_TileContainerLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_TileContainerLinkingCollectionsNaumaiPageStandardCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_TileContainerLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_TileContainerOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  ColumnsAsc = 'columns_ASC',
  ColumnsDesc = 'columns_DESC',
  DisplayCollapsedAsc = 'displayCollapsed_ASC',
  DisplayCollapsedDesc = 'displayCollapsed_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Swnz_TileContainerTilesCollection = {
  __typename?: 'SWNZ_TileContainerTilesCollection';
  items: Array<Maybe<Swnz_Tile>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum Swnz_TileContainerTilesCollectionOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Swnz_TileFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_TileFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_TileFilter>>>;
  bodyText_contains?: InputMaybe<Scalars['String']['input']>;
  bodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<Swnz_CfMediaImageNestedFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  primaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  primaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  secondaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  subtitle_contains?: InputMaybe<Scalars['String']['input']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subtitle_not?: InputMaybe<Scalars['String']['input']>;
  subtitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_TileLinkingCollections = {
  __typename?: 'SWNZ_TileLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  tileContainerCollection?: Maybe<Swnz_TileContainerCollection>;
};


export type Swnz_TileLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_TileLinkingCollectionsTileContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_TileLinkingCollectionsTileContainerCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_TileLinkingCollectionsTileContainerCollectionOrder {
  CodeIdAsc = 'codeId_ASC',
  CodeIdDesc = 'codeId_DESC',
  ColumnsAsc = 'columns_ASC',
  ColumnsDesc = 'columns_DESC',
  DisplayCollapsedAsc = 'displayCollapsed_ASC',
  DisplayCollapsedDesc = 'displayCollapsed_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Swnz_TileOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/translationProject) */
export type Swnz_TranslationProject = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_TranslationProject';
  _id: Scalars['ID']['output'];
  contentType?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  linkedFrom?: Maybe<Swnz_TranslationProjectLinkingCollections>;
  localizationMethod?: Maybe<Scalars['String']['output']>;
  localizedReferences?: Maybe<Scalars['Boolean']['output']>;
  requestedDueDate?: Maybe<Scalars['String']['output']>;
  selectedReferenceFields?: Maybe<Scalars['SWNZ_JSON']['output']>;
  sourceContent?: Maybe<Scalars['SWNZ_JSON']['output']>;
  sys: Swnz_Sys;
  targetLanguages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  trackChanges?: Maybe<Scalars['Boolean']['output']>;
  translationName?: Maybe<Scalars['String']['output']>;
  translationNotes?: Maybe<Scalars['String']['output']>;
  translator?: Maybe<Swnz_Translator>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/translationProject) */
export type Swnz_TranslationProjectContentTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/translationProject) */
export type Swnz_TranslationProjectLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/translationProject) */
export type Swnz_TranslationProjectLocalizationMethodArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/translationProject) */
export type Swnz_TranslationProjectLocalizedReferencesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/translationProject) */
export type Swnz_TranslationProjectRequestedDueDateArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/translationProject) */
export type Swnz_TranslationProjectSelectedReferenceFieldsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/translationProject) */
export type Swnz_TranslationProjectSourceContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/translationProject) */
export type Swnz_TranslationProjectTargetLanguagesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/translationProject) */
export type Swnz_TranslationProjectTrackChangesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/translationProject) */
export type Swnz_TranslationProjectTranslationNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/translationProject) */
export type Swnz_TranslationProjectTranslationNotesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/translationProject) */
export type Swnz_TranslationProjectTranslatorArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_TranslatorFilter>;
};

export type Swnz_TranslationProjectCollection = {
  __typename?: 'SWNZ_TranslationProjectCollection';
  items: Array<Maybe<Swnz_TranslationProject>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_TranslationProjectFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_TranslationProjectFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_TranslationProjectFilter>>>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  contentType_contains?: InputMaybe<Scalars['String']['input']>;
  contentType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentType_not?: InputMaybe<Scalars['String']['input']>;
  contentType_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  localizationMethod?: InputMaybe<Scalars['String']['input']>;
  localizationMethod_contains?: InputMaybe<Scalars['String']['input']>;
  localizationMethod_exists?: InputMaybe<Scalars['Boolean']['input']>;
  localizationMethod_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  localizationMethod_not?: InputMaybe<Scalars['String']['input']>;
  localizationMethod_not_contains?: InputMaybe<Scalars['String']['input']>;
  localizationMethod_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  localizedReferences?: InputMaybe<Scalars['Boolean']['input']>;
  localizedReferences_exists?: InputMaybe<Scalars['Boolean']['input']>;
  localizedReferences_not?: InputMaybe<Scalars['Boolean']['input']>;
  requestedDueDate?: InputMaybe<Scalars['String']['input']>;
  requestedDueDate_contains?: InputMaybe<Scalars['String']['input']>;
  requestedDueDate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  requestedDueDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  requestedDueDate_not?: InputMaybe<Scalars['String']['input']>;
  requestedDueDate_not_contains?: InputMaybe<Scalars['String']['input']>;
  requestedDueDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  selectedReferenceFields_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sourceContent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  targetLanguages_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  targetLanguages_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  targetLanguages_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  targetLanguages_exists?: InputMaybe<Scalars['Boolean']['input']>;
  trackChanges?: InputMaybe<Scalars['Boolean']['input']>;
  trackChanges_exists?: InputMaybe<Scalars['Boolean']['input']>;
  trackChanges_not?: InputMaybe<Scalars['Boolean']['input']>;
  translationName?: InputMaybe<Scalars['String']['input']>;
  translationName_contains?: InputMaybe<Scalars['String']['input']>;
  translationName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  translationName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translationName_not?: InputMaybe<Scalars['String']['input']>;
  translationName_not_contains?: InputMaybe<Scalars['String']['input']>;
  translationName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translationNotes?: InputMaybe<Scalars['String']['input']>;
  translationNotes_contains?: InputMaybe<Scalars['String']['input']>;
  translationNotes_exists?: InputMaybe<Scalars['Boolean']['input']>;
  translationNotes_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translationNotes_not?: InputMaybe<Scalars['String']['input']>;
  translationNotes_not_contains?: InputMaybe<Scalars['String']['input']>;
  translationNotes_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translator?: InputMaybe<Swnz_CfTranslatorNestedFilter>;
  translator_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Swnz_TranslationProjectLinkingCollections = {
  __typename?: 'SWNZ_TranslationProjectLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
};


export type Swnz_TranslationProjectLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_TranslationProjectOrder {
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  LocalizationMethodAsc = 'localizationMethod_ASC',
  LocalizationMethodDesc = 'localizationMethod_DESC',
  LocalizedReferencesAsc = 'localizedReferences_ASC',
  LocalizedReferencesDesc = 'localizedReferences_DESC',
  RequestedDueDateAsc = 'requestedDueDate_ASC',
  RequestedDueDateDesc = 'requestedDueDate_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrackChangesAsc = 'trackChanges_ASC',
  TrackChangesDesc = 'trackChanges_DESC',
  TranslationNameAsc = 'translationName_ASC',
  TranslationNameDesc = 'translationName_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/translator) */
export type Swnz_Translator = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_Translator';
  _id: Scalars['ID']['output'];
  apiKey?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: Swnz_ContentfulMetadata;
  linkedFrom?: Maybe<Swnz_TranslatorLinkingCollections>;
  name?: Maybe<Scalars['String']['output']>;
  sandbox?: Maybe<Scalars['Boolean']['output']>;
  sys: Swnz_Sys;
  translationService?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/translator) */
export type Swnz_TranslatorApiKeyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/translator) */
export type Swnz_TranslatorLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/translator) */
export type Swnz_TranslatorNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/translator) */
export type Swnz_TranslatorSandboxArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/translator) */
export type Swnz_TranslatorTranslationServiceArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_TranslatorCollection = {
  __typename?: 'SWNZ_TranslatorCollection';
  items: Array<Maybe<Swnz_Translator>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_TranslatorFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_TranslatorFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_TranslatorFilter>>>;
  apiKey?: InputMaybe<Scalars['String']['input']>;
  apiKey_contains?: InputMaybe<Scalars['String']['input']>;
  apiKey_exists?: InputMaybe<Scalars['Boolean']['input']>;
  apiKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  apiKey_not?: InputMaybe<Scalars['String']['input']>;
  apiKey_not_contains?: InputMaybe<Scalars['String']['input']>;
  apiKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  sandbox_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sandbox_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  translationService?: InputMaybe<Scalars['String']['input']>;
  translationService_contains?: InputMaybe<Scalars['String']['input']>;
  translationService_exists?: InputMaybe<Scalars['Boolean']['input']>;
  translationService_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translationService_not?: InputMaybe<Scalars['String']['input']>;
  translationService_not_contains?: InputMaybe<Scalars['String']['input']>;
  translationService_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_TranslatorLinkingCollections = {
  __typename?: 'SWNZ_TranslatorLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  translationProjectCollection?: Maybe<Swnz_TranslationProjectCollection>;
};


export type Swnz_TranslatorLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_TranslatorLinkingCollectionsTranslationProjectCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_TranslatorLinkingCollectionsTranslationProjectCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_TranslatorLinkingCollectionsTranslationProjectCollectionOrder {
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  LocalizationMethodAsc = 'localizationMethod_ASC',
  LocalizationMethodDesc = 'localizationMethod_DESC',
  LocalizedReferencesAsc = 'localizedReferences_ASC',
  LocalizedReferencesDesc = 'localizedReferences_DESC',
  RequestedDueDateAsc = 'requestedDueDate_ASC',
  RequestedDueDateDesc = 'requestedDueDate_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrackChangesAsc = 'trackChanges_ASC',
  TrackChangesDesc = 'trackChanges_DESC',
  TranslationNameAsc = 'translationName_ASC',
  TranslationNameDesc = 'translationName_DESC'
}

export enum Swnz_TranslatorOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SandboxAsc = 'sandbox_ASC',
  SandboxDesc = 'sandbox_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TranslationServiceAsc = 'translationService_ASC',
  TranslationServiceDesc = 'translationService_DESC'
}

/** Parallax banner stating organisation values [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/value) */
export type Swnz_Value = Swnz_Entry & Swnz__Node & {
  __typename?: 'SWNZ_Value';
  _id: Scalars['ID']['output'];
  contentfulMetadata: Swnz_ContentfulMetadata;
  ctaText?: Maybe<Scalars['String']['output']>;
  drawerDescription?: Maybe<Swnz_ValueDrawerDescription>;
  drawerPrimaryCta?: Maybe<Swnz_CallToAction>;
  drawerPronunciation?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Swnz_Asset>;
  introduction?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<Swnz_ValueLinkingCollections>;
  sys: Swnz_Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** Parallax banner stating organisation values [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/value) */
export type Swnz_ValueCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Parallax banner stating organisation values [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/value) */
export type Swnz_ValueDrawerDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Parallax banner stating organisation values [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/value) */
export type Swnz_ValueDrawerPrimaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<Swnz_CallToActionFilter>;
};


/** Parallax banner stating organisation values [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/value) */
export type Swnz_ValueDrawerPronunciationArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Parallax banner stating organisation values [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/value) */
export type Swnz_ValueIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Parallax banner stating organisation values [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/value) */
export type Swnz_ValueImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Parallax banner stating organisation values [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/value) */
export type Swnz_ValueIntroductionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Parallax banner stating organisation values [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/value) */
export type Swnz_ValueLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Parallax banner stating organisation values [See type definition](https://app.contentful.com/spaces/d5o6yop857fx/content_types/value) */
export type Swnz_ValueTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Swnz_ValueCollection = {
  __typename?: 'SWNZ_ValueCollection';
  items: Array<Maybe<Swnz_Value>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Swnz_ValueDrawerDescription = {
  __typename?: 'SWNZ_ValueDrawerDescription';
  json: Scalars['SWNZ_JSON']['output'];
  links: Swnz_ValueDrawerDescriptionLinks;
};

export type Swnz_ValueDrawerDescriptionAssets = {
  __typename?: 'SWNZ_ValueDrawerDescriptionAssets';
  block: Array<Maybe<Swnz_Asset>>;
  hyperlink: Array<Maybe<Swnz_Asset>>;
};

export type Swnz_ValueDrawerDescriptionEntries = {
  __typename?: 'SWNZ_ValueDrawerDescriptionEntries';
  block: Array<Maybe<Swnz_Entry>>;
  hyperlink: Array<Maybe<Swnz_Entry>>;
  inline: Array<Maybe<Swnz_Entry>>;
};

export type Swnz_ValueDrawerDescriptionLinks = {
  __typename?: 'SWNZ_ValueDrawerDescriptionLinks';
  assets: Swnz_ValueDrawerDescriptionAssets;
  entries: Swnz_ValueDrawerDescriptionEntries;
  resources: Swnz_ValueDrawerDescriptionResources;
};

export type Swnz_ValueDrawerDescriptionResources = {
  __typename?: 'SWNZ_ValueDrawerDescriptionResources';
  block: Array<Swnz_ValueDrawerDescriptionResourcesBlock>;
  hyperlink: Array<Swnz_ValueDrawerDescriptionResourcesHyperlink>;
  inline: Array<Swnz_ValueDrawerDescriptionResourcesInline>;
};

export type Swnz_ValueDrawerDescriptionResourcesBlock = Swnz_ResourceLink & {
  __typename?: 'SWNZ_ValueDrawerDescriptionResourcesBlock';
  sys: Swnz_ResourceSys;
};

export type Swnz_ValueDrawerDescriptionResourcesHyperlink = Swnz_ResourceLink & {
  __typename?: 'SWNZ_ValueDrawerDescriptionResourcesHyperlink';
  sys: Swnz_ResourceSys;
};

export type Swnz_ValueDrawerDescriptionResourcesInline = Swnz_ResourceLink & {
  __typename?: 'SWNZ_ValueDrawerDescriptionResourcesInline';
  sys: Swnz_ResourceSys;
};

export type Swnz_ValueFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_ValueFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_ValueFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  ctaText?: InputMaybe<Scalars['String']['input']>;
  ctaText_contains?: InputMaybe<Scalars['String']['input']>;
  ctaText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ctaText_not?: InputMaybe<Scalars['String']['input']>;
  ctaText_not_contains?: InputMaybe<Scalars['String']['input']>;
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  drawerDescription_contains?: InputMaybe<Scalars['String']['input']>;
  drawerDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  drawerDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  drawerPrimaryCta?: InputMaybe<Swnz_CfCallToActionNestedFilter>;
  drawerPrimaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  drawerPronunciation?: InputMaybe<Scalars['String']['input']>;
  drawerPronunciation_contains?: InputMaybe<Scalars['String']['input']>;
  drawerPronunciation_exists?: InputMaybe<Scalars['Boolean']['input']>;
  drawerPronunciation_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  drawerPronunciation_not?: InputMaybe<Scalars['String']['input']>;
  drawerPronunciation_not_contains?: InputMaybe<Scalars['String']['input']>;
  drawerPronunciation_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  introduction?: InputMaybe<Scalars['String']['input']>;
  introduction_contains?: InputMaybe<Scalars['String']['input']>;
  introduction_exists?: InputMaybe<Scalars['Boolean']['input']>;
  introduction_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introduction_not?: InputMaybe<Scalars['String']['input']>;
  introduction_not_contains?: InputMaybe<Scalars['String']['input']>;
  introduction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_ValueLinkingCollections = {
  __typename?: 'SWNZ_ValueLinkingCollections';
  entryCollection?: Maybe<Swnz_EntryCollection>;
  pageModalCollection?: Maybe<Swnz_PageModalCollection>;
  pageStandardCollection?: Maybe<Swnz_PageStandardCollection>;
};


export type Swnz_ValueLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_ValueLinkingCollectionsPageModalCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_ValueLinkingCollectionsPageModalCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type Swnz_ValueLinkingCollectionsPageStandardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<Swnz_ValueLinkingCollectionsPageStandardCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Swnz_ValueLinkingCollectionsPageModalCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_ValueLinkingCollectionsPageStandardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Swnz_ValueOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  DrawerPronunciationAsc = 'drawerPronunciation_ASC',
  DrawerPronunciationDesc = 'drawerPronunciation_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Swnz__Node = {
  _id: Scalars['ID']['output'];
};

export type Swnz_CfAccordionItemNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfAccordionItemNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfAccordionItemNestedFilter>>>;
  bodyText_contains?: InputMaybe<Scalars['String']['input']>;
  bodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CfBlogAuthorNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfBlogAuthorNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfBlogAuthorNestedFilter>>>;
  authorImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bio_contains?: InputMaybe<Scalars['String']['input']>;
  bio_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bio_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bio_not?: InputMaybe<Scalars['String']['input']>;
  bio_not_contains?: InputMaybe<Scalars['String']['input']>;
  bio_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullName_contains?: InputMaybe<Scalars['String']['input']>;
  fullName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fullName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fullName_not?: InputMaybe<Scalars['String']['input']>;
  fullName_not_contains?: InputMaybe<Scalars['String']['input']>;
  fullName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  subTitle_contains?: InputMaybe<Scalars['String']['input']>;
  subTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subTitle_not?: InputMaybe<Scalars['String']['input']>;
  subTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  subTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_CfBlogCallToActionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfBlogCallToActionNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfBlogCallToActionNestedFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  internalLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  openInNewWindow?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewWindow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewWindow_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  urlLink?: InputMaybe<Scalars['String']['input']>;
  urlLink_contains?: InputMaybe<Scalars['String']['input']>;
  urlLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  urlLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  urlLink_not?: InputMaybe<Scalars['String']['input']>;
  urlLink_not_contains?: InputMaybe<Scalars['String']['input']>;
  urlLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CfBlogCardWithLinkNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfBlogCardWithLinkNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfBlogCardWithLinkNestedFilter>>>;
  assetLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  customUrl?: InputMaybe<Scalars['String']['input']>;
  customUrl_contains?: InputMaybe<Scalars['String']['input']>;
  customUrl_exists?: InputMaybe<Scalars['Boolean']['input']>;
  customUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  customUrl_not?: InputMaybe<Scalars['String']['input']>;
  customUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  customUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CfBlogCategoryNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfBlogCategoryNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfBlogCategoryNestedFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_exists?: InputMaybe<Scalars['Boolean']['input']>;
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  order?: InputMaybe<Scalars['Int']['input']>;
  order_exists?: InputMaybe<Scalars['Boolean']['input']>;
  order_gt?: InputMaybe<Scalars['Int']['input']>;
  order_gte?: InputMaybe<Scalars['Int']['input']>;
  order_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  order_lt?: InputMaybe<Scalars['Int']['input']>;
  order_lte?: InputMaybe<Scalars['Int']['input']>;
  order_not?: InputMaybe<Scalars['Int']['input']>;
  order_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_CfBlogCustomCampaignTagsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfBlogCustomCampaignTagsNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfBlogCustomCampaignTagsNestedFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_exists?: InputMaybe<Scalars['Boolean']['input']>;
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_CfBlogPostNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfBlogPostNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfBlogPostNestedFilter>>>;
  areaOfStudy_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  areaOfStudy_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  areaOfStudy_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  areaOfStudy_exists?: InputMaybe<Scalars['Boolean']['input']>;
  authorsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyContent_contains?: InputMaybe<Scalars['String']['input']>;
  bodyContent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyContent_not_contains?: InputMaybe<Scalars['String']['input']>;
  brandPillars_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  brandPillars_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  brandPillars_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  brandPillars_exists?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  campaign_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  campaign_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  campaign_exists?: InputMaybe<Scalars['Boolean']['input']>;
  category_exists?: InputMaybe<Scalars['Boolean']['input']>;
  chinaRegion_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chinaRegion_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chinaRegion_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chinaRegion_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  featuredBlogPosition?: InputMaybe<Scalars['Int']['input']>;
  featuredBlogPosition_exists?: InputMaybe<Scalars['Boolean']['input']>;
  featuredBlogPosition_gt?: InputMaybe<Scalars['Int']['input']>;
  featuredBlogPosition_gte?: InputMaybe<Scalars['Int']['input']>;
  featuredBlogPosition_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  featuredBlogPosition_lt?: InputMaybe<Scalars['Int']['input']>;
  featuredBlogPosition_lte?: InputMaybe<Scalars['Int']['input']>;
  featuredBlogPosition_not?: InputMaybe<Scalars['Int']['input']>;
  featuredBlogPosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  hero_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  indiaRegion_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  indiaRegion_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  indiaRegion_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  indiaRegion_exists?: InputMaybe<Scalars['Boolean']['input']>;
  introText?: InputMaybe<Scalars['String']['input']>;
  introText_contains?: InputMaybe<Scalars['String']['input']>;
  introText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  introText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introText_not?: InputMaybe<Scalars['String']['input']>;
  introText_not_contains?: InputMaybe<Scalars['String']['input']>;
  introText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lastUpdatedDate?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  lastUpdatedDate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  lastUpdatedDate_gt?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  lastUpdatedDate_gte?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  lastUpdatedDate_in?: InputMaybe<Array<InputMaybe<Scalars['SWNZ_DateTime']['input']>>>;
  lastUpdatedDate_lt?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  lastUpdatedDate_lte?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  lastUpdatedDate_not?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  lastUpdatedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['SWNZ_DateTime']['input']>>>;
  learnerJourneyStage_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  learnerJourneyStage_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  learnerJourneyStage_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  learnerJourneyStage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  levelOfStudyBroad_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudyBroad_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudyBroad_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudyBroad_exists?: InputMaybe<Scalars['Boolean']['input']>;
  levelOfStudySpecific_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudySpecific_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudySpecific_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  levelOfStudySpecific_exists?: InputMaybe<Scalars['Boolean']['input']>;
  location_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_exists?: InputMaybe<Scalars['Boolean']['input']>;
  no_follow?: InputMaybe<Scalars['Boolean']['input']>;
  no_follow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  no_follow_not?: InputMaybe<Scalars['Boolean']['input']>;
  no_index?: InputMaybe<Scalars['Boolean']['input']>;
  no_index_exists?: InputMaybe<Scalars['Boolean']['input']>;
  no_index_not?: InputMaybe<Scalars['Boolean']['input']>;
  nzRegion_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nzRegion_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nzRegion_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nzRegion_exists?: InputMaybe<Scalars['Boolean']['input']>;
  providerSubtype_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerSubtype_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerSubtype_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerSubtype_exists?: InputMaybe<Scalars['Boolean']['input']>;
  providerType_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerType_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerType_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publishedDate?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  publishedDate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publishedDate_gt?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  publishedDate_gte?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  publishedDate_in?: InputMaybe<Array<InputMaybe<Scalars['SWNZ_DateTime']['input']>>>;
  publishedDate_lt?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  publishedDate_lte?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  publishedDate_not?: InputMaybe<Scalars['SWNZ_DateTime']['input']>;
  publishedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['SWNZ_DateTime']['input']>>>;
  relatedContentCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  seoDescription?: InputMaybe<Scalars['String']['input']>;
  seoDescription_contains?: InputMaybe<Scalars['String']['input']>;
  seoDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  seoDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  seoDescription_not?: InputMaybe<Scalars['String']['input']>;
  seoDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  seoDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  seoTitle?: InputMaybe<Scalars['String']['input']>;
  seoTitle_contains?: InputMaybe<Scalars['String']['input']>;
  seoTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  seoTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  seoTitle_not?: InputMaybe<Scalars['String']['input']>;
  seoTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  seoTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subjectOfStudy_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subjectOfStudy_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subjectOfStudy_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subjectOfStudy_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  tagBrandPillars?: InputMaybe<Scalars['String']['input']>;
  tagBrandPillars_contains?: InputMaybe<Scalars['String']['input']>;
  tagBrandPillars_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tagBrandPillars_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tagBrandPillars_not?: InputMaybe<Scalars['String']['input']>;
  tagBrandPillars_not_contains?: InputMaybe<Scalars['String']['input']>;
  tagBrandPillars_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tagCampaign?: InputMaybe<Scalars['String']['input']>;
  tagCampaign_contains?: InputMaybe<Scalars['String']['input']>;
  tagCampaign_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tagCampaign_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tagCampaign_not?: InputMaybe<Scalars['String']['input']>;
  tagCampaign_not_contains?: InputMaybe<Scalars['String']['input']>;
  tagCampaign_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tagCustomCampaign_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tagLearnerJourneyStage?: InputMaybe<Scalars['String']['input']>;
  tagLearnerJourneyStage_contains?: InputMaybe<Scalars['String']['input']>;
  tagLearnerJourneyStage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tagLearnerJourneyStage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tagLearnerJourneyStage_not?: InputMaybe<Scalars['String']['input']>;
  tagLearnerJourneyStage_not_contains?: InputMaybe<Scalars['String']['input']>;
  tagLearnerJourneyStage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tagTopic?: InputMaybe<Scalars['String']['input']>;
  tagTopic_contains?: InputMaybe<Scalars['String']['input']>;
  tagTopic_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tagTopic_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tagTopic_not?: InputMaybe<Scalars['String']['input']>;
  tagTopic_not_contains?: InputMaybe<Scalars['String']['input']>;
  tagTopic_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topic_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topic_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topic_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topic_exists?: InputMaybe<Scalars['Boolean']['input']>;
  userType_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userType_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userType_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userType_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Swnz_CfBulletPointNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfBulletPointNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfBulletPointNestedFilter>>>;
  bodyText_contains?: InputMaybe<Scalars['String']['input']>;
  bodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_CfCallToActionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfCallToActionNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfCallToActionNestedFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  internalLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  modalId?: InputMaybe<Scalars['String']['input']>;
  modalId_contains?: InputMaybe<Scalars['String']['input']>;
  modalId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  modalId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  modalId_not?: InputMaybe<Scalars['String']['input']>;
  modalId_not_contains?: InputMaybe<Scalars['String']['input']>;
  modalId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  openInNewWindow?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewWindow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openInNewWindow_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  urlLink?: InputMaybe<Scalars['String']['input']>;
  urlLink_contains?: InputMaybe<Scalars['String']['input']>;
  urlLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  urlLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  urlLink_not?: InputMaybe<Scalars['String']['input']>;
  urlLink_not_contains?: InputMaybe<Scalars['String']['input']>;
  urlLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CfCardWithLinkNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfCardWithLinkNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfCardWithLinkNestedFilter>>>;
  assetLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  customUrl?: InputMaybe<Scalars['String']['input']>;
  customUrl_contains?: InputMaybe<Scalars['String']['input']>;
  customUrl_exists?: InputMaybe<Scalars['Boolean']['input']>;
  customUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  customUrl_not?: InputMaybe<Scalars['String']['input']>;
  customUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  customUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CfCardWithModalNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfCardWithModalNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfCardWithModalNestedFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  modalContent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CfEmotionItemNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfEmotionItemNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfEmotionItemNestedFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  responsesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_CfEmotionItemResponseNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfEmotionItemResponseNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfEmotionItemResponseNestedFilter>>>;
  bodyText_contains?: InputMaybe<Scalars['String']['input']>;
  bodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  callToAction_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  heading?: InputMaybe<Scalars['String']['input']>;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading_not?: InputMaybe<Scalars['String']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_CfFormDropdownNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfFormDropdownNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfFormDropdownNestedFilter>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  dropdownOptionsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_CfFormDropdownOptionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfFormDropdownOptionNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfFormDropdownOptionNestedFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_exists?: InputMaybe<Scalars['Boolean']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CfFormFieldCityNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfFormFieldCityNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfFormFieldCityNestedFilter>>>;
  cityLabel?: InputMaybe<Scalars['String']['input']>;
  cityLabel_contains?: InputMaybe<Scalars['String']['input']>;
  cityLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cityLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cityLabel_not?: InputMaybe<Scalars['String']['input']>;
  cityLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  cityLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_exists?: InputMaybe<Scalars['Boolean']['input']>;
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_CfHeroFeaturedFloatingCardNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfHeroFeaturedFloatingCardNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfHeroFeaturedFloatingCardNestedFilter>>>;
  cardHeading?: InputMaybe<Scalars['String']['input']>;
  cardHeading_contains?: InputMaybe<Scalars['String']['input']>;
  cardHeading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cardHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cardHeading_not?: InputMaybe<Scalars['String']['input']>;
  cardHeading_not_contains?: InputMaybe<Scalars['String']['input']>;
  cardHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  internalLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CfHeroFeaturedNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfHeroFeaturedNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfHeroFeaturedNestedFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  heading?: InputMaybe<Scalars['String']['input']>;
  heading_contains?: InputMaybe<Scalars['String']['input']>;
  heading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heading_not?: InputMaybe<Scalars['String']['input']>;
  heading_not_contains?: InputMaybe<Scalars['String']['input']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heroFloatingCard_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introText?: InputMaybe<Scalars['String']['input']>;
  introText_contains?: InputMaybe<Scalars['String']['input']>;
  introText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  introText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introText_not?: InputMaybe<Scalars['String']['input']>;
  introText_not_contains?: InputMaybe<Scalars['String']['input']>;
  introText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mediaItem_exists?: InputMaybe<Scalars['Boolean']['input']>;
  primaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subHeading?: InputMaybe<Scalars['String']['input']>;
  subHeading_contains?: InputMaybe<Scalars['String']['input']>;
  subHeading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subHeading_not?: InputMaybe<Scalars['String']['input']>;
  subHeading_not_contains?: InputMaybe<Scalars['String']['input']>;
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  textThemeColour?: InputMaybe<Scalars['String']['input']>;
  textThemeColour_contains?: InputMaybe<Scalars['String']['input']>;
  textThemeColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  textThemeColour_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  textThemeColour_not?: InputMaybe<Scalars['String']['input']>;
  textThemeColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  textThemeColour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CfHeroTabNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfHeroTabNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfHeroTabNestedFilter>>>;
  backgroundColour?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundColour_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  backgroundColour_not?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  backgroundColour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  foregroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  quoteAuthor?: InputMaybe<Scalars['String']['input']>;
  quoteAuthor_contains?: InputMaybe<Scalars['String']['input']>;
  quoteAuthor_exists?: InputMaybe<Scalars['Boolean']['input']>;
  quoteAuthor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  quoteAuthor_not?: InputMaybe<Scalars['String']['input']>;
  quoteAuthor_not_contains?: InputMaybe<Scalars['String']['input']>;
  quoteAuthor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  quoteStudyLocation?: InputMaybe<Scalars['String']['input']>;
  quoteStudyLocation_contains?: InputMaybe<Scalars['String']['input']>;
  quoteStudyLocation_exists?: InputMaybe<Scalars['Boolean']['input']>;
  quoteStudyLocation_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  quoteStudyLocation_not?: InputMaybe<Scalars['String']['input']>;
  quoteStudyLocation_not_contains?: InputMaybe<Scalars['String']['input']>;
  quoteStudyLocation_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  quoteText?: InputMaybe<Scalars['String']['input']>;
  quoteText_contains?: InputMaybe<Scalars['String']['input']>;
  quoteText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  quoteText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  quoteText_not?: InputMaybe<Scalars['String']['input']>;
  quoteText_not_contains?: InputMaybe<Scalars['String']['input']>;
  quoteText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  secondaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  storyImageOne_exists?: InputMaybe<Scalars['Boolean']['input']>;
  storyImageTwo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  storyLearnMoreOne_exists?: InputMaybe<Scalars['Boolean']['input']>;
  storyLearnMoreTwo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  storySummaryOne_contains?: InputMaybe<Scalars['String']['input']>;
  storySummaryOne_exists?: InputMaybe<Scalars['Boolean']['input']>;
  storySummaryOne_not_contains?: InputMaybe<Scalars['String']['input']>;
  storySummaryTwo_contains?: InputMaybe<Scalars['String']['input']>;
  storySummaryTwo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  storySummaryTwo_not_contains?: InputMaybe<Scalars['String']['input']>;
  storyTitle?: InputMaybe<Scalars['String']['input']>;
  storyTitle_contains?: InputMaybe<Scalars['String']['input']>;
  storyTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  storyTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  storyTitle_not?: InputMaybe<Scalars['String']['input']>;
  storyTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  storyTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  tabBackgroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tabHeading?: InputMaybe<Scalars['String']['input']>;
  tabHeading_contains?: InputMaybe<Scalars['String']['input']>;
  tabHeading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tabHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tabHeading_not?: InputMaybe<Scalars['String']['input']>;
  tabHeading_not_contains?: InputMaybe<Scalars['String']['input']>;
  tabHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tabSubHeading?: InputMaybe<Scalars['String']['input']>;
  tabSubHeading_contains?: InputMaybe<Scalars['String']['input']>;
  tabSubHeading_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tabSubHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tabSubHeading_not?: InputMaybe<Scalars['String']['input']>;
  tabSubHeading_not_contains?: InputMaybe<Scalars['String']['input']>;
  tabSubHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tabThumbnailImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Swnz_CfHomepageCardNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfHomepageCardNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfHomepageCardNestedFilter>>>;
  bodyText_contains?: InputMaybe<Scalars['String']['input']>;
  bodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isNew?: InputMaybe<Scalars['Boolean']['input']>;
  isNew_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isNew_not?: InputMaybe<Scalars['Boolean']['input']>;
  primaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  subtitle_contains?: InputMaybe<Scalars['String']['input']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subtitle_not?: InputMaybe<Scalars['String']['input']>;
  subtitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CfKeepExploringNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfKeepExploringNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfKeepExploringNestedFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  ctaLinksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CfMediaAudioNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfMediaAudioNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfMediaAudioNestedFilter>>>;
  audio_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sourceUrl?: InputMaybe<Scalars['String']['input']>;
  sourceUrl_contains?: InputMaybe<Scalars['String']['input']>;
  sourceUrl_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sourceUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sourceUrl_not?: InputMaybe<Scalars['String']['input']>;
  sourceUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  sourceUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_CfMediaIconNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfMediaIconNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfMediaIconNestedFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  source_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_CfMediaImageNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfMediaImageNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfMediaImageNestedFilter>>>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_contains?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_not?: InputMaybe<Scalars['String']['input']>;
  caption_not_contains?: InputMaybe<Scalars['String']['input']>;
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isZoomable?: InputMaybe<Scalars['Boolean']['input']>;
  isZoomable_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isZoomable_not?: InputMaybe<Scalars['Boolean']['input']>;
  source_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_CfNaumaiPageStandardNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfNaumaiPageStandardNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfNaumaiPageStandardNestedFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  hero_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  intro_exists?: InputMaybe<Scalars['Boolean']['input']>;
  parentPage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  region_contains?: InputMaybe<Scalars['String']['input']>;
  region_exists?: InputMaybe<Scalars['Boolean']['input']>;
  region_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  region_not?: InputMaybe<Scalars['String']['input']>;
  region_not_contains?: InputMaybe<Scalars['String']['input']>;
  region_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  seo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CfNavigationElementNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfNavigationElementNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfNavigationElementNestedFilter>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  customUrl?: InputMaybe<Scalars['String']['input']>;
  customUrl_contains?: InputMaybe<Scalars['String']['input']>;
  customUrl_exists?: InputMaybe<Scalars['Boolean']['input']>;
  customUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  customUrl_not?: InputMaybe<Scalars['String']['input']>;
  customUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  customUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  internalPageLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  linkType?: InputMaybe<Scalars['String']['input']>;
  linkType_contains?: InputMaybe<Scalars['String']['input']>;
  linkType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  linkType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  linkType_not?: InputMaybe<Scalars['String']['input']>;
  linkType_not_contains?: InputMaybe<Scalars['String']['input']>;
  linkType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navigationElementsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openLinkInNewWindow?: InputMaybe<Scalars['Boolean']['input']>;
  openLinkInNewWindow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openLinkInNewWindow_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CfNextStepsItemNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfNextStepsItemNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfNextStepsItemNestedFilter>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  primaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CfPageModalNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfPageModalNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfPageModalNestedFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  hero_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_CfPageNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfPageNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfPageNestedFilter>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pageTheme?: InputMaybe<Scalars['String']['input']>;
  pageTheme_contains?: InputMaybe<Scalars['String']['input']>;
  pageTheme_exists?: InputMaybe<Scalars['Boolean']['input']>;
  pageTheme_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pageTheme_not?: InputMaybe<Scalars['String']['input']>;
  pageTheme_not_contains?: InputMaybe<Scalars['String']['input']>;
  pageTheme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  parentPage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  seo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  showLcf?: InputMaybe<Scalars['Boolean']['input']>;
  showLcf_exists?: InputMaybe<Scalars['Boolean']['input']>;
  showLcf_not?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CfPromoNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfPromoNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfPromoNestedFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  primaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  showFilterCta?: InputMaybe<Scalars['Boolean']['input']>;
  showFilterCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  showFilterCta_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CfSeoNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfSeoNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfSeoNestedFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  keywords_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  keywords_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  keywords_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  keywords_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  no_follow?: InputMaybe<Scalars['Boolean']['input']>;
  no_follow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  no_follow_not?: InputMaybe<Scalars['Boolean']['input']>;
  no_index?: InputMaybe<Scalars['Boolean']['input']>;
  no_index_exists?: InputMaybe<Scalars['Boolean']['input']>;
  no_index_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CfStatNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfStatNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfStatNestedFilter>>>;
  bodyText_contains?: InputMaybe<Scalars['String']['input']>;
  bodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  statColour?: InputMaybe<Scalars['String']['input']>;
  statColour_contains?: InputMaybe<Scalars['String']['input']>;
  statColour_exists?: InputMaybe<Scalars['Boolean']['input']>;
  statColour_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  statColour_not?: InputMaybe<Scalars['String']['input']>;
  statColour_not_contains?: InputMaybe<Scalars['String']['input']>;
  statColour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  statistic?: InputMaybe<Scalars['Int']['input']>;
  statistic_exists?: InputMaybe<Scalars['Boolean']['input']>;
  statistic_gt?: InputMaybe<Scalars['Int']['input']>;
  statistic_gte?: InputMaybe<Scalars['Int']['input']>;
  statistic_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  statistic_lt?: InputMaybe<Scalars['Int']['input']>;
  statistic_lte?: InputMaybe<Scalars['Int']['input']>;
  statistic_not?: InputMaybe<Scalars['Int']['input']>;
  statistic_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CfStoryCardNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfStoryCardNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfStoryCardNestedFilter>>>;
  areaOfStudy?: InputMaybe<Scalars['String']['input']>;
  areaOfStudy_contains?: InputMaybe<Scalars['String']['input']>;
  areaOfStudy_exists?: InputMaybe<Scalars['Boolean']['input']>;
  areaOfStudy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  areaOfStudy_not?: InputMaybe<Scalars['String']['input']>;
  areaOfStudy_not_contains?: InputMaybe<Scalars['String']['input']>;
  areaOfStudy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  location_contains?: InputMaybe<Scalars['String']['input']>;
  location_exists?: InputMaybe<Scalars['Boolean']['input']>;
  location_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_not?: InputMaybe<Scalars['String']['input']>;
  location_not_contains?: InputMaybe<Scalars['String']['input']>;
  location_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  modalContent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  studentName?: InputMaybe<Scalars['String']['input']>;
  studentName_contains?: InputMaybe<Scalars['String']['input']>;
  studentName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  studentName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  studentName_not?: InputMaybe<Scalars['String']['input']>;
  studentName_not_contains?: InputMaybe<Scalars['String']['input']>;
  studentName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  tags_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Swnz_CfTileNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfTileNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfTileNestedFilter>>>;
  bodyText_contains?: InputMaybe<Scalars['String']['input']>;
  bodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  primaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  secondaryCta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  subtitle_contains?: InputMaybe<Scalars['String']['input']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subtitle_not?: InputMaybe<Scalars['String']['input']>;
  subtitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CfTranslatorNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfTranslatorNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfTranslatorNestedFilter>>>;
  apiKey?: InputMaybe<Scalars['String']['input']>;
  apiKey_contains?: InputMaybe<Scalars['String']['input']>;
  apiKey_exists?: InputMaybe<Scalars['Boolean']['input']>;
  apiKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  apiKey_not?: InputMaybe<Scalars['String']['input']>;
  apiKey_not_contains?: InputMaybe<Scalars['String']['input']>;
  apiKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  sandbox_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sandbox_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<Swnz_SysFilter>;
  translationService?: InputMaybe<Scalars['String']['input']>;
  translationService_contains?: InputMaybe<Scalars['String']['input']>;
  translationService_exists?: InputMaybe<Scalars['Boolean']['input']>;
  translationService_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translationService_not?: InputMaybe<Scalars['String']['input']>;
  translationService_not_contains?: InputMaybe<Scalars['String']['input']>;
  translationService_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Swnz_CfbackgroundMediaMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfbackgroundMediaMultiTypeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfbackgroundMediaMultiTypeNestedFilter>>>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_contains?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_not?: InputMaybe<Scalars['String']['input']>;
  caption_not_contains?: InputMaybe<Scalars['String']['input']>;
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_CfheroMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfheroMultiTypeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfheroMultiTypeNestedFilter>>>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_contains?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_not?: InputMaybe<Scalars['String']['input']>;
  caption_not_contains?: InputMaybe<Scalars['String']['input']>;
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_CfimageCarouselMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfimageCarouselMultiTypeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfimageCarouselMultiTypeNestedFilter>>>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_contains?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_not?: InputMaybe<Scalars['String']['input']>;
  caption_not_contains?: InputMaybe<Scalars['String']['input']>;
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_CfmediaItemMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfmediaItemMultiTypeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfmediaItemMultiTypeNestedFilter>>>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_contains?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_not?: InputMaybe<Scalars['String']['input']>;
  caption_not_contains?: InputMaybe<Scalars['String']['input']>;
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_CfmediaItemsMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfmediaItemsMultiTypeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfmediaItemsMultiTypeNestedFilter>>>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_contains?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_not?: InputMaybe<Scalars['String']['input']>;
  caption_not_contains?: InputMaybe<Scalars['String']['input']>;
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId?: InputMaybe<Scalars['String']['input']>;
  codeId_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  codeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  codeId_not?: InputMaybe<Scalars['String']['input']>;
  codeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  codeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type Swnz_CfsectionsMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<Swnz_CfsectionsMultiTypeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Swnz_CfsectionsMultiTypeNestedFilter>>>;
  contentfulMetadata?: InputMaybe<Swnz_ContentfulMetadataFilter>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifier_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_exists?: InputMaybe<Scalars['Boolean']['input']>;
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  identifier_not?: InputMaybe<Scalars['String']['input']>;
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>;
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<Swnz_SysFilter>;
};

export type AgentAttributes = {
  __typename?: 'agentAttributes';
  specialties?: Maybe<AgentSpecialties>;
};

export type AgentIdentifier = {
  __typename?: 'agentIdentifier';
  body?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
};

export type AgentOffice = {
  __typename?: 'agentOffice';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isHeadOffice?: Maybe<Scalars['Boolean']['output']>;
  isoCountryCode?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  studentEmail?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type AgentSpecialties = {
  __typename?: 'agentSpecialties';
  EL?: Maybe<Scalars['Boolean']['output']>;
  HS?: Maybe<Scalars['Boolean']['output']>;
  ITP?: Maybe<Scalars['Boolean']['output']>;
  PIS?: Maybe<Scalars['Boolean']['output']>;
  PTE?: Maybe<Scalars['Boolean']['output']>;
  UNI?: Maybe<Scalars['Boolean']['output']>;
};

export type ApiInfo = {
  __typename?: 'apiInfo';
  aws_region?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type Campus = {
  __typename?: 'campus';
  active?: Maybe<Scalars['Boolean']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  attributes?: Maybe<ProviderAttributes>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<TaxonomyObject>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  profileImages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  profileVideos?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  region?: Maybe<TaxonomyObject>;
};

export type Department = {
  __typename?: 'department';
  active?: Maybe<Scalars['Boolean']['output']>;
  campusIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Intake = {
  __typename?: 'intake';
  campus?: Maybe<Scalars['String']['output']>;
  deliveryMode?: Maybe<TaxonomyObject>;
  duration?: Maybe<Scalars['String']['output']>;
  enrolmentDeadline?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modeOfStudy?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type InventoryAgent = {
  __typename?: 'inventoryAgent';
  attributes?: Maybe<AgentAttributes>;
  created?: Maybe<Scalars['String']['output']>;
  domains?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  identifiers?: Maybe<Array<Maybe<AgentIdentifier>>>;
  name?: Maybe<Scalars['String']['output']>;
  offices?: Maybe<Array<Maybe<AgentOffice>>>;
  status?: Maybe<Scalars['String']['output']>;
  statusUpdateTime?: Maybe<Scalars['String']['output']>;
  subtype?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type InventoryCollection = InventoryAgent | InventoryCourse | InventoryProvider | InventoryScholarship;

export type InventoryCourse = {
  __typename?: 'inventoryCourse';
  active?: Maybe<Scalars['Boolean']['output']>;
  areasOfStudy?: Maybe<Array<Maybe<TaxonomyObject>>>;
  department?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  entryRequirements?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  intakes?: Maybe<Array<Maybe<Intake>>>;
  levelOfStudy?: Maybe<TaxonomyObject>;
  majorOfStudy?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nzqaLevel?: Maybe<Scalars['Int']['output']>;
  provider?: Maybe<InventoryProvider>;
  subjectsOfStudy?: Maybe<Array<Maybe<TaxonomyObject>>>;
};

export type InventoryProvider = {
  __typename?: 'inventoryProvider';
  active?: Maybe<Scalars['Boolean']['output']>;
  attributes?: Maybe<ProviderAttributes>;
  campuses?: Maybe<Array<Maybe<Campus>>>;
  defaultCampus?: Maybe<Scalars['String']['output']>;
  departments?: Maybe<Array<Maybe<Department>>>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profileImages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  profileVideos?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  subtype?: Maybe<TaxonomyObject>;
  subtypeList?: Maybe<Array<Maybe<TaxonomyObject>>>;
  summary?: Maybe<Scalars['String']['output']>;
  type?: Maybe<TaxonomyObject>;
  url?: Maybe<Scalars['String']['output']>;
};

export type InventoryScholarship = {
  __typename?: 'inventoryScholarship';
  active?: Maybe<Scalars['Boolean']['output']>;
  applicationDeadlines?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  applicationInstructions?: Maybe<Scalars['String']['output']>;
  areasOfStudy?: Maybe<Array<Maybe<TaxonomyObject>>>;
  displayValue?: Maybe<Scalars['String']['output']>;
  eligibilityCriteria?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  levelsOfStudy?: Maybe<Array<Maybe<TaxonomyObject>>>;
  nationalities?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  numberOfAwards?: Maybe<Scalars['String']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  providerLogoUrl?: Maybe<Scalars['String']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
  providerSubtype?: Maybe<TaxonomyObject>;
  providerSubtypeList?: Maybe<Array<Maybe<TaxonomyObject>>>;
  providerType?: Maybe<TaxonomyObject>;
  subjectsOfStudy?: Maybe<Array<Maybe<TaxonomyObject>>>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  valueDescription?: Maybe<Scalars['String']['output']>;
  valuePeriod?: Maybe<Scalars['String']['output']>;
};

export type ProviderAttributes = {
  __typename?: 'providerAttributes';
  ACCOMMODATION?: Maybe<Scalars['String']['output']>;
  GENDER_DEMOGRAPHY?: Maybe<Scalars['String']['output']>;
  NUMBER_OF_INTERNATIONAL_STUDENTS?: Maybe<Scalars['String']['output']>;
  NUMBER_OF_STUDENTS?: Maybe<Scalars['String']['output']>;
  RELIGIOUS_CLUB?: Maybe<Scalars['String']['output']>;
  RELIGIOUS_FACILITIES?: Maybe<Scalars['String']['output']>;
};

export type TaxonomyObject = {
  __typename?: 'taxonomyObject';
  name?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
};
